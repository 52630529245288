import {
    dayEmoji,
    firstGuessEmoji,
    loseEmoji,
    winEmoji,
} from '../constants/misc/EmojiConstants';
import { Guess } from '../models/game/guess';
import { StreakItem, Streaks } from '../models/stats/StreakItems';

export const generateStreakItems = (
    streaks: Streaks,
    category: 'all' | 'streaks' | 'antistreaks',
    emojiOnly = false,
    currentStreaksOnly = true,
): StreakItem[] => {
    const printLabel = (label: string) => (emojiOnly ? '' : ` ${label}`);

    const allStreakItems: StreakItem[] = [
        {
            label: `${dayEmoji}${printLabel('Daily Streak')}`,
            current: streaks.playStreak,
            best: streaks.playStreakBest,
            isAntiStreak: false,
        },
        {
            label: `${winEmoji}${printLabel('Win Streak')}`,
            current: streaks.winStreak,
            best: streaks.winStreakBest,
            isAntiStreak: false,
        },
        {
            label: `${firstGuessEmoji}${printLabel('First-Guess Streak')}`,
            current: streaks.firstGuessStreak,
            best: streaks.firstGuessStreakBest,
            isAntiStreak: false,
        },
        {
            label: `${loseEmoji}${printLabel('Loss Streak')}`,
            current: streaks.loseStreak,
            best: streaks.loseStreakBest,
            isAntiStreak: true,
        },
    ];

    const resultStreakItems: StreakItem[] = [];

    for (const item of allStreakItems) {
        if (
            category === 'all' ||
            (category === 'streaks' && !item.isAntiStreak) ||
            (category === 'antistreaks' && item.isAntiStreak)
        ) {
            if (!currentStreaksOnly || item.current > 0) {
                resultStreakItems.push(item);
            }
        }
    }

    return resultStreakItems;
};

export const generateArchiveGuesses = (guesses: Guess[]): number[] => {
    let guessesArchive = [];

    // 1 = correct,
    // 2 = skip,
    // 3 = incorrect

    for (let i = 0; i < guesses.length; i++) {
        const guess = guesses[i];
        guessesArchive.push(guess.isSkip ? 2 : guess.isCorrect ? 1 : 3);
    }

    return guessesArchive;
};

export const generateGuessesFromArchive = (guesses: number[]): Guess[] => {
    let guessesArchive = [];

    // 1 = correct,
    // 2 = skip,
    // 3 = incorrect

    for (let i = 0; i < 6; i++) {
        switch (guesses[i]) {
            case 1:
                guessesArchive.push({ isCorrect: true, isSkip: false });
                break;
            case 2:
                guessesArchive.push({ isCorrect: false, isSkip: true });
                break;
            case 3:
                guessesArchive.push({ isCorrect: false, isSkip: false });
                break;
        }
    }

    return guessesArchive;
};

export const generateEmojiGuessesFromArchive = (
    guesses: number[],
    isArchive = false,
): string => {
    let emojis = '';

    for (let i = 0; i < 6; i++) {
        if (i > guesses.length - 1) {
            emojis += '⬜';
            continue;
        }

        switch (guesses[i]) {
            case 1:
                emojis += isArchive ? '🟪' : '🟩';
                break;
            case 2:
                emojis += '⬛';
                break;
            case 3:
                emojis += '🟥';
                break;
        }
    }

    return emojis;
};

export const generateStreakMedalsFromArchive = (
    guesses: number[],
    win: boolean,
): string => {
    let emojis = '';

    // If win on first guess, return firstGuessEmoji
    if (guesses[0] === 1) {
        emojis = firstGuessEmoji;
    }

    // if win, return winEmoji
    else if (win) {
        emojis = winEmoji;
    }

    // if lose, return loseEmoji
    else {
        emojis = loseEmoji;
    }

    return emojis;
};
