import React from 'react';
import { useClassyBoy } from '../../../hooks/useClassyBoy';

type AppCardTitleProps = {
    value: string;
    isVisible?: boolean;
    className?: string;
};

const AppCardTitle: React.FC<AppCardTitleProps> = ({
    className,
    isVisible,
    value,
}) => {
    const classes = useClassyBoy(
        'flex flex-row justify-center items-center text-2xl uppercase',
        className,
    );

    return !isVisible ? null : (
        <div className={classes.result}>
            <h3>{value}</h3>
        </div>
    );
};

AppCardTitle.defaultProps = {
    value: 'Card Title',
    isVisible: true,
};

export default AppCardTitle;
