import React, { FocusEventHandler, useEffect, useRef } from 'react';
import LoadingSpinner from '../misc/LoadingSpinner';
import { SearchIcon } from '../misc/Svgs';
import clsx from "clsx";

export type AppSearchBarCallables = {
    setValue: (value: string) => void;
};

type AppSearchBarProps = {
    id?: string;
    name?: string;
    placeholder: string;
    setSearchQuery: Function;
    className?: string;
    inputRef?: any;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    setCallables?: (callables: AppSearchBarCallables) => void;

    isLoading?: boolean;
    isEnabled?: boolean;
};

const AppSearchBar: React.FC<AppSearchBarProps> = ({
    id,
    className,
    name,
    placeholder,
    setSearchQuery,
    inputRef,
    onBlur,
    onFocus,
    setCallables,
    isLoading = false,
   isEnabled = true,
}) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const ref = inputRef ?? defaultRef;

    useEffect(() => {
        if (setCallables) {
            setCallables({
                setValue,
            });
        }
    }, [setCallables]);

    const handleChange = (e: any) => {
        setSearchQuery(e.target.value);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
    };

    const setValue = (value: string) => {
        if (ref.current) {
            ref.current.value = value;
        }
    };

    return (
        <form onSubmit={onSubmit} className={className}>
            <div className="relative">
                <span className="absolute inset-y-0 left-0 flex flex items-center pl-3">
                    {isLoading ? (
                        <LoadingSpinner className="w-4 h-4" />
                    ) : (
                        <button
                            type="submit"
                            className="p-1 focus:outline-none focus:shadow-outline"
                            disabled={!isEnabled}
                        >
                            <SearchIcon className={clsx('h-5 w-5', isEnabled ? 'text-accent-1' : 'text-accent-2')} />
                        </button>
                    )}
                </span>
                <input
                    id={id}
                    ref={ref}
                    type="search"
                    onChange={handleChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    name={name}
                    placeholder={placeholder}
                    className={clsx('w-full min-h-[2.5rem] text-ellipsis pl-12 border border-accent-1 focus:border-primary-1 bg-canvas-5 placeholder-canvas-3',
                        isEnabled ? 'text-accent-1' : 'text-accent-2')}
                    disabled={!isEnabled}
                />
            </div>
        </form>
    );
};

export default AppSearchBar;
