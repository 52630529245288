import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArchiveRecord } from '../models/stats/ArchiveRecord';
import { GameRecord } from '../models/stats/GameRecord';
import { Streaks } from '../models/stats/StreakItems';
import { LogDebug } from '../utils/loggingUtils';

export type StatsState = Streaks & {
    previousRounds: GameRecord[];
    archiveRounds: ArchiveRecord[];
};

const statsInitialState: StatsState = {
    previousRounds: [],
    archiveRounds: [],
    winStreak: 0,
    winStreakBest: 0,
    loseStreak: 0,
    loseStreakBest: 0,
    playStreak: 0,
    playStreakBest: 0,
    firstGuessStreak: 0,
    firstGuessStreakBest: 0,
};

const calculateStreaks = (rounds: GameRecord[]): Streaks => {
    let winStreak = 0;
    let winStreakBest = 0;
    let loseStreak = 0;
    let loseStreakBest = 0;
    let playStreak = 0;
    let playStreakBest = 0;
    let firstGuessStreak = 0;
    let firstGuessStreakBest = 0;

    // Loop over previous rounds
    for (let i = 0; i < rounds.length; i++) {
        const previousRound = i === 0 ? undefined : rounds[i - 1];
        const round = rounds[i];

        if (
            previousRound &&
            round.round.number !== previousRound.round.number + 1
        ) {
            // If gap in round history, reset all streaks
            winStreak = 0;
            loseStreak = 0;
            playStreak = 0;
            firstGuessStreak = 0;
        }

        playStreak++;

        // win/lose streak
        if (round.win) {
            winStreak++;
            loseStreak = 0;
        } else {
            loseStreak++;
            winStreak = 0;
        }

        // first guess streak
        if (round.guessCount === 1) {
            firstGuessStreak++;
        } else {
            firstGuessStreak = 0;
        }

        // set best streaks
        if (winStreak > winStreakBest) {
            winStreakBest = winStreak;
        }

        if (loseStreak > loseStreakBest) {
            loseStreakBest = loseStreak;
        }

        if (playStreak > playStreakBest) {
            playStreakBest = playStreak;
        }

        if (firstGuessStreak > firstGuessStreakBest) {
            firstGuessStreakBest = firstGuessStreak;
        }
    }
    return {
        playStreak,
        playStreakBest,
        winStreak,
        winStreakBest,
        loseStreak,
        loseStreakBest,
        firstGuessStreak,
        firstGuessStreakBest,
    };
};

const stats = createSlice({
    name: 'stats',
    initialState: statsInitialState,
    reducers: {
        addGameRecord: (
            state: StatsState,
            action: PayloadAction<GameRecord>,
        ) => {
            LogDebug('Adding game record...');
            const newRound = action.payload;
            const existingRound = state.previousRounds.find(
                (round) => round.round.id === newRound.round.id,
            );

            if (existingRound) {
                LogDebug('Game record already exists, skipping...');
                return;
            }

            state.previousRounds = state.previousRounds.concat(...[newRound]);
        },
        recalculateStreaks: (state: StatsState) => {
            const streaks = calculateStreaks(state.previousRounds);
            state.winStreak = streaks.winStreak;
            state.winStreakBest = streaks.winStreakBest;
            state.playStreak = streaks.playStreak;
            state.playStreakBest = streaks.playStreakBest;
            state.loseStreak = streaks.loseStreak;
            state.loseStreakBest = streaks.loseStreakBest;
            state.firstGuessStreak = streaks.firstGuessStreak;
            state.firstGuessStreakBest = streaks.firstGuessStreakBest;

            LogDebug('Updated stats', {
                previousRounds: state.previousRounds.length,
                winStreak: state.winStreak,
                winStreakBest: state.winStreakBest,
                loseStreak: state.loseStreak,
                loseStreakBest: state.loseStreakBest,
                playStreak: state.playStreak,
                playStreakBest: state.playStreakBest,
                firstGuessStreak: state.firstGuessStreak,
                firstGuessStreakBest: state.firstGuessStreakBest,
            });
        },
        resetArchive: (state: StatsState) => {
            state.archiveRounds = [];
            LogDebug('Archive reset');
        },
        importStats: (state: StatsState, action: PayloadAction<StatsState>) => {
            // iterate over each state field and import from payload
            Object.keys(state).forEach((key) => {
                // @ts-ignore
                state[key] = action.payload[key as keyof StatsState];
            });
            LogDebug('Stats imported');
        },
    },
});

export const {
    addGameRecord,
    //addArchiveRecord,
    recalculateStreaks,
    resetArchive,
    importStats,
} = stats.actions;

export default stats.reducer;
