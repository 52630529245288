import clsx from 'clsx';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { useClassyBoy } from '../../../hooks/useClassyBoy';
import {AdminRoundShort, RoundShort} from '../../../models/game/round';
import RoundListProps from '../../../models/props/RoundListProps';
import AppIconButton from '../../common/inputs/AppIconButton';
import AppCard from '../../common/layout/AppCard';
import AppContainer from '../../common/layout/AppContainer';
import LoadingSpinner from '../../common/misc/LoadingSpinner';
import { LeftArrowIcon, RightArrowIcon } from '../../common/misc/Svgs';
import {ClaimStatus} from "../../../models/pages/roundList";
import RoundCalendarItem from "./RoundCalendarItem";
import {useAppSelector} from "../../../app/hooks";
import Scrollbars from "react-custom-scrollbars-2";
import AppButton from "../../common/inputs/AppButton";

const RoundList: React.FC<RoundListProps> = ({
     className,
     updateDateRange,
     queryRequest,
     queryResponse,
     setNewRoundData,
     roundClaimingActive,
     isUpdatingRound,
     saveRoundClaims,
     setRoundClaimingActive,
     activateRoundClaiming,
     claimedRounds,
     setRoundClaim,
}) => {
    const classes = useClassyBoy('max-h-full overflow-y-hidden', className);
    const account = useAppSelector((state) => state.account);

    return (
        <AppContainer
            outerClassName={classes.result}
            innerClassName="w-full items-center py-4 text-accent-1 flex flex-col"
            fillScreen={true}
        >
            <div className="relative w-full flex justify-center h-12">
                <h2 className="absolute top-0 flex-grow text-2xl text-center font-semibold mb-4 uppercase">
                    Rounds
                </h2>
                <div className="absolute top-0 w-full">
                    <div className="flex justify-between">
                        {roundClaimingActive ? (
                            <AppButton
                                value="save"
                                className="text-xs uppercase mr-2"
                                sizeClassName="w-16 h-6"
                                onClick={saveRoundClaims}
                                isLoading={isUpdatingRound}
                                isEnabled={!isUpdatingRound}
                            />
                        ) : <div/>}
                        {roundClaimingActive ? (
                            <>
                                <AppButton
                                    value="Cancel"
                                    mode="secondary"
                                    className="text-xs uppercase"
                                    sizeClassName="w-16 h-6"
                                    onClick={() => setRoundClaimingActive(false)}
                                    isEnabled={!isUpdatingRound}
                                />
                            </>
                        ) : (
                            <AppButton
                                value="claim"
                                className="text-xs uppercase"
                                sizeClassName="w-16 h-6"
                                onClick={activateRoundClaiming}
                            />
                        )}
                    </div>
                </div>
            </div>

            <AppCard className="w-full h-full max-h-full overflow-hidden pb-16" layoutClassName="py-4 px-2">
                <div className="flex w-full items-center mb-4 px-4">
                    <AppIconButton
                        onClick={() => updateDateRange(-1)}
                        isEnabled={queryRequest !== undefined}
                    >
                        <LeftArrowIcon className="w-12 h-12 text-primary-1" />
                    </AppIconButton>
                    <h3 className="text-lg text-center uppercase flex-grow">
                        {queryRequest === undefined
                            ? 'Loading...'
                            : DateTime.fromISO(queryRequest.dateStart).toFormat(
                                  'MMMM y',
                              )}
                    </h3>
                    <AppIconButton
                        onClick={() => updateDateRange(1)}
                        isEnabled={queryRequest !== undefined}
                    >
                        <RightArrowIcon className="w-12 h-12 text-primary-1" />
                    </AppIconButton>
                </div>
                {(queryResponse.isFetching || queryResponse.isLoading) && (
                    <div className="w-full flex justify-center">
                        <LoadingSpinner className="w-12 h-12 mt-4" />
                    </div>
                )}
                {queryResponse.isSuccess && !queryResponse.isFetching && (
                    <Scrollbars>
                        <div className="mx-3">
                            {queryResponse.data?.content.map(
                                (round: AdminRoundShort, index: number) => {
                                    const roundClaim = claimedRounds.find(
                                        (e) => e.id === round.id,
                                    );

                                    const claimStatus: ClaimStatus = roundClaim
                                        ? roundClaim.userId === account.id
                                            ? roundClaim.existing
                                                ? roundClaim.remove
                                                    ? 'remove'
                                                    : 'claimed'
                                                : 'claim'
                                            : roundClaim.update
                                                ? 'claim'
                                                : 'none'
                                        : 'none';

                                    return (
                                        <RoundCalendarItem
                                            key={index}
                                            className="cursor-pointer mb-4"
                                            index={index}
                                            round={round}
                                            setNewRoundData={setNewRoundData}
                                            routerLinkEnabled={!roundClaimingActive}
                                            nonRouterLinkOnClick={() =>
                                                roundClaimingActive
                                                    ? setRoundClaim(round)
                                                    : undefined
                                            }
                                            claimStatus={
                                                roundClaimingActive
                                                    ? claimStatus
                                                    : 'none'
                                            }
                                        />
                                    );
                                },
                            )}
                        </div>
                    </Scrollbars>
                )}
            </AppCard>
        </AppContainer>
    );
};

export default RoundList;
