import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Player from '../../components/game/Player';
import Controller, {
    ControllerCallables,
} from '../../components/game/Controller';
import GuessHistory from '../../components/game/GuessHistory';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {useGetCurrentRoundQuery} from '../../app/services/api';
import {addGuess, setNewRound, setRound, skipGuess} from '../../store/gameSlice';
import {DateTime} from 'luxon';
import EndScreen from '../../components/game/EndScreen';
import CountdownTimer from '../../components/game/CountdownTimer';
import Scrollbars from 'react-custom-scrollbars-2';
import AppContainer from "../../components/common/layout/AppContainer";
import Adsense from "../../components/common/misc/Adsense";
import clsx from "clsx";
import {getDateTimeLocalNow, getDiffNow} from "../../utils/dateTimeUtils";
import {LogDebug} from "../../utils/loggingUtils";

const GamePage: React.FC = () => {
    const dispatch = useAppDispatch();
    const scrollbars = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [newRoundFetchTimer, setNewRoundFetchTimer] = useState<any>();
    const [roundDate, setRoundDate] = useState<string>();
    const [controllerCallables, setControllerCallables] =
        useState<ControllerCallables>();
    const [selectedClipIndex, setSelectedClipIndex] = useState(0);
    const [loadingNewRound, setLoadingNewRound] = useState(false);

    const game = useAppSelector((state) => state.game);
    const navigation = useAppSelector((state) => state.navigation);

    const getRoundResponse = useGetCurrentRoundQuery(roundDate!, {
        skip: !roundDate,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (getRoundResponse.isSuccess) {
            const newRound = getRoundResponse.data.content;
            const refreshDue =
                game.round
                && navigation.timeLastRefreshed
                && getDiffNow(DateTime.fromISO(navigation.timeLastRefreshed)).as("hours") > 48

            // Round exists in storage
            if (game.round
                // New Round is different from existing round
                && game.round.id !== newRound.id
                // Page hasn't been refreshed in X hours
                && refreshDue
                // No new round exists in storage and pending flag is false
                && ((!game.newRound && !game.newRoundPending)
                    // New round exists in storage and pending flag is true
                    || (game.newRound && game.newRoundPending))){
                dispatch(setNewRound(newRound));
            } else {
                dispatch(setRound(newRound));
            }
        }
    }, [getRoundResponse.data, getRoundResponse.isSuccess]);

    useEffect(() => {
        setSelectedClipIndex(game.guessIndex);
    }, [game.guessIndex]);

    useEffect(() => {
        if (game.gameOver) {
            setSelectedClipIndex(7);
        }
    }, [game.gameOver]);

    const setSelectedClip = useCallback((index: number) => {
        if (index !== selectedClipIndex && (game.gameOver || index <= game.guessIndex)) {
            setSelectedClipIndex(index);
        }
    }, [game.gameOver, game.guessIndex, selectedClipIndex])

    const calculateNewRoundIntervalDuration = (): number => {
        const currentDateTime = getDateTimeLocalNow();
        const nextStartDateTime = currentDateTime
            .plus({day: 1})
            .startOf('day');
        const durationUntilNextRound = nextStartDateTime
            .diff(currentDateTime)
            .plus({second: 1});
        LogDebug(
            `Fetching next round in ${durationUntilNextRound.toFormat(
                'hh:mm:ss',
            )}`,
        );
        return durationUntilNextRound.toMillis();
    };

    const fetchNewRound = (): void => {
        LogDebug('Fetching new round...');
        setRoundDate(getDateTimeLocalNow().toISO({includeOffset: false}));

        if (newRoundFetchTimer) {
            clearTimeout(newRoundFetchTimer);
        }
        setNewRoundFetchTimer(
            setTimeout(fetchNewRound, calculateNewRoundIntervalDuration()),
        );
    };

    useEffect(() => {
        fetchNewRound();
        setNewRoundFetchTimer(
            setTimeout(fetchNewRound, calculateNewRoundIntervalDuration()),
        );

        return () => {
            if (newRoundFetchTimer) {
                clearTimeout(newRoundFetchTimer);
            }
        };
    }, []);

    useEffect(() => {
        if (game.gameOver && scrollbars !== null && scrollbars.current !== null) {
            // @ts-ignore
            scrollbars.current.scrollTop(0);
        }
    }, [game.gameOver]);

    const player = useMemo(() => <Player
        clips={game.round?.clips || []}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        guessIndex={game.guessIndex}
        clipIndex={selectedClipIndex}
        setSelectedClipIndex={setSelectedClip}
        showPlayer={game.round !== undefined && game.round.ready && !game.newRoundPending && !loadingNewRound}
        isLoading={(!game.round || !game.round.ready || loadingNewRound) && !game.newRoundPending}
        isReady={game.round !== undefined && game.round.ready}
        gameOver={game.gameOver}/>,
        [game.gameOver, game.guessIndex, game.newRoundPending, game.round, isPlaying, loadingNewRound,
            selectedClipIndex, setSelectedClip]);

    return (
        <>
            {!game.gameOver && player}
            <Scrollbars
                ref={scrollbars}
                className="flex-grow overflow-y-auto"
                renderThumbVertical={(props) => (
                    <div
                        {...props}
                        className="thumb-vertical bg-canvas-4 rounded-full"
                    />
                )}
            >
                {game.gameOver ? (
                    <>
                        {player}
                        <EndScreen/>
                    </>
                ) : (
                    <GuessHistory
                        setSearchFocused={controllerCallables?.setFocus}
                        guessIndex={game.guessIndex}
                        gameOver={game.gameOver}
                        guesses={game.guesses}
                    />
                )}

                <div id="left-ad" className="hidden lg:block fixed left-0 center-panel-ad-container">
                    <Adsense
                        className={clsx( "w-full h-full" )}
                        slot="2732131624"
                        style={{
                            display: 'block'
                        }}
                    />
                </div>

                <div id="right-ad" className="hidden lg:block fixed right-0 center-panel-ad-container">
                    <Adsense
                        className={clsx( "w-full h-full" )}
                        slot="2130879289"
                        style={{
                            display: 'block'
                        }}
                    />
                </div>
            </Scrollbars>
            <div className="z-10">
                {game.gameOver ? (
                    <CountdownTimer/>
                ) : (
                    <Controller
                        setCallables={setControllerCallables}
                        roundReady={game.round?.ready || false}
                        guesses={game.guesses}
                        submitGuessFunction={(option) => dispatch(addGuess(option))}
                        skipGuessFunction={() => dispatch(skipGuess())}
                    />
                )}
            </div>
            <div className="z-10">
                <AppContainer outerClassName={clsx("h-[4.5rem] pt-4 w-full", game.gameOver ? 'bg-canvas-6' : 'bg-canvas-5')}
                              innerClassName="w-full h-full"
                              maxWidth="1024px"
                              padding={false}>
                    <Adsense
                        className={clsx( "w-full h-full" )}
                        slot="8728701940"
                        responsive={true}
                        style={{
                            display: 'block'
                        }}
                    />
                </AppContainer>
            </div>
        </>
    );
};

export default GamePage;
