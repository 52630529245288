import {
    MiddlewareAPI,
    isRejectedWithValue,
    Middleware,
} from '@reduxjs/toolkit';
import {ErrorToast} from '../../components/common/misc/Toast';
import {DateTime} from "luxon";
import {getDateTimeLocalNow, getDiffNow} from "../../utils/dateTimeUtils";
import {LogDebug} from "../../utils/loggingUtils";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            LogDebug('Middleware - RTK Query Error Logger');
            console.error(action);

            let toastMessage;
            let showToast = true;
            if (action.payload.status === 'FETCH_ERROR') {
                const lastFetchErrorTimeStamp = sessionStorage.getItem("lastFetchErrorTimeStamp");
                const secondsSinceLastFetchError = lastFetchErrorTimeStamp
                    ? Math.abs(getDiffNow(DateTime.fromSeconds(Number(lastFetchErrorTimeStamp)), "seconds").seconds)
                    : 999;

                if (secondsSinceLastFetchError > 20) {
                    LogDebug("Seconds since last fetch error:", secondsSinceLastFetchError)
                    showToast = false;
                    sessionStorage.setItem("lastFetchErrorTimeStamp", getDateTimeLocalNow(true).toSeconds().toString())
                }

                toastMessage = 'Server is unavailable, please try again later.';
            } else {
                toastMessage = action.payload.data.message;
            }

            if (showToast) {
                ErrorToast(toastMessage);
            }
        }

        return next(action);
    };
