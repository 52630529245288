type TWIncrement = 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000;

export const animateAll = (duration?: TWIncrement) =>
    `transition-all ease-in-out duration-${duration ?? 300}`;
export const animateColors = (duration?: TWIncrement) =>
    `transition-colors ease-in-out duration-${duration ?? 300}`;
export const animateOpacity = (duration?: TWIncrement) =>
    `transition-opacity ease-in-out duration-${duration ?? 300}`;
export const animateTransform = (duration?: TWIncrement) =>
    `transition-transform ease-in-out duration-${duration ?? 300}`;
