import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { useClassyBoy } from '../../../hooks/useClassyBoy';
import LoadingSpinner from '../misc/LoadingSpinner';
import onChange = toast.onChange;

type AppIconInputProps = {
    children: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    layoutClassName?: string;
    enabledClassName?: string;
    disabledClassName?: string;
    loadingClassName?: string;
    isLoading?: boolean;
    isEnabled?: boolean;
    animationsEnabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const AppIconInput: React.FC<AppIconInputProps> = ({
    type,
    children,
    onChange,
    className,
    layoutClassName,
    enabledClassName,
    disabledClassName,
    loadingClassName,
    isLoading,
    isEnabled,
    animationsEnabled = true,
}) => {
    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const buttonClasses = useClassyBoy(
        'focus:outline-none rounded-full flex items-center justify-center',
        className,
    );
    const loadingClasses = useClassyBoy('m-2', loadingClassName);

    if (isLoading) {
        return <LoadingSpinner className={loadingClasses.result} />;
    }

    buttonClasses.add(layoutClassName ?? 'p-2 w-8 h-8');

    if (animationsEnabled && (isEnabled === undefined || isEnabled)) {
        buttonClasses.add(
            'hover:bg-gray-400 hover:bg-opacity-25 cursor-pointer',
            enabledClassName,
        );
    } else {
        buttonClasses.add('text-primary-3 cursor-default', disabledClassName);
    }

    const processClick = () => {
        if (isEnabled === undefined || isEnabled) {
            console.log('Pressed AppIconInput', inputRef);
            inputRef.current.click();
        }
    };

    return (
        <div className={buttonClasses.result} onClick={processClick}>
            {children}
            <input
                ref={inputRef}
                type={type}
                className="invisible absolute"
                onChange={onChange}
            />
        </div>
    );
};

export default AppIconInput;
