import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { apiBaseUrl } from '../../config';
import {
    ApiRequestLogin,
    ApiRequestLogout,
    ApiResultLogin,
} from '../../models/api/account';
import {
    ApiRequestCreateClip,
    ApiRequestDeleteClip,
    ApiRequestUpdateClip,
} from '../../models/api/clip';
import {
    PaginatedListResponse,
    ResponseModel,
    ResponseModelBase,
} from '../../models/api/common/response';
import {
    ApiRequestAddFlick,
    ApiRequestFlickSearchAdmin,
} from '../../models/api/flick';

import {
    ApiRequestArchive,
    ApiRequestArchiveCompletionRecord,
    ApiRequestArchiveList,
    ApiRequestClearArchive,
    ApiRequestNextArchive,
    ApiRequestRandomArchive,
} from '../../models/api/archive';
import {
    ApiRequestRoundsInRange,
    ApiRequestUpdateRound,
} from '../../models/api/round';
import {
    ApiRequestAnonRoundCompletion,
    ApiRequestRoundStats,
} from '../../models/api/stats';
import { ApiResultVersion } from '../../models/api/version';
import { Entity } from '../../models/common';
import { AdminClip } from '../../models/game/clip';
import {
    Flick,
    FlickAdminSearchResult,
    FlickSearchResult,
} from '../../models/game/flick';
import {
    AdminRoundLong,
    AdminRoundShort,
    ArchiveRoundLong,
    ArchiveRoundShort,
    RoundLong,
} from '../../models/game/round';
import { RoundStats } from '../../models/stats/RoundStats';
import { resetUser, setTokens } from '../../store/accountSlice';
import { getDateTimeLocalNow } from '../../utils/dateTimeUtils';
import { RootState } from '../rootReducer';

const baseQuery = fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).account.accessToken?.value;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const rootState = api.getState() as RootState;
    const accountReducer = rootState.account;

    if (
        accountReducer.accessToken === undefined ||
        getDateTimeLocalNow(true).toSeconds() >
            accountReducer.accessToken!.expiresAt
    ) {
        if (
            accountReducer.refreshToken === undefined ||
            getDateTimeLocalNow(true).toSeconds() >
                accountReducer.refreshToken!.expiresAt
        ) {
            api.dispatch(resetUser());
        } else {
            const refreshResult = await baseQuery(
                {
                    url: '/account/refresh-token',
                    method: 'post',
                    body: { refreshToken: accountReducer.refreshToken!.value },
                },
                api,
                extraOptions,
            );
            if (refreshResult) {
                let content;
                try {
                    content = (
                        refreshResult.data as ResponseModel<ApiResultLogin>
                    ).content;
                    api.dispatch(setTokens(content));
                } catch (e) {
                    console.error(e);
                    api.dispatch(resetUser());
                }
            }
        }
    }

    return baseQuery(args, api, extraOptions);
};

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Round', 'RoundList', 'Archive', 'ArchiveList'],
    endpoints: (builder) => {
        return {
            /* ACCOUNT */
            login: builder.mutation<
                ResponseModel<ApiResultLogin>,
                ApiRequestLogin
            >({
                query: (request) => ({
                    url: '/account/login',
                    method: 'POST',
                    body: request,
                }),
            }),
            logout: builder.mutation<null, ApiRequestLogout>({
                query: (request) => ({
                    url: '/account/logout',
                    method: 'POST',
                    body: request,
                }),
            }),

            /* VERSION */
            getAppVersion: builder.query<
                ResponseModel<ApiResultVersion>,
                boolean
            >({
                query: (includeMessage: boolean) => {
                    return `/version/app?IncludeMessage=${includeMessage}`;
                },
            }),

            /* ROUNDS */
            getCurrentRound: builder.query<ResponseModel<RoundLong>, string>({
                query: (date: string) => {
                    return `/round/current?localdatetime=${date}`;
                },
                providesTags: (result) =>
                    result
                        ? [{ type: 'Round', id: result.content.id }, 'Round']
                        : ['Round'],
            }),
            getRound: builder.query<ResponseModel<AdminRoundLong>, string>({
                query: (id: string) => {
                    return `/round/${id}`;
                },
                providesTags: (result) =>
                    result
                        ? [
                              { type: 'Round' as const, id: result.content.id },
                              'Round',
                          ]
                        : ['Round'],
            }),
            getRoundsInRange: builder.query<
                ResponseModel<AdminRoundShort[]>,
                ApiRequestRoundsInRange
            >({
                query: (query: ApiRequestRoundsInRange) => {
                    return `/round/range?dateStart=${query.dateStart}&dateEnd=${query.dateEnd}`;
                },
                providesTags: ['RoundList'],
            }),
            updateRound: builder.mutation<
                ResponseModel<AdminRoundLong>,
                ApiRequestUpdateRound
            >({
                query: (request) => ({
                    url: `/round/${request.roundId}`,
                    method: 'PUT',
                    body: request.body,
                }),
                invalidatesTags: [
                    'Round',
                    'RoundList',
                    'Archive',
                    'ArchiveList',
                ],
            }),

            /* FLICKS */
            searchFlicks: builder.query<
                ResponseModel<PaginatedListResponse<FlickSearchResult>>,
                string
            >({
                query: (search: string) => {
                    return `/flicks?PageNumber=1&PageSize=64&Search=${search}`;
                },
            }),
            searchFlicksAdmin: builder.query<
                ResponseModel<PaginatedListResponse<FlickAdminSearchResult>>,
                ApiRequestFlickSearchAdmin
            >({
                query: ({ search, currentFlickId }) => {
                    return `/flicks/admin?PageNumber=1&PageSize=64&OrderBy=title asc${
                        currentFlickId && `&Exclude=${currentFlickId}`
                    }&Search=${search}`;
                },
            }),
            addFlick: builder.mutation<
                ResponseModel<Flick>,
                ApiRequestAddFlick
            >({
                query: (request) => ({
                    url: '/flicks',
                    method: 'POST',
                    body: request,
                }),
            }),

            /* CLIPS */
            createClip: builder.mutation<
                ResponseModel<AdminClip>,
                ApiRequestCreateClip
            >({
                query: (request) => ({
                    url: `/clips`,
                    method: 'POST',
                    body: request.body,
                }),
                invalidatesTags: (result) => ['Round', 'Archive'],
            }),
            updateClip: builder.mutation<
                ResponseModel<AdminClip>,
                ApiRequestUpdateClip
            >({
                query: (request) => ({
                    url: `/clips/${request.clipId}`,
                    method: 'PUT',
                    body: request.body,
                }),
                invalidatesTags: (result) => ['Round', 'Archive'],
            }),
            deleteClip: builder.mutation<
                ResponseModelBase,
                ApiRequestDeleteClip
            >({
                query: (request) => ({
                    url: `/clips/${request.clipId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: (result) => ['Round', 'Archive'],
            }),

            /* STATS */
            recordAnonRoundCompletion: builder.mutation<
                ResponseModelBase,
                ApiRequestAnonRoundCompletion
            >({
                query: (request) => ({
                    url: `/stats/anon/round`,
                    method: 'POST',
                    body: request,
                }),
            }),
            getRoundStats: builder.query<
                ResponseModel<RoundStats>,
                ApiRequestRoundStats
            >({
                query: (payload: ApiRequestRoundStats) => {
                    return `/stats/round/${payload.roundId}`;
                },
            }),

            /* ARCHIVE */
            getArchiveList: builder.query<
                ResponseModel<PaginatedListResponse<ArchiveRoundShort>>,
                ApiRequestArchiveList
            >({
                query: (query: ApiRequestArchiveList) => {
                    return `/archive?pageSize=${query.pageSize}&pageNumber=${query.pageNumber}&sessionId=${query.sessionId}&orderBy=date desc`;
                },
                providesTags: ['ArchiveList'],
            }),
            getArchive: builder.query<
                ResponseModel<ArchiveRoundLong>,
                ApiRequestArchive
            >({
                query: (query: ApiRequestArchive) => {
                    return `/archive/${query.roundId}?sessionId=${query.sessionId}`;
                },
                providesTags: (result) =>
                    result
                        ? [
                              {
                                  type: 'Archive' as const,
                                  id: result.content.id,
                              },
                              'Archive',
                          ]
                        : ['Archive'],
            }),
            createArchiveCompletionRecord: builder.mutation<
                ResponseModelBase,
                ApiRequestArchiveCompletionRecord
            >({
                query: (request) => ({
                    url: `/archive`,
                    method: 'POST',
                    body: request,
                }),
                invalidatesTags: () => ['ArchiveList', 'Archive'],
            }),
            getNextArchive: builder.query<
                ResponseModel<Entity>,
                ApiRequestNextArchive
            >({
                query: (query: ApiRequestNextArchive) => {
                    return `/archive/${query.currentRoundId}/next?roundId=${query.currentRoundId}&sessionId=${query.sessionId}`;
                },
            }),
            getRandomArchive: builder.query<
                ResponseModel<Entity>,
                ApiRequestRandomArchive
            >({
                query: (query: ApiRequestRandomArchive) => {
                    return `/archive/random?roundId=${query.currentRoundId}&sessionId=${query.sessionId}`;
                },
            }),
            clearArchiveRecords: builder.mutation<
                ResponseModelBase,
                ApiRequestClearArchive
            >({
                query: (request) => ({
                    url: `/archive`,
                    method: 'DELETE',
                    body: request,
                }),
                invalidatesTags: () => ['ArchiveList', 'Archive'],
            }),
        };
    },
});

export const {
    /* ACCOUNT */
    useLoginMutation,
    useLogoutMutation,

    /* VERSION */
    useGetAppVersionQuery,
    useLazyGetAppVersionQuery,

    /* ROUNDS */
    useGetCurrentRoundQuery,
    useGetRoundQuery,
    useGetRoundsInRangeQuery,
    useUpdateRoundMutation,

    /* FLICKS */
    useSearchFlicksQuery,
    useSearchFlicksAdminQuery,
    useAddFlickMutation,

    /* CLIPS */
    useCreateClipMutation,
    useUpdateClipMutation,
    useDeleteClipMutation,

    /* STATS */
    useRecordAnonRoundCompletionMutation,
    useGetRoundStatsQuery,

    /* ARCHIVE */
    useGetArchiveListQuery,
    useGetArchiveQuery,
    useCreateArchiveCompletionRecordMutation,
    useClearArchiveRecordsMutation,
    useLazyGetNextArchiveQuery,
    useLazyGetRandomArchiveQuery,
} = api;
