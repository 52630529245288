import React from 'react';
import {useAppSelector} from "../../../app/hooks";
import clsx from "clsx";
import {eventName} from "../../../config";
import {aprilfools} from "../../../constants/misc/eventConstants";

type AppDialogProps = {
    children: React.ReactNode;
    buttons?: React.ReactNode;
    close: () => void;
    title: string;
};

const AppDialog: React.FC<AppDialogProps> = ({
    children,
    buttons,
    close,
    title,
}) => {
    const eventActive = useAppSelector((state) => state.events.eventActive);

    return (
        <div className={clsx('absolute h-full w-full z-60', eventActive && eventName === aprilfools && 'transform rotate-180')}>
            <div
                className="absolute h-full w-full bg-canvas-6 opacity-90"
                onClick={close}
            />
            <div className="absolute flex h-full w-full items-center justify-center">
                <div className="w-3/4 max-w-2xl bg-canvas-4 rounded-lg drop-shadow-2xl pb-4 pt-2 px-4 text-accent-1">
                    <div className="flex justify-center">
                        <h1 className="text-2xl text-center uppercase border-b border-accent-1 pb-1 px-4 max-w-fit">
                            {title}
                        </h1>
                    </div>
                    <div className="flex flex-col flex-grow mt-8">
                        {children}
                    </div>
                    {buttons && (
                        <div className="flex justify-center mt-8">
                            {buttons}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AppDialog;
