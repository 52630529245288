import { DateTime, Info } from 'luxon';
import React from 'react';
import { useAppSelector } from '../../../app/hooks';
import { useClassyBoy } from '../../../hooks/useClassyBoy';
import { AdminRoundShort } from '../../../models/game/round';
import { ClaimStatus } from '../../../models/pages/roundList';
import RoundListProps from '../../../models/props/RoundListProps';
import AppButton from '../../common/inputs/AppButton';
import AppIconButton from '../../common/inputs/AppIconButton';
import AppCard from '../../common/layout/AppCard';
import AppContainer from '../../common/layout/AppContainer';
import LoadingSpinner from '../../common/misc/LoadingSpinner';
import { LeftArrowIcon, RightArrowIcon } from '../../common/misc/Svgs';
import RoundCalendarItem from './RoundCalendarItem';

const RoundCalendar: React.FC<RoundListProps> = ({
    className,
    updateDateRange,
    queryRequest,
    queryResponse,
    setNewRoundData,
    roundClaimingActive,
    isUpdatingRound,
    saveRoundClaims,
    setRoundClaimingActive,
    activateRoundClaiming,
    claimedRounds,
    setRoundClaim,
}) => {
    const classes = useClassyBoy('max-h-full overflow-y-hidden', className);
    const account = useAppSelector((state) => state.account);

    return (
        <AppContainer
            outerClassName={classes.result}
            innerClassName="w-full h-full items-center py-4 text-accent-1"
            maxWidth=""
        >
            <div className="relative w-full flex justify-between h-12">
                <div className="flex-grow" />
                <h2 className="absolute top-0 w-full flex-grow text-2xl text-center font-semibold mb-4 uppercase">
                    Rounds
                </h2>
                <div className="absolute top-0 w-full flex justify-end">
                    {roundClaimingActive ? (
                        <>
                            <AppButton
                                value="save"
                                className="text-sm uppercase mr-4"
                                onClick={saveRoundClaims}
                                isLoading={isUpdatingRound}
                                isEnabled={!isUpdatingRound}
                            />
                            <AppButton
                                value="Cancel"
                                mode="secondary"
                                className="text-sm uppercase"
                                onClick={() => setRoundClaimingActive(false)}
                                isEnabled={!isUpdatingRound}
                            />
                        </>
                    ) : (
                        <AppButton
                            value="claim rounds"
                            className="text-sm uppercase"
                            sizeClassName="w-40 h-8"
                            onClick={activateRoundClaiming}
                        />
                    )}
                </div>
            </div>

            <AppCard layoutClassName="py-4 px-1">
                <div className="flex w-full items-center mb-2 px-4">
                    <AppIconButton
                        onClick={() => updateDateRange(-1)}
                        isEnabled={queryRequest !== undefined}
                    >
                        <LeftArrowIcon className="w-12 h-12 text-primary-1" />
                    </AppIconButton>
                    <h3 className="text-lg text-center uppercase flex-grow">
                        {queryRequest === undefined
                            ? 'Loading...'
                            : DateTime.fromISO(queryRequest.dateStart).toFormat(
                                  'MMMM y',
                              )}
                    </h3>
                    <AppIconButton
                        onClick={() => updateDateRange(1)}
                        isEnabled={queryRequest !== undefined}
                    >
                        <RightArrowIcon className="w-12 h-12 text-primary-1" />
                    </AppIconButton>
                </div>
                <div className="grid grid-rows-1 grid-cols-7 mb-2">
                    {Info.weekdays('long').map((item, index) => (
                        <span key={index} className="block text-center">
                            {item}
                        </span>
                    ))}
                </div>
                {(queryResponse.isLoading || queryResponse.isFetching) && (
                    <div className="w-full flex justify-center">
                        <LoadingSpinner className="w-12 h-12 mt-4" />
                    </div>
                )}
                {queryResponse.isSuccess && !queryResponse.isFetching && (
                    <div className="w-full grid grid-cols-7 gap-3 px-3 overflow-y-auto max-h-[calc(100vh-15rem)]">
                        {queryResponse.data?.content.map(
                            (round: AdminRoundShort, index: number) => {
                                const roundClaim = claimedRounds.find(
                                    (e) => e.id === round.id,
                                );

                                const claimStatus: ClaimStatus = roundClaim
                                    ? roundClaim.userId === account.id
                                        ? roundClaim.existing
                                            ? roundClaim.remove
                                                ? 'remove'
                                                : 'claimed'
                                            : 'claim'
                                        : roundClaim.update
                                        ? 'claim'
                                        : 'none'
                                    : 'none';

                                return (
                                    <RoundCalendarItem
                                        key={index}
                                        className="cursor-pointer"
                                        index={index}
                                        round={round}
                                        setNewRoundData={setNewRoundData}
                                        routerLinkEnabled={!roundClaimingActive}
                                        nonRouterLinkOnClick={() =>
                                            roundClaimingActive
                                                ? setRoundClaim(round)
                                                : undefined
                                        }
                                        claimStatus={
                                            roundClaimingActive
                                                ? claimStatus
                                                : 'none'
                                        }
                                    />
                                );
                            },
                        )}
                    </div>
                )}
            </AppCard>
        </AppContainer>
    );
};
export default RoundCalendar;
