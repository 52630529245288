import AppDialog from '../common/layout/AppDialog';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setRulesModalIsOpen } from '../../store/navigationSlice';
import AppButton from '../common/inputs/AppButton';

const RulesModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(setRulesModalIsOpen(false));
    };

    const modalButtons = () => <AppButton onClick={closeModal}>OK</AppButton>;

    return (
        <AppDialog
            close={closeModal}
            buttons={modalButtons()}
            title="How To Play"
        >
            <p>
                <span className="font-bold text-primary-1">1.</span> Watch the
                clip and guess the movie of the day! Start typing to get
                suggestions.
            </p>
            <p className="my-4">
                <span className="font-bold text-primary-1">2.</span> Still not
                sure? Unlock up to 5 more clips with skips and incorrect
                guesses.
            </p>
            <p>
                <span className="font-bold text-primary-1">3.</span> Find the
                answer in as few tries as possible and share your score!
            </p>
        </AppDialog>
    );
};
export default RulesModal;
