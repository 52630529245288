import React from 'react';
import AppContainer from '../common/layout/AppContainer';
import {
    getGuess,
    getGuessBorderColor,
    getGuessTextColor,
} from '../../utils/guessesUtils';
import {Guess} from "../../models/game/guess";

type GuessHistoryProps = {
    setSearchFocused?: any;
    guesses: Guess[];
    guessIndex: number;
    gameOver: boolean;
};

const GuessHistory: React.FC<GuessHistoryProps> = (
    {
        setSearchFocused,
        guessIndex,
        guesses,
        gameOver
    }) => {
    return (
        <AppContainer
            outerClassName="max-h-full"
            innerClassName="w-full h-full items-center py-4"
        >
            {Array(6)
                .fill(null)
                .map((item, index) => {
                    const guess = getGuess(guesses, index);
                    return (
                        <div
                            key={index}
                            onClick={() =>
                                setSearchFocused ? setSearchFocused() : null
                            }
                            className={`${getGuessBorderColor(
                                guess,
                                index,
                                guessIndex,
                                !gameOver,
                            )} flex items-center w-full min-h-[2.1rem] border mb-2 last:mb-0 text-accent-1 px-2`}
                        >
                            {guess && (
                                <span
                                    className={`py-1 ${getGuessTextColor(
                                        guess,
                                    )}`}
                                >
                                    {guess.isSkip ? 'Skip' : guess.flick!.title}
                                </span>
                            )}
                        </div>
                    );
                })}
        </AppContainer>
    );
};

export default GuessHistory;
