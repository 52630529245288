import React, { HTMLInputTypeAttribute } from 'react';

type AppTextInputProps = {
    name?: string;
    placeholder?: string;
    className?: string;
    defaultValue?: string;
    autocomplete?: string;
    type?: HTMLInputTypeAttribute;
    formProperties: any;
};

const AppFormTextInput: React.FC<AppTextInputProps> = ({
    className,
    name,
    placeholder,
    defaultValue,
    autocomplete,
    type = 'text',
    formProperties,
}) => {
    return (
        <input
            type={type}
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            autoComplete={autocomplete}
            {...formProperties}
            className={`${className} w-full min-h-[2.5rem] text-ellipsis border border-accent-1 focus:border-primary-1 bg-canvas-5 text-accent-1 placeholder-canvas-3`}
        />
    );
};

export default AppFormTextInput;
