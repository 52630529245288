import React, {useEffect, useState} from "react";
import clsx from "clsx";
import LoadingSpinner from "../misc/LoadingSpinner";
import { RoundStats } from '../../../models/stats/RoundStats';
import {Guess} from "../../../models/game/guess";
import {useGetRoundStatsQuery} from "../../../app/services/api";

type RoundStatsProps = {
    roundId: string;
    win: boolean;
    guesses: Guess[];
    isArchive: boolean;
}

const RoundStatsChart: React.FC<RoundStatsProps> = (
    {
        roundId,
        win,
        guesses,
        isArchive
    }) => {
    const [roundStats, setRoundStats] = useState<RoundStats>();

    const getRoundStatsResponse = useGetRoundStatsQuery({roundId: roundId, isArchive: isArchive});

    useEffect(() => {
        if (getRoundStatsResponse.isSuccess) {
            setRoundStats(getRoundStatsResponse.data!.content);
        }
    }, [
        getRoundStatsResponse.isSuccess,
        getRoundStatsResponse.isError,
        getRoundStatsResponse.data,
    ]);

    return (
        <>
            <p className="mt-8 text-accent-1 text-sm mb-2">
                How did everyone else do?
            </p>

            {roundStats ? (<div className="flex h-20 w-full max-w-[22rem]">
                {Array.from(Array(7).keys()).map((item, index) => {
                    const losses =
                        roundStats!.losses + (win ? 0 : 1);
                    const total = roundStats!.total + 1;

                    let percentages: number[] = Object.values(
                        roundStats!.guesses,
                    ).map((item) =>
                        Math.round(
                            ((item +
                                    (win && index === guesses.length - 1
                                        ? 1
                                        : 0)) /
                                total) *
                            100,
                        ),
                    );
                    percentages.push(
                        Math.round((losses / total) * 100),
                    );

                    const highestPercentage = Math.max(
                        ...percentages,
                    );
                    const percentage = percentages[index];
                    const barPercentage = Math.min(
                        100,
                        percentage * (100 / highestPercentage),
                    );
                    const spacePercentage = 100 - barPercentage;

                    return (
                        <div
                            key={index}
                            className={clsx(
                                'flex-grow flex flex-col',
                                guesses.length - 1 === index && win
                                    ? (isArchive ? 'text-secondary-1' : 'text-primary-1')
                                    : index === 6 && !win
                                        ? 'text-error-1'
                                        : 'text-accent-2',
                            )}
                        >
                                        <span className="block text-center text-sm mb-1">
                                            {percentage}%
                                        </span>
                            <div className="flex-grow px-2">
                                <div
                                    style={{
                                        height: `${spacePercentage}%`,
                                    }}
                                ></div>
                                <div
                                    className={clsx(
                                        guesses.length - 1 ===
                                        index && win
                                            ? (isArchive ? 'bg-secondary-1' : 'bg-primary-1')
                                            : index === 6 && !win
                                                ? 'bg-error-1'
                                                : 'bg-accent-2',
                                    )}
                                    style={{
                                        height: `${barPercentage}%`,
                                    }}
                                ></div>
                            </div>

                            <span className="block text-center text-sm mt-1">
                                            {index < 6 ? index + 1 : 'X'}
                                        </span>
                        </div>
                    );
                })}
            </div>) : (<LoadingSpinner />)}
        </>
    );
};

export default RoundStatsChart;
