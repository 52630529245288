import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    generateAdminMenuItems,
    generateAnonMenuItems,
    generateCommonMenuItems,
    generateUserMenuItems,
} from '../../../constants/components/AppMenuItemConstants';
import { MenuItem } from '../../../models/navigation/appMenu';
import { setMenuIsOpen } from '../../../store/navigationSlice';

const generateLinks = (
    role: 'anonymous' | 'user' | 'admin',
    statsEnabled: boolean,
): MenuItem[] => {
    const getIconColor = (isDisabled?: boolean, isAlt?: boolean) =>
        isDisabled ? 'text-canvas-2' : isAlt ? 'text-secondary-1' : 'text-primary-1';

    return [
        ...generateCommonMenuItems(getIconColor, statsEnabled),
        ...(role === 'anonymous' ? generateAnonMenuItems(getIconColor) : []),
        ...(role !== 'anonymous' ? generateUserMenuItems(getIconColor) : []),
        ...(role === 'admin' ? generateAdminMenuItems(getIconColor) : []),
    ];
};

type AppMenuProps = {
    className?: string;
};

const AppMenu: React.FC<AppMenuProps> = ({ className }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const previousRounds = useAppSelector(
        (state) => state.stats.previousRounds,
    );
    const userRole = useAppSelector((state) => state.account.role);
    const statsEnabled = previousRounds?.length > 0 ?? 0;

    const closeMenu = () => {
        dispatch(setMenuIsOpen(false));
    };

    useEffect(() => {
        setMenuItems(generateLinks(userRole, statsEnabled));
    }, [userRole, statsEnabled]);

    return (
        <div
            className={clsx(
                className,
                'h-full w-full bg-canvas-5 border-r border-accent-1 z-50 text-accent-1 flex flex-col relative',
            )}
        >
            <ul className={clsx('flex-grow relative')}>
                {menuItems.map((e, i) => (
                    <li
                        key={i}
                        className="text-center h-12 border-b border-accent-1 flex items-center"
                    >
                        <Link
                            to={e.route}
                            onClick={closeMenu}
                            className={clsx(
                                'w-full h-full',
                                e.isDisabled && 'pointer-events-none',
                            )}
                        >
                            <div className="flex h-full items-center justify-start">
                                <div
                                    className={`rounded-full w-8 h-8 ml-2 flex items-center justify-center`}
                                >
                                    {e.icon}
                                </div>
                                <span
                                    className={`pl-2 w-4 leading-none uppercase font-semibold ${
                                        e.isDisabled
                                            ? 'text-canvas-2'
                                            : e.isAlt 
                                                ? 'text-secondary-1' 
                                                : 'text-primary-1'
                                    }`}
                                >
                                    {' '}
                                    {e.text}
                                </span>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AppMenu;
