import { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { CookiePolicyStatusType } from '../store/settingsSlice';
import {LogDebug} from "../utils/loggingUtils";

const useAdsense = () => {
    const cookiePolicyStatus: CookiePolicyStatusType = useAppSelector(
        (state) => state.settings.cookiePolicyStatus,
    );

    useEffect(() => {
        try {
            if (typeof window === 'object') {
                LogDebug('Configuring ads...');
                const ads = ((window as any).adsbygoogle =
                    (window as any).adsbygoogle || []);
                ads.pauseAdRequests = cookiePolicyStatus === 'pending' ? 1 : 0;
                ads.requestNonPersonalizedAds =
                    cookiePolicyStatus === 'full' ? 0 : 1;
                LogDebug('Configure ads successful');
            }
        } catch {
            // Pass
        }
    }, [cookiePolicyStatus]);

    const fetchAd = () => {
        const p: any = {};
        try {
            if (typeof window === 'object') {
                LogDebug('Fetching ad...');
                const ads = ((window as any).adsbygoogle =
                    (window as any).adsbygoogle || []);
                ads.push(p);

                LogDebug('Fetch ad successful');
            }
        } catch (e: any) {
            console.error(e);
        }
    };

    return { fetchAd };
};

export default useAdsense;
