import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import RoundListPage from '../pages/admin/RoundListPage';
import RoundPage from '../pages/admin/RoundPage';
import LoginPage from '../pages/anonymous/LoginPage';
import CookiePolicyPage from '../pages/public/CookiePolicyPage';
import GamePage from '../pages/public/GamePage';
import PrivacyPolicyPage from '../pages/public/PrivacyPolicyPage';
import SettingsPage from '../pages/public/SettingsPage';
import StatsPage from '../pages/public/StatsPage';
import AccountPage from '../pages/user/AccountPage';
import AdminRoute from './common/AdminRoute';
import AnonymousRoute from './common/AnonymousRoute';
import UserRoute from './common/UserRoute';
import EmbedDetectionPage from "../pages/public/EmbedDetectionPage";
import ArchiveList from "../components/archive/ArchiveList";
import ArchiveListPage from "../pages/public/archive/ArchiveListPage";
import ArchivePage from "../pages/public/archive/ArchivePage";

const HomeRoutes: React.FC = () => {

    return (
        <Routes>
            <Route path="/" element={<GamePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/statistics" element={<StatsPage />} />
            <Route path="/embed-detected" element={<EmbedDetectionPage />} />
            <Route path="/archive" element={<ArchiveListPage />} />
            <Route path="/archive/:roundId" element={<ArchivePage />} />
            <Route element={<AnonymousRoute />}>
                <Route path="/login" element={<LoginPage />} />
            </Route>
            <Route element={<UserRoute />}>
                <Route path="/account" element={<AccountPage />} />
            </Route>
            <Route element={<AdminRoute />}>
                <Route path="/rounds" element={<RoundListPage />} />
                <Route path="/rounds/:roundId" element={<RoundPage />} />
            </Route>
            <Route>
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            </Route>
            <Route>
                <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            </Route>
        </Routes>
    );
};

export default HomeRoutes;
