import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';
import { Flick } from '../models/game/flick';
import { Guess } from '../models/game/guess';
import { RoundLong } from '../models/game/round';

type GameState = {
    round?: RoundLong;
    newRound?: RoundLong;
    newRoundPending: boolean;
    guesses: Guess[];
    guessIndex: number;
    gameOver: boolean;
    win: boolean;
};

const gameInitialState: GameState = {
    newRoundPending: false,
    guesses: [],
    guessIndex: 0,
    gameOver: false,
    win: false,
};

const game = createSlice({
    name: 'game',
    initialState: gameInitialState,
    reducers: {
        setRound: (state: GameState, action: PayloadAction<RoundLong>) => {
            const newRound = action.payload;
            if (!state.round || state.round.id !== newRound.id) {
                // First round on device or incoming new round
                state.round = newRound;
                state.gameOver = false;
                state.win = false;
                state.guesses = [];
                state.guessIndex = 0;
            } else {
                // Current round unchanged/updated
                state.round = newRound;
            }
            state.newRound = undefined;
            state.newRoundPending = false;
        },
        setNewRound: (state: GameState, action: PayloadAction<RoundLong>) => {
            state.newRound = action.payload;
            state.newRoundPending = true;
        },
        addGuess: (state: GameState, action: PayloadAction<Flick>) => {
            if (!state.round || !action.payload) return;

            const guess: Guess = {
                flick: action.payload,
                isSkip: false,
                isCorrect: action.payload.id === state.round.flickId,
            };
            const newGuesses = state.guesses.concat(...[guess]);
            const victory = guess.isCorrect;
            const gameOver = newGuesses.length === 6 || victory;

            state.guesses = newGuesses;
            state.guessIndex = newGuesses.length;
            state.win = victory;
            state.gameOver = gameOver;

            if (gameOver) {
                const score =
                    newGuesses.length === 6 && !victory ? 7 : newGuesses.length;
                sessionStorage.setItem('score', String(score));
                sessionStorage.setItem('round', String(state.round.number));
                ReactGA.event('complete_round');
            }
        },
        skipGuess: (state: GameState) => {
            if (!state.round) return;

            const guess = {
                isSkip: true,
                isCorrect: false,
            };
            const newGuesses = state.guesses.concat(...[guess]);
            state.guesses = newGuesses;
            state.guessIndex = newGuesses.length;
            state.gameOver = newGuesses.length === 6;
        },
    },
});

export const { setRound, setNewRound, addGuess, skipGuess } = game.actions;

export default game.reducer;
