import { Guess } from '../models/game/guess';

export const getGuess = (
    guesses: Guess[],
    index: number,
): Guess | undefined => {
    return guesses.length - 1 >= index ? guesses[index] : undefined;
};

export const getGuessBorderColor = (
    guess: Guess | undefined,
    rowIndex: number,
    guessIndex?: number,
    gameActive?: boolean,
): string => {
    return guess
        ? guess.isSkip
            ? 'border-accent-2'
            : guess.isCorrect
            ? 'border-success-1'
            : 'border-error-1'
        : !gameActive || rowIndex === guessIndex
        ? 'border-accent-1'
        : 'border-accent-2';
};

export const getGuessTextColor = (guess: Guess | undefined): string => {
    return guess
        ? guess.isSkip
            ? 'text-accent-2'
            : guess.isCorrect
            ? 'text-success-1'
            : 'text-error-1'
        : 'text-accent-1';
};
