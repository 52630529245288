import React from 'react';
import { toast } from 'react-toastify';

export const ApiErrorToast = (responseError: string) => {
    ErrorToast(
        <>
            <p>Sorry there was an error making the request:</p>
            <p>{responseError}</p>
        </>,
    );
};

export const ErrorToast = (message: React.ReactNode) => {
    toast(message, { type: 'error', position: 'bottom-center' });
};

export const SuccessToast = (message: React.ReactNode) => {
    toast(message, {
        type: 'success',
        position: 'bottom-center',
        className: 'toastSuccess',
    });
};
