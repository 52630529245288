import React from 'react';
import { useClassyBoy } from '../../../hooks/useClassyBoy';

export const svgClasses = 'h-12 w-12 text-white';

export type SvgProps = {
    className?: string;
};

export const BoardIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
    >
        <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
    </svg>
);

export const BookIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        id="i-book"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
    >
        <path d="M16 7 C16 7 9 1 2 6 L2 28 C9 23 16 28 16 28 16 28 23 23 30 28 L30 6 C23 1 16 7 16 7 Z M16 7 L16 28" />
    </svg>
);

export const GraphIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
    >
        <path
            fillRule="evenodd"
            d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z"
            clipRule="evenodd"
        />
    </svg>
);

export const SearchIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        fill="currentColor"
    >
        <path
            d="M14.162,27.606H15.93l.626-.6a14.562,14.562,0,1,1,1.566,1.566l-.6.626v1.767L6.333,42.124,3,38.791Zm13.422,0A10.066,10.066,0,1,0,17.518,17.54,10.053,10.053,0,0,0,27.584,27.606Z"
            transform="translate(-3 -3)"
        />
    </svg>
);

export const ExitIcon = () => {
    return (
        <svg
            className="h-6 w-6 fill-current"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
        </svg>
    );
};

export const ShareIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M15.8595 7.63273L9.15726 11.5895L8.1405 9.86722L14.8428 5.91046L15.8595 7.63273Z" />
        <path d="M9.15726 12.4105L15.8595 16.3672L14.8428 18.0895L8.1405 14.1327L9.15726 12.4105Z" />
        <path d="M17.5 9C19.433 9 21 7.433 21 5.5C21 3.567 19.433 2 17.5 2C15.567 2 14 3.567 14 5.5C14 7.433 15.567 9 17.5 9Z" />
        <path d="M17.5 22C19.433 22 21 20.433 21 18.5C21 16.567 19.433 15 17.5 15C15.567 15 14 16.567 14 18.5C14 20.433 15.567 22 17.5 22Z" />
        <path d="M6.5 15.5C8.433 15.5 10 13.933 10 12C10 10.067 8.433 8.5 6.5 8.5C4.567 8.5 3 10.067 3 12C3 13.933 4.567 15.5 6.5 15.5Z" />
    </svg>
);

export const AddIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10.5 4.5C10.5 3.66772 11.1677 3 12 3C12.8323 3 13.5 3.66772 13.5 4.5V19.5C13.5 20.3323 12.8323 21 12 21C11.1677 21 10.5 20.3323 10.5 19.5V4.5Z" />
        <path d="M3 12C3 11.1677 3.66772 10.5 4.5 10.5H19.5C20.3323 10.5 21 11.1677 21 12C21 12.8323 20.3323 13.5 19.5 13.5H4.5C3.66772 13.5 3 12.8323 3 12Z" />
    </svg>
);

export const DeleteIcon: React.FC<SvgProps> = ({ className }) => {
    const classes = useClassyBoy(className ?? 'h-5 w-5');

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes.result}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
        </svg>
    );
};

export const EditIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export const DownloadIcon: React.FC<SvgProps> = ({ className }) => {
    const classes = useClassyBoy(className ?? 'h-5 w-5');

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={classes.result}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
        </svg>
    );
};

export const HistoryIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M16.49 3.07C15.11 2.37 13.55 2 12 2C7.78998 2 5.13998 4.66 3.50998 6.66C3.16998 7.09 3.22998 7.72 3.65998 8.07C4.08998 8.42 4.71998 8.35 5.06998 7.92C7.26998 5.21 9.40998 4 12 4C13.24 4 14.48 4.29 15.59 4.85C18.31 6.22 20 8.96 20 12C20 16.41 16.41 20 12 20C8.95998 20 6.21998 18.31 4.84998 15.59C4.59998 15.1 4.00998 14.9 3.50998 15.15C3.01998 15.4 2.81998 16 3.06998 16.49C4.77998 19.89 8.19998 22 12 22C17.51 22 22 17.51 22 12C22 8.2 19.89 4.78 16.49 3.07Z"
            ></path>
            <path
                fill="currentColor"
                d="M2 3.59009V10.0001H8.41L2 3.59009Z"
            ></path>
            <path
                fill="currentColor"
                d="M12 6C11.45 6 11 6.45 11 7V13C11 13.55 11.45 14 12 14C12.55 14 13 13.55 13 13V7C13 6.45 12.55 6 12 6Z"
            ></path>
            <path
                fill="currentColor"
                d="M17 12H12C11.45 12 11 12.45 11 13C11 13.55 11.45 14 12 14H17C17.55 14 18 13.55 18 13C18 12.45 17.55 12 17 12Z"
            ></path>
            <path
                fill="currentColor"
                d="M12 13.5C12.2761 13.5 12.5 13.2761 12.5 13C12.5 12.7239 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.7239 11.5 13C11.5 13.2761 11.7239 13.5 12 13.5ZM10.5 13C10.5 12.1716 11.1716 11.5 12 11.5C12.8284 11.5 13.5 12.1716 13.5 13C13.5 13.8284 12.8284 14.5 12 14.5C11.1716 14.5 10.5 13.8284 10.5 13Z"
                clipRule="evenodd"
                fillRule="evenodd"
            ></path>
        </svg>
    );
};

export const SortDirectionIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 23C8.55228 23 9 22.5523 9 22V2C9 1.44772 8.55228 1 8 1C7.44772 1 7 1.44772 7 2V22C7 22.5523 7.44772 23 8 23Z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="M16 23C16.5523 23 17 22.5523 17 22V2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2V22C15 22.5523 15.4477 23 16 23Z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="M11.7929 6.20711C12.1834 6.59763 12.8166 6.59763 13.2071 6.20711L16 3.41421L18.7929 6.20711C19.1834 6.59763 19.8166 6.59763 20.2071 6.20711C20.5976 5.81658 20.5976 5.18342 20.2071 4.79289L16.7071 1.29289C16.3166 0.902369 15.6834 0.902369 15.2929 1.29289L11.7929 4.79289C11.4024 5.18342 11.4024 5.81658 11.7929 6.20711Z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
        <path
            d="M7.29289 22.7071C7.68342 23.0976 8.31658 23.0976 8.70711 22.7071L12.2071 19.2071C12.5976 18.8166 12.5976 18.1834 12.2071 17.7929C11.8166 17.4024 11.1834 17.4024 10.7929 17.7929L8 20.5858L5.20711 17.7929C4.81658 17.4024 4.18342 17.4024 3.79289 17.7929C3.40237 18.1834 3.40237 18.8166 3.79289 19.2071L7.29289 22.7071Z"
            clipRule="evenodd"
            fillRule="evenodd"
        />
    </svg>
);

export const DefaultUserIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
            className={className}
            fill="currentColor"
        >
            <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" />
        </svg>
    );
};

type ThreeDotsIconProps = {
    className: string;
    color: string;
};
export const ThreeDotsIcon: React.FC<ThreeDotsIconProps> = ({
    className,
    color,
}) => {
    return (
        <svg
            className={className}
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1200 1200"
            enableBackground="new 0 0 1200 1200"
        >
            <g>
                <circle
                    fill="none"
                    stroke={color}
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    cx="178.026"
                    cy="602.5"
                    r="125.526"
                />
                <circle
                    fill="none"
                    stroke={color}
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    cx="602.5"
                    cy="602.5"
                    r="125.526"
                />
                <circle
                    fill="none"
                    stroke={color}
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    cx="1026.974"
                    cy="602.5"
                    r="125.526"
                />
            </g>
        </svg>
    );
};

export const InfoIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 25 24"
            fill="currentColor"
        >
            <path d="M14 9H11V20H14V9Z"></path>
            <path d="M14 4H11V7H14V4Z"></path>
        </svg>
    );
};

export const HelpIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1"
                stroke="currentColor"
                d="M12.01 14C12.01 13.5 12.01 13.5 12.01 13.5C12.04 12.75 13 12.46 14.04 11.2C14.41 10.74 14.69 10.41 14.86 9.85C15.15 8.95 14.92 8.18 14.86 8.02C14.8 7.79 14.52 7 13.72 6.46C13.06 6.02 12.42 6 12.14 6C11.9 6 11.36 6 10.78 6.3C10.28 6.56 9.98 6.9 9.83 7.1C9.24 7.82 9.06 8.63 9 9.06"
            />
            <path
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1"
                stroke="currentColor"
                d="M11.99 18H12.01"
            />
        </svg>
    );
};

export const SettingsIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="currentColor"
                d="M13.87 4.89L13.56 2.44C13.53 2.19 13.32 2 13.06 2H9.94998C9.69998 2 9.47998 2.19 9.44998 2.44L9.17998 4.59C9.15998 4.77 9.02998 4.93 8.85998 4.99C8.01998 5.3 7.21998 5.78 6.52998 6.4L4.24998 5.44C4.01998 5.34 3.74998 5.43 3.61998 5.65L2.05998 8.35C1.93998 8.57 1.99998 8.85 2.19998 9L4.16998 10.49C3.95998 11.5 3.95998 12.53 4.15998 13.51H4.16998L2.19998 15C1.99998 15.15 1.93998 15.43 2.06998 15.65L3.62998 18.35C3.75998 18.57 4.02998 18.66 4.25998 18.56L6.53998 17.6L6.52998 17.61C6.89998 17.94 7.31998 18.24 7.76998 18.5C8.21998 18.76 8.67998 18.97 9.15998 19.13V19.11L9.46998 21.56C9.47998 21.81 9.69998 22 9.94998 22H13.07C13.32 22 13.53 21.81 13.57 21.56L13.84 19.41C13.86 19.23 13.99 19.07 14.16 19.01C15 18.7 15.8 18.22 16.49 17.6L18.77 18.56C19 18.66 19.27 18.57 19.4 18.35L20.96 15.65C21.09 15.43 21.03 15.15 20.83 15L18.86 13.51C19.07 12.5 19.07 11.47 18.87 10.49H18.86L20.81 9C21.01 8.85 21.07 8.57 20.94 8.35L19.38 5.65C19.25 5.43 18.98 5.34 18.75 5.44L16.48 6.4L16.49 6.39C16.12 6.06 15.7 5.76 15.25 5.5C14.8 5.24 14.34 5.03 13.86 4.87"
                clipRule="evenodd"
                fillRule="evenodd"
            />
            <path
                fill="currentColor"
                d="M11.51 16C13.7191 16 15.51 14.2091 15.51 12C15.51 9.79086 13.7191 8 11.51 8C9.30087 8 7.51001 9.79086 7.51001 12C7.51001 14.2091 9.30087 16 11.51 16Z"
            />
            <path
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1"
                stroke="#0f0f0f"
                d="M11.51 16C13.7191 16 15.51 14.2091 15.51 12C15.51 9.79086 13.7191 8 11.51 8C9.30087 8 7.51001 9.79086 7.51001 12C7.51001 14.2091 9.30087 16 11.51 16Z"
            />
        </svg>
    );
};

export const StatsIcon: React.FC<SvgProps> = ({ className }) => {
    return (
        <svg
            className={className}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            data-reactroot=""
        >
            <path d="M6 7C5.44772 7 5 7.44772 5 8V18C5 18.5523 5.44772 19 6 19H8C8.55228 19 9 18.5523 9 18V8C9 7.44772 8.55228 7 8 7H6Z"></path>
            <path
                d="M2 1C2.55228 1 3 1.44772 3 2V21H22C22.5523 21 23 21.4477 23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22V2C1 1.44772 1.44772 1 2 1Z"
                clipRule="evenodd"
                fillRule="evenodd"
            ></path>
            <path d="M12 1C11.4477 1 11 1.44772 11 2V18C11 18.5523 11.4477 19 12 19H14C14.5523 19 15 18.5523 15 18V2C15 1.44772 14.5523 1 14 1H12Z"></path>
            <path d="M18 11C17.4477 11 17 11.4477 17 12V18C17 18.5523 17.4477 19 18 19H20C20.5523 19 21 18.5523 21 18V12C21 11.4477 20.5523 11 20 11H18Z"></path>
        </svg>
    );
};

type LoadingIconProps = {
    className: string;
};
export const LoadingIcon: React.FC<LoadingIconProps> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 360 360"
            fill="currentColor"
        >
            <path d="M180,1V61A119,119,0,1,1,61,180H1c0,98.7,80.3,179,179,179s179-80.3,179-179S278.7,1,180,1Z" />
        </svg>
    );
};

type TableSortArrowIconProps = {
    className?: string;
    direction: 'up' | 'down';
};
export const TableSortArrowIcon: React.FC<TableSortArrowIconProps> = ({
    className,
    direction,
}) => {
    const baseClass = 'transform transition duration-200';
    const rotation = direction === 'up' ? 'rotate-0' : 'rotate-180';
    return (
        <svg
            className={`${baseClass} ${rotation} ${className}`}
            viewBox="0 0 360 360"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <polyline points="359 90.5 180 269.5 1 90.5" />
        </svg>
    );
};

export const ClearFiltersIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 360 360"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M274,1a89.5,89.5,0,0,0-53.7,161.1V359l-71.6-35.8v-179L5.5,1Z" />
        <path d="M274,10A80.55,80.55,0,1,0,354.56,90.5,80.64,80.64,0,0,0,274,10Zm44.27,112.17-12.66,12.65L274,103.16l-31.62,31.61-12.66-12.65L261.34,90.5,229.72,58.88l12.66-12.65L274,77.85l31.61-31.62,12.66,12.65L286.65,90.5Z" />
    </svg>
);

export const TwitterIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 5.89C21.26 6.21 20.46 6.42 19.64 6.53C20.49 6.03 21.14 5.24 21.44 4.3C20.65 4.77 19.77 5.09 18.84 5.28C18.09 4.49 17.02 4 15.85 4C13.58 4 11.75 5.81 11.75 8.04C11.75 8.36 11.78 8.67 11.84 8.96C8.43999 8.79 5.41999 7.19 3.38999 4.74C3.03999 5.34 2.82999 6.03 2.82999 6.78C2.82999 8.18 3.55999 9.42 4.64999 10.13C3.98999 10.12 3.33999 9.93 2.79999 9.63V9.67C2.79999 11.63 4.21999 13.26 6.07999 13.64C5.74999 13.73 5.37999 13.77 4.99999 13.77C4.73999 13.77 4.46999 13.76 4.21999 13.7C4.74999 15.3 6.25999 16.48 8.04999 16.51C6.65999 17.59 4.87999 18.23 2.96999 18.23C2.62999 18.23 2.30999 18.21 1.98999 18.17C3.81999 19.34 5.96999 20 8.28999 20C15.84 20 19.96 13.85 19.96 8.51C19.96 8.33 19.95 8.16 19.94 7.99C20.76 7.42 21.44 6.71 22 5.89Z"></path>
    </svg>
);

export const HeartIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 22"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M17 3C14.1196 2.99993 12 6 12 6C12 6 10.0808 3 7 3C4.09916 3 2.0001 5.00004 2 7.99998C2.0001 10.9999 3.0001 13 6 16C8.0001 18 12 21 12 21C12 21 16.0001 18 18 16C21.0001 13 22.0001 11 22.0001 8C22.0001 5.00004 19.9008 3.00007 17 3Z" />
    </svg>
);

export const DiscordIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        fill="currentColor"
        viewBox="0 -28.5 256 256"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
    >
        <g>
            <path
                d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                fillRule="nonzero"
            ></path>
        </g>
    </svg>
);

export const MenuIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 18C2 17.4477 2.44772 17 3 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
        <path
            d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
        <path
            d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
    </svg>
);

export const ChevronLeftIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.7071 3.29289C16.3166 2.90237 15.6834 2.90237 15.2929 3.29289L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071C17.0976 20.3166 17.0976 19.6834 16.7071 19.2929L9.41421 12L16.7071 4.70711C17.0976 4.31658 17.0976 3.68342 16.7071 3.29289Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
    </svg>
);

export const HomeIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M20 8.4L12 2L4 8.4V22H20V8.4Z"></path>
        <path
            d="M11.3803 2.21512C11.7436 1.92829 12.2563 1.92829 12.6196 2.21512L22.1196 9.71512C22.5531 10.0573 22.6271 10.6862 22.2849 11.1196C21.9426 11.5531 21.3138 11.6271 20.8803 11.2849L12 4.27408L3.11962 11.2849C2.68615 11.6271 2.05732 11.5531 1.7151 11.1196C1.37288 10.6862 1.44686 10.0573 1.88034 9.71512L11.3803 2.21512Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
    </svg>
);

export const LeftArrowIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 21L2 12L22 3L17 12L22 21Z" />
    </svg>
);

export const RightArrowIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={`${className} rotate-180`}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M22 21L2 12L22 3L17 12L22 21Z" />
    </svg>
);

export const ShuffleIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 -960 960 960"
        className={className}
    >
        <path d="M581-150v-60h125L522-393l42-43 186 184v-127h60v229H581Zm-389 0-42-43 558-558H581v-60h229v229h-60v-126L192-150Zm203-374L150-768l43-43 245 244-43 43Z" />
    </svg>
);

export const DoubleChevronDownIcon: React.FC<SvgProps> = ({ className }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071L20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L12 10.5858L4.70711 3.29289C4.31658 2.90237 3.68342 2.90237 3.29289 3.29289C2.90237 3.68342 2.90237 4.31658 3.29289 4.70711L11.2929 12.7071Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
        <path
            d="M11.2929 20.7071C11.6834 21.0976 12.3166 21.0976 12.7071 20.7071L20.7071 12.7071C21.0976 12.3166 21.0976 11.6834 20.7071 11.2929C20.3166 10.9024 19.6834 10.9024 19.2929 11.2929L12 18.5858L4.70711 11.2929C4.31658 10.9024 3.68342 10.9024 3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071L11.2929 20.7071Z"
            clipRule="evenodd"
            fillRule="evenodd"
        ></path>
    </svg>
);
