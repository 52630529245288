import React  from 'react';
import AppContainer from '../../components/common/layout/AppContainer';
import AppCard from '../../components/common/layout/AppCard';
import {Link} from "react-router-dom";
import clsx from "clsx";
import {eventName} from "../../config";
import {aprilfools} from "../../constants/misc/eventConstants";
import {useAppSelector} from "../../app/hooks";

const PrivacyPolicyPage: React.FC = () => {
    const eventActive = useAppSelector((state) => state.events.eventActive);

    return (
        <AppContainer
            outerClassName={clsx('max-h-full', eventActive && eventName === aprilfools && 'transform rotate-180')}
            innerClassName="w-full items-center py-4 text-accent-1 flex flex-col"
            fillScreen={true}
        >
            <h2 className="text-2xl text-center font-semibold mb-4 uppercase">
                Privacy Policy
            </h2>

            <AppCard isScrolling={true} className="flex-grow w-full policy-container">
                <p>Our website is committed to protecting the privacy of our visitors. This privacy policy describes the types of personal information we collect, how we use it, and your rights regarding your personal information.</p>
                <h3>Information Collection and Use:</h3>
                <ul>
                    <li>We collect personal information from you when you use our website, such as your IP address. We use this information to provide you with the services and features offered on our website, and to improve the performance of our website.</li>
                </ul>
                <h3>Cookies and Tracking Technologies:</h3>
                <ul>
                    <li>We use cookies and other tracking technologies to collect information about your use of our website. We use this information to personalize the content and advertising shown to you, to understand the interests of our visitors, and to improve the performance of our website. Please see our <Link
                        to="/cookie-policy"
                        className="text-primary-1 hover:text-primary-2"
                    >
                        Cookie Policy
                    </Link>{' '} for more information.</li>
                </ul>
                <h3>Sharing of Personal Information:</h3>
                <ul>
                    <li>We do not share your personal information with third parties, except in the following circumstances:
                        <ul>
                            <li>With your consent.</li>
                            <li>To comply with legal requirements, such as a subpoena or court order.</li>
                            <li>To protect the safety of our visitors or to enforce our legal rights.</li>
                        </ul>
                    </li>
                </ul>
                <h3>Security:</h3>
                <ul>
                    <li>We take appropriate security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information.</li>
                </ul>
                <h3>Your Rights:</h3>
                <ul>
                    <li>You have the right to access, correct, update, or request deletion of your personal information. You also have the right to withdraw your consent to our use of your personal information at any time. To exercise these rights, please contact us at the email address provided below.</li>
                </ul>
                <p>Changes to this Privacy Policy:</p>
                <ul>
                    <li>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on our website.</li>
                </ul>
                <h3>Contact Us:</h3>
                <ul>
                    <li>If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:flickle.contact@gmail.com">flickle.contact@gmail.com</a>.</li>
                </ul>
                <p>By using our website, you confirm that you have read and understood this privacy policy and that you agree to our collection, use, and sharing of your personal information as described above.</p>
            </AppCard>
        </AppContainer>
    );
};
export default PrivacyPolicyPage;
