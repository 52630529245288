import React from 'react';
import AppContainer from '../../components/common/layout/AppContainer';
import AppCard from '../../components/common/layout/AppCard';
import {useAppSelector} from "../../app/hooks";
import {eventName} from "../../config";
import {aprilfools} from "../../constants/misc/eventConstants";
import clsx from "clsx";

const CookiePolicyPage: React.FC = () => {
    const eventActive = useAppSelector((state) => state.events.eventActive);

    return (
        <AppContainer
            outerClassName={clsx('max-h-full', eventActive && eventName === aprilfools && 'transform rotate-180')}
            innerClassName="w-full items-center py-4 text-accent-1 flex flex-col"
            fillScreen={true}
        >
            <h2 className="text-2xl text-center font-semibold mb-4 uppercase">
                Cookie Policy
            </h2>

            <AppCard className="flex-grow w-full policy-container" isScrolling={true}>
                <p>Our website uses cookies to improve your browsing experience and to personalize the content and advertising shown to you. By using our website, you consent to our use of cookies.</p>
                <h3>Google Analytics:</h3>
                <ul>
                    <li>We use Google Analytics, a web analytics service provided by Google, to help us understand how our visitors engage with our website. Google Analytics uses cookies to collect information about your use of our website, including the number of visits, the duration of each visit, the pages viewed, and the sources of traffic to our website. This information is used to improve the performance of our website and to understand the interests of our visitors.</li>
                </ul>
                <h3>Google Adsense:</h3>
                <ul>
                    <li>We also use Google Adsense, a program for displaying ads on websites, to serve ads to you on our website. Google Adsense uses cookies to understand your interests and to display ads that are relevant to you. You can opt out of personalized advertising by rejecting essential cookies from the settings page or by installing the Google Analytics Opt-out Browser Add-on.</li>
                </ul>
                <p>You can control the use of cookies on our website by adjusting the settings on your browser. However, please note that disabling cookies may affect your ability to use some features of our website.</p>
                <p>By using our website, you confirm that you have read and understood this cookie policy and that you agree to our use of cookies as described above.</p>
            </AppCard>
        </AppContainer>
    );
};
export default CookiePolicyPage;
