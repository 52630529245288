import {DateTime, Duration, DurationUnits} from "luxon";

const debugOffsetMinutes = 0;

export const getDateTimeLocalNow = (ignoreDebugOffset = true): DateTime => {
    return DateTime.now().plus({minutes: debugOffsetMinutes})
}

export const getDateTimeUtcNow = (ignoreDebugOffset = true): DateTime => {
    return DateTime.now().plus({minutes: debugOffsetMinutes})
}

export const getDiffNow = (dateTime: DateTime, durationUnits?: DurationUnits, ignoreDebugOffset = true): Duration => {
    return dateTime.diff(getDateTimeLocalNow(ignoreDebugOffset), durationUnits)
}

export const getDurationFormat = (duration: Duration) => {
    return duration.as('hours') > 48
        ? "d'd'"
        : duration.as('minutes') > 60
            ? "h'hr'"
            : duration.as('seconds') > 60
                ? "m'm'"
                : "s's'";
}
