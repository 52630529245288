import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setNewAppVersionModalIsOpen } from '../../store/navigationSlice';
import AppButton from '../common/inputs/AppButton';
import AppDialog from '../common/layout/AppDialog';

const NewAppVersionModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const message = useAppSelector((state) => state.settings.appVersionMessage);

    const closeModal = () => {
        window.location.reload();
        dispatch(setNewAppVersionModalIsOpen(false));
    };

    const modalButtons = () => <AppButton onClick={closeModal}>OK</AppButton>;

    return (
        <AppDialog
            close={closeModal}
            buttons={modalButtons()}
            title="New Version"
        >
            {message && (
                <>
                    <div className="bg-canvas-6 w-56 self-center mb-4 p-2">
                        <p className="text-left">{message}</p>
                    </div>
                </>
            )}

            <p className="text-center">
                Click OK or refresh the page to update.
            </p>
        </AppDialog>
    );
};
export default NewAppVersionModal;
