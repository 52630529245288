import {
    BookIcon,
    DefaultUserIcon, HistoryIcon,
    HomeIcon,
    SettingsIcon,
    StatsIcon,
} from '../../components/common/misc/Svgs';
import React from 'react';
import { MenuItem } from '../../models/navigation/appMenu';

export const generateCommonMenuItems = (
    getIconColor: (isDisabled?: boolean, isAlt?: boolean) => string,
    statsEnabled: boolean,
): MenuItem[] => [
    {
        route: '/',
        text: 'Home',
        icon: <HomeIcon className={`w-6 h-6 ${getIconColor()}`} />,
    },
    {
        route: '/settings',
        text: 'Settings',
        icon: <SettingsIcon className={`w-6 h-6 ${getIconColor()}`} />,
    },
    {
        route: '/statistics',
        text: 'Stats',
        isDisabled: !statsEnabled,
        icon: (
            <StatsIcon className={`w-5 h-5 ${getIconColor(!statsEnabled)}`} />
        ),
    },
    {
        route: '/archive',
        text: 'Archive',
        isDisabled: false,
        isAlt: true,
        icon: (
            <HistoryIcon className={`w-5 h-5 ${getIconColor(false, true)}`} />
        ),
    },
];

export const generateAnonMenuItems = (
    getIconColor: (isDisabled?: boolean) => string,
): MenuItem[] => [
    {
        route: '/login',
        text: 'Login',
        icon: <DefaultUserIcon className={`w-5 h-5 ${getIconColor()}`} />,
    },
];

export const generateUserMenuItems = (
    getIconColor: (isDisabled?: boolean) => string,
): MenuItem[] => [
    {
        route: '/account',
        text: 'Account',
        icon: <DefaultUserIcon className={`w-5 h-5 ${getIconColor()}`} />,
    },
];

export const generateAdminMenuItems = (
    getIconColor: (isDisabled?: boolean) => string,
): MenuItem[] => [
    {
        route: '/rounds',
        text: 'Rounds',
        icon: <SettingsIcon className={`w-5 h-5 ${getIconColor()}`} />,
    },
];
