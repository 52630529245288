import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CookiePolicyStatusType = 'pending' | 'essential' | 'full';

type SettingsState = {
    firstVisit: boolean;
    shareLink: boolean;
    shareStats: boolean;
    shareStatsBest: boolean;
    shareStatsShort: boolean;
    shareStatsNegative: boolean;
    shareOverrideCopyToClipboard: boolean;
    appVersionLastUpdated: string;
    appVersionMessage?: string;
    cookiePolicyStatus: CookiePolicyStatusType;
};

const settingsInitialState: SettingsState = {
    firstVisit: true,
    shareLink: true,
    shareStats: true,
    shareStatsBest: true,
    shareStatsShort: false,
    shareStatsNegative: true,
    shareOverrideCopyToClipboard: false,
    appVersionLastUpdated: '0',
    cookiePolicyStatus: 'pending',
};

const settings = createSlice({
    name: 'settings',
    initialState: settingsInitialState,
    reducers: {
        setFirstVisit: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.firstVisit = action.payload;
        },
        setShareLink: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.shareLink = action.payload;
        },
        setShareStats: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.shareStats = action.payload;
        },
        setShareStatsBest: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.shareStatsBest = action.payload;
        },
        setShareStatsShort: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.shareStatsShort = action.payload;
        },
        setShareStatsNegative: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.shareStatsNegative = action.payload;
        },
        setShareOverrideCopyToClipboard: (
            state: SettingsState,
            action: PayloadAction<boolean>,
        ) => {
            state.shareOverrideCopyToClipboard = action.payload;
        },
        setAppVersionLastUpdated: (
            state: SettingsState,
            action: PayloadAction<string>,
        ) => {
            state.appVersionLastUpdated = action.payload;
        },
        setAppVersionMessage: (
            state: SettingsState,
            action: PayloadAction<string | undefined>,
        ) => {
            state.appVersionMessage = action.payload;
        },
        setCookiePolicyStatus: (
            state: SettingsState,
            action: PayloadAction<CookiePolicyStatusType>,
        ) => {
            state.cookiePolicyStatus = action.payload;
        },
    },
});

export const {
    setFirstVisit,
    setShareLink,
    setShareStats,
    setShareStatsBest,
    setShareStatsShort,
    setShareStatsNegative,
    setShareOverrideCopyToClipboard,
    setAppVersionLastUpdated,
    setAppVersionMessage,
    setCookiePolicyStatus,
} = settings.actions;

export default settings.reducer;
