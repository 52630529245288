import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlicksModalState, Modal } from '../models/navigation/modalState';

type NavigationState = {
    menuOpen: boolean;
    rulesModalOpen: boolean;
    statsModalOpen: boolean;
    settingsModalOpen: boolean;
    newAppVersionModalOpen: boolean;
    guessesModalOpen: boolean;
    socialsModalOpen: boolean;
    policyConsentPanelOpen: boolean;
    flicksModalState: Modal<FlicksModalState>;
    timeLastRefreshed?: string,
};

const navigationInitialState: NavigationState = {
    menuOpen: false,
    rulesModalOpen: false,
    statsModalOpen: false,
    settingsModalOpen: false,
    newAppVersionModalOpen: false,
    guessesModalOpen: false,
    socialsModalOpen: false,
    policyConsentPanelOpen: false,
    flicksModalState: { isOpen: false },
};

const closeAllModals = (state: NavigationState) => {
    state.menuOpen = false;
    state.rulesModalOpen = false;
    state.statsModalOpen = false;
    state.settingsModalOpen = false;
    state.guessesModalOpen = false;
    state.newAppVersionModalOpen = false;
    state.socialsModalOpen = false;
    state.flicksModalState = { isOpen: false };
};

const navigation = createSlice({
    name: 'navigation',
    initialState: navigationInitialState,
    reducers: {
        setTimeLastRefreshed: (state: NavigationState,
                               action: PayloadAction<string>) => {
            state.timeLastRefreshed = action.payload;
        },
        setMenuIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.menuOpen = action.payload;
        },
        setRulesModalIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.rulesModalOpen = action.payload;
        },
        setStatsModalIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.statsModalOpen = action.payload;
        },
        setSettingsModalIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.settingsModalOpen = action.payload;
        },
        setNewAppVersionModalIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.newAppVersionModalOpen = action.payload;
        },
        setGuessesModalIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.guessesModalOpen = action.payload;
        },
        setSocialsModalIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            closeAllModals(state);
            state.socialsModalOpen = action.payload;
        },
        setPolicyConsentPanelIsOpen: (
            state: NavigationState,
            action: PayloadAction<boolean>,
        ) => {
            state.policyConsentPanelOpen = action.payload;
        },
        setFlicksModalState: (
            state: NavigationState,
            action: PayloadAction<Modal<FlicksModalState>>,
        ) => {
            closeAllModals(state);
            state.flicksModalState = action.payload;
        },
        setAllModalsClosed: (state: NavigationState) => {
            closeAllModals(state);
        },
    },
});

export const {
    setTimeLastRefreshed,
    setMenuIsOpen,
    setRulesModalIsOpen,
    setStatsModalIsOpen,
    setSettingsModalIsOpen,
    setGuessesModalIsOpen,
    setNewAppVersionModalIsOpen,
    setSocialsModalIsOpen,
    setPolicyConsentPanelIsOpen,
    setFlicksModalState,
    setAllModalsClosed,
} = navigation.actions;

export default navigation.reducer;
