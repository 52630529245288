import { useEffect, useMemo, useState } from 'react';

function classNameStringToArray(className: string): string[] {
    return className.trim().split(' ');
}

export type ClassyBoyFunctions = {
    add: (...input: (string | undefined)[]) => void;
    set: (...input: (string | undefined)[]) => void;
    remove: (input: string) => void;
    replace: (input: string, ...target: string[]) => void;
    clear: () => void;
    result: string;
};

export function useClassyBoy(
    ...baseClasses: (string | undefined)[]
): ClassyBoyFunctions {
    const [classes, setClasses] = useState<string[]>([]);

    useEffect(() => {
        const initialClasses: string[] = baseClasses
            .flatMap((item) => classNameStringToArray(item ?? ''))
            .filter((item) => item !== '');

        setClasses(initialClasses);
        //LogDebug('useClassyBoy - effect', classes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...baseClasses]);

    function add(...input: (string | undefined)[]): void {
        const inputClasses = input
            .flatMap((item) => classNameStringToArray(item ?? ''))
            .filter((item) => item !== '' && !classes.includes(item));

        if (inputClasses.length > 0) {
            setClasses(classes.concat(inputClasses));
        }
        //LogDebug('useClassyBoy - add', input, classes);
    }

    function set(...input: (string | undefined)[]): void {
        const inputClasses = input.flatMap((item) =>
            classNameStringToArray(item ?? ''),
        );

        if (classes.join('') !== inputClasses.join('')) {
            setClasses(inputClasses);
        }
        //LogDebug('useClassyBoy - add', input, classes);
    }

    function remove(input: string): void {
        const classesToRemove = classNameStringToArray(input).filter((item) =>
            classes.includes(item),
        );
        if (classesToRemove.length > 0) {
            setClasses(classes.filter((x) => !classesToRemove.includes(x)));
        }
        //LogDebug('useClassyBoy - remove', input, classes);
    }

    function replace(input: string, ...target: string[]): void {
        const inputClasses = classNameStringToArray(input);
        const classesToRemove = target
            .flatMap((item) => classNameStringToArray(item ?? ''))
            .filter(
                (item) => classes.includes(item) || inputClasses.includes(item),
            );
        const inputClassesFiltered = inputClasses.filter(
            (item) => item !== '' && !classes.includes(item),
        );

        if (classesToRemove.length > 0 || inputClassesFiltered.length > 0) {
            setClasses(
                classes
                    .filter((x) => !classesToRemove.includes(x))
                    .concat(inputClassesFiltered),
            );
        }
        //LogDebug('useClassyBoy - replace', target, input, classes);
    }

    function clear(): void {
        setClasses([]);
        //LogDebug('useClassyBoy - clear');
    }

    const result = useMemo(() => {
        return classes.join(' ');
    }, [classes]);

    return { add, set, remove, replace, clear, result };
}
