import React, { useEffect, useState } from 'react';
import { LoadingIcon } from './Svgs';

type SpinnerProps = {
    className?: string;
    delay?: number;
};

const LoadingSpinner: React.FC<SpinnerProps> = ({ className, delay }) => {
    const [show, setShow] = useState(false);
    const baseClasses = 'animate-spin rounded-full h-6 w-6 text-white';

    useEffect(() => {
        if (delay) {
            setTimeout(() => {
                setShow(true);
            }, delay);
        } else {
            setShow(true);
        }
    }, [show]);

    if (!show) return null;

    return (
        <div className="flex justify-center items-center">
            <LoadingIcon className={`${className} ${baseClasses}`} />
        </div>
    );
};

export default LoadingSpinner;
