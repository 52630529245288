import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { animateTransform } from '../../../constants/components/tailwindClassCollections';
import { useClassyBoy } from '../../../hooks/useClassyBoy';
import LoadingSpinner from '../misc/LoadingSpinner';
import AppCardTitle from './AppCardTitle';

type AppCardProps = {
    children: React.ReactNode;
    className?: string;
    layoutClassName?: string;
    onClick?: Function;
    onMouseEnter?: Function;
    onMouseLeave?: Function;
    style?: React.CSSProperties;
    isLoading?: boolean;
    loadingHeightStyle?: string;
    loadingIndicatorClassname?: string;
    title?: string;
    isScrolling?: boolean;
};

const AppCard: React.FC<AppCardProps> = ({
    children,
    className,
    layoutClassName,
    onClick,
    onMouseEnter,
    onMouseLeave,
    style,
    isLoading,
    loadingHeightStyle,
    loadingIndicatorClassname = 'h-12 w-12',
    title,
    isScrolling = false,
}: any) => {
    const classes = useClassyBoy(
        'rounded-lg shadow-card bg-canvas-5 relative flex flex-col',
        animateTransform(),
        className,
    );

    classes.add(layoutClassName ?? 'p-4');

    return (
        <div
            className={classes.result}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={style}
        >
            {title && <AppCardTitle value={title} />}
            <div className="flex-grow">
                {isLoading ? (
                    <div
                        className="flex w-full h-full align-center justify-center"
                        style={
                            loadingHeightStyle && isLoading
                                ? { height: loadingHeightStyle }
                                : undefined
                        }
                    >
                        <LoadingSpinner className={loadingIndicatorClassname} />
                    </div>
                ) : isScrolling ? (
                    <Scrollbars>{children}</Scrollbars>
                ) : (
                    children
                )}
            </div>
        </div>
    );
};

export default AppCard;
