import React, { MutableRefObject, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    useAddFlickMutation,
    useSearchFlicksAdminQuery,
} from '../../app/services/api';
import useFocus from '../../hooks/useFocus';
import { ResponseModel } from '../../models/api/common/response';
import { ApiRequestAddFlick } from '../../models/api/flick';
import {
    Flick,
    FlickAdminSearchResult,
    FlickSearchResult,
} from '../../models/game/flick';
import { setFlicksModalState } from '../../store/navigationSlice';
import AppFormSubmitButton from '../common/forms/AppFormSubmitButton';
import AppFormTextInput from '../common/forms/AppFormTextInput';
import AppButton from '../common/inputs/AppButton';
import AppSearchBar, {
    AppSearchBarCallables,
} from '../common/inputs/AppSearchBar';
import AppDialog from '../common/layout/AppDialog';
import AppCheckbox from "../common/inputs/AppCheckbox";

export type FlicksModalCallables = {
    setFocus: MutableRefObject<null> | (() => void);
};

type FlicksModalProps = {
    setCallables?: (callables: FlicksModalCallables) => void;
};

const FlicksModal: React.FC<FlicksModalProps> = ({ setCallables }) => {
    const dispatch = useAppDispatch();

    const modalState = useAppSelector(
        (state) => state.navigation.flicksModalState,
    );

    const [inputRef, setInputFocus] = useFocus();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [debouncedSearchQuery, { isPending: debounceIsPending }] =
        useDebounce(searchQuery, 300);
    const [searchOptions, setSearchOptions] = useState<
        FlickAdminSearchResult[]
    >([]);
    const [selectedOption, setSelectedOption] = useState<FlickSearchResult>();
    const [showSearchOptions, setShowSearchOptions] = useState<boolean>(false);
    const [searchBarCallables, setSearchBarCallables] =
        useState<AppSearchBarCallables>();
    const [addFlickFormOpen, setAddFLickFormOpen] = useState<boolean>(false);
    const [isReplay, setIsReplay] = useState<boolean>(modalState.state?.isReplay ?? false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ApiRequestAddFlick>();

    const isSearchQueryValid = (value: string): boolean => value.length >= 2;

    const searchFlicksQueryResponse = useSearchFlicksAdminQuery(
        {
            search: debouncedSearchQuery,
        },
        {
            skip: !isSearchQueryValid(debouncedSearchQuery),
        },
    );

    const [addFlick, { isLoading: isAddingFlick }] = useAddFlickMutation();

    useEffect(() => {
        if (
            searchFlicksQueryResponse.isSuccess &&
            searchFlicksQueryResponse.data
        ) {
            const flicks = searchFlicksQueryResponse.data.content.items;

            setSearchOptions(flicks);
        }
    }, [searchFlicksQueryResponse.data, searchFlicksQueryResponse.isSuccess]);

    useEffect(() => {
        if (searchBarCallables) {
            if (selectedOption?.title){
                searchBarCallables.setValue(selectedOption.title);
                setSearchQuery(selectedOption?.title);
            } else {
                searchBarCallables.setValue('');
                setSearchQuery('');
            }
        }
    }, [searchBarCallables, selectedOption]);

    useEffect(() => {
        if (setCallables) {
            setCallables({
                setFocus: setInputFocus,
            });
        }
    }, [setCallables]);

    useEffect(() => {
        window.addEventListener('click', onWindowClick);

        if (modalState.state) {
            setSelectedOption({
                id: modalState.state!.flickId!,
                title: modalState.state!.flickTitle!,
            });
        }

        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    }, []);

    const closeModal = () => {
        dispatch(
            setFlicksModalState({ isOpen: false, state: modalState.state }),
        );
    };

    const saveAndCloseModal = () => {
        console.warn(selectedOption, isReplay)
        if (selectedOption === undefined && !isReplay) return;

        dispatch(
            setFlicksModalState({
                isOpen: false,
                state: {
                    flickId: selectedOption?.id,
                    flickTitle: selectedOption?.title,
                    isReplay: isReplay
                },
            }),
        );
    };

    const processSearchQuery = (value: string) => {
        if (isSearchQueryValid(value)) {
            if (selectedOption) {
                setSelectedOption(undefined);
            }
            setSearchQuery(value);
            setShowSearchOptions(true);
        } else {
            setSearchQuery('');
        }
    };

    const searchBarOnFocus = () => {
        if (isReplay) return;
        setShowSearchOptions(true);
    };

    const onClickOption = (option: FlickAdminSearchResult) => {
        if (option.hasRound && option.id !== modalState.state?.flickId) return;
        setSelectedOption(option);
        setShowSearchOptions(false);
    };

    const onWindowClick = (event: MouseEvent) => {
        const allowedInputs = ['searchOption', 'searchBar'];
        if (
            !event.target ||
            !(event.target instanceof HTMLElement) ||
            !allowedInputs.includes(event.target.id)
        ) {
            setShowSearchOptions(false);
        } else if (
            event.target.id === 'searchBar' &&
            isSearchQueryValid(searchQuery)
        ) {
            setShowSearchOptions(true);
        }
    };

    const onSubmitNewFlick = (request: ApiRequestAddFlick) => {
        try {
            addFlick(request)
                .unwrap()
                .then((response: ResponseModel<Flick>) => {
                    setSelectedOption(response.content);
                    setAddFLickFormOpen(false);
                });
        } catch (addFlickError) {
            console.warn(addFlickError);
        }
    };

    const modalButtons = () => (
        <div className="flex justify-between w-full">
            <AppButton mode="secondary" onClick={closeModal}>
                CANCEL
            </AppButton>
            <AppButton
                onClick={saveAndCloseModal}
                isEnabled={selectedOption?.id !== undefined || isReplay}
            >
                SAVE
            </AppButton>
        </div>
    );

    return (
        <AppDialog
            close={closeModal}
            buttons={addFlickFormOpen ? undefined : modalButtons()}
            title="Flicks"
        >
            {!addFlickFormOpen && (
                <>
                    <div className="relative w-full">
                        {showSearchOptions &&
                            searchQuery.length >= 2 &&
                            !searchFlicksQueryResponse.isLoading && (
                                <div className="absolute w-full top-11 overflow-y-scroll max-h-[15rem] border-t border-accent-1">
                                    {searchOptions.length > 0 ? (
                                        searchOptions.map((option, index) => (
                                            <div
                                                id="searchOption"
                                                key={index}
                                                onClick={() => {
                                                    onClickOption(option);
                                                }}
                                                className="cursor-pointer flex items-center w-full -mt-1 min-h-[2.5rem] border border-accent-1 text-accent-1 px-2 bg-canvas-4"
                                            >
                                                <span
                                                    className={`pointer-events-none py-2 ${
                                                        option.hasRound &&
                                                        option.id !==
                                                            modalState.state
                                                                ?.flickId &&
                                                        'text-error-1'
                                                    }`}
                                                >
                                                    {option.title}
                                                </span>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="flex items-center w-full -mt-1 min-h-[2.5rem] border border-accent-1 text-accent-2 px-2 bg-canvas-4">
                                            <span className="pointer-events-none py-2">
                                                No movies found
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                    </div>
                    <AppSearchBar
                        id="searchBar"
                        placeholder="Start typing to find movies"
                        className="w-full"
                        setSearchQuery={processSearchQuery}
                        onFocus={searchBarOnFocus}
                        inputRef={inputRef}
                        setCallables={setSearchBarCallables}
                        isLoading={
                            searchFlicksQueryResponse.isLoading ||
                            searchFlicksQueryResponse.isFetching ||
                            debounceIsPending()
                        }
                        isEnabled={!isReplay}
                    />
                    <span
                        className="w-full text-center text-primary-1 cursor-pointer mt-2"
                        onClick={() => setAddFLickFormOpen(true)}
                    >
                        Add New
                    </span>


                    <div className="flex self-center items-center justify-center mt-2">
                        <AppCheckbox
                            value={isReplay}
                            mode="alt"
                            setValue={() => {
                                setIsReplay(!isReplay)
                                setSelectedOption(undefined)
                            }}
                        />
                        <span
                            className="w-full text-center text-secondary-1 ml-2"
                        >
                        Set Replay
                    </span>
                    </div>

                </>
            )}
            {addFlickFormOpen && (
                <>
                    <form
                        onSubmit={handleSubmit(onSubmitNewFlick)}
                        className="flex flex-col items-center"
                    >
                        <AppFormTextInput
                            type="text"
                            formProperties={register('title', {
                                required: true,
                            })}
                        />
                        {errors.title && (
                            <span className="text-error-1 mt-2">
                                Title cannot be empty
                            </span>
                        )}

                        <div className="w-full flex justify-between mt-8">
                            <AppButton
                                className="uppercase"
                                mode="secondary"
                                value="Back"
                                isEnabled={!isAddingFlick}
                                onClick={() => setAddFLickFormOpen(false)}
                            />
                            <AppFormSubmitButton
                                type="submit"
                                isEnabled={!isAddingFlick}
                                value={isAddingFlick ? 'Loading...' : 'Add'}
                                sizeClassName="w-auto h-8 px-4"
                            />
                        </div>
                    </form>
                </>
            )}
        </AppDialog>
    );
};
export default FlicksModal;
