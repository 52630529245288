import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    useGetAppVersionQuery,
    useLazyGetAppVersionQuery,
} from '../app/services/api';
import AppBar from '../components/common/layout/AppBar';
import AppMenuContainer from '../components/common/layout/AppMenuContainer/AppMenuContainer';
import AppMenuMobileContainer from '../components/common/layout/AppMenuContainer/AppMenuMobileContainer';
import PolicyConsent from '../components/common/misc/PolicyConsent';
import FlicksModal from '../components/modals/FlicksModal';
import GuessesModal from '../components/modals/GuessesModal';
import NewAppVersionModal from '../components/modals/NewAppVersionModal';
import RulesModal from '../components/modals/RulesModal';
import SocialsModal from '../components/modals/SocialsModal';
import { eventEndDate, eventLive, eventName, eventStartDate } from '../config';
import { aprilfools } from '../constants/misc/eventConstants';
import { ResponseModel } from '../models/api/common/response';
import { ApiResultVersion } from '../models/api/version';
import HomeRoutes from '../routes/HomeRoutes';
import { setSessionId } from '../store/accountSlice';
import { setEventActive } from '../store/eventsSlice';
import {
    setNewAppVersionModalIsOpen,
    setPolicyConsentPanelIsOpen,
    setRulesModalIsOpen,
    setTimeLastRefreshed,
} from '../store/navigationSlice';
import {
    setAppVersionLastUpdated,
    setAppVersionMessage,
    setFirstVisit,
} from '../store/settingsSlice';
import { getDateTimeLocalNow, getDiffNow } from '../utils/dateTimeUtils';
import { LogDebug } from '../utils/loggingUtils';

const HomePage: React.FC = () => {
    const dispatch = useAppDispatch();
    const localEventStartDate = useRef<DateTime | undefined>();
    const localEventEndDate = useRef<DateTime | undefined>();
    const location = useLocation();
    const navigate = useNavigate();

    const firstVisit = useAppSelector((state) => state.settings.firstVisit);
    const navigation = useAppSelector((state) => state.navigation);
    const settings = useAppSelector((state) => state.settings);
    const account = useAppSelector((state) => state.account);
    const eventActive = useAppSelector((state) => state.events.eventActive);

    const embedNoticeActive = location.pathname === '/embed-detected';

    const getShortAppVersionResponse = useGetAppVersionQuery(false, {
        refetchOnMountOrArgChange: true,
        pollingInterval: 10000,
    });

    const [getLongAppVersionQueryTrigger, getLongAppVersionResponse] =
        useLazyGetAppVersionQuery();

    const getIsEventActive = (): boolean => {
        return (
            localEventStartDate.current !== undefined &&
            localEventEndDate.current !== undefined &&
            getDiffNow(localEventStartDate.current).toMillis() < 0 &&
            getDiffNow(localEventEndDate.current).toMillis() > 0
        );
    };

    const updateEventActive = () => {
        const eventActive = getIsEventActive();
        dispatch(setEventActive(eventActive));
        LogDebug(
            'updateEventActive',
            localEventStartDate.current
                ? getDiffNow(localEventStartDate.current).toMillis()
                : -999,
            localEventEndDate.current
                ? getDiffNow(localEventEndDate.current).toMillis()
                : -999,
            eventActive,
        );
    };

    useEffect(() => {
        if (
            window.top !== null &&
            window.top !== window.self &&
            location.pathname !== '/embed-detected'
        ) {
            navigate('/embed-detected', { replace: true });
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        if (getShortAppVersionResponse.isSuccess) {
            const appVersionUpdateDateTime =
                getShortAppVersionResponse.data.content.dateTime;

            if (
                settings.appVersionLastUpdated < appVersionUpdateDateTime &&
                !firstVisit &&
                !navigation.rulesModalOpen
            ) {
                try {
                    getLongAppVersionQueryTrigger(true)
                        .unwrap()
                        .then((response: ResponseModel<ApiResultVersion>) => {
                            dispatch(
                                setAppVersionLastUpdated(
                                    response.content.dateTime,
                                ),
                            );
                            dispatch(
                                setAppVersionMessage(response.content.message),
                            );
                            if (settings.appVersionLastUpdated !== '0') {
                                dispatch(setNewAppVersionModalIsOpen(true));
                            }
                        });
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }, [getShortAppVersionResponse]);

    useEffect(() => {
        dispatch(setTimeLastRefreshed(getDateTimeLocalNow().toISO()));

        if (firstVisit) {
            setTimeout(() => {
                dispatch(setRulesModalIsOpen(true));
                dispatch(setFirstVisit(false));
            }, 100);
        }

        if (eventLive) {
            localEventStartDate.current =
                eventStartDate !== undefined
                    ? DateTime.fromISO(eventStartDate)
                    : undefined;
            localEventEndDate.current =
                eventEndDate !== undefined
                    ? DateTime.fromISO(eventEndDate)
                    : undefined;
            updateEventActive();
            setInterval(updateEventActive, 1000);
        }

        if (account.sessionId === undefined) {
            dispatch(setSessionId(undefined));
        }
    }, []);

    useEffect(() => {
        if (
            settings.cookiePolicyStatus === 'pending' &&
            !navigation.policyConsentPanelOpen
        ) {
            dispatch(setPolicyConsentPanelIsOpen(true));
        }
    }, [navigation.policyConsentPanelOpen, settings.cookiePolicyStatus]);

    return (
        <>
            {!embedNoticeActive && navigation.rulesModalOpen && <RulesModal />}
            {!embedNoticeActive && navigation.guessesModalOpen && (
                <GuessesModal />
            )}
            {!embedNoticeActive && navigation.newAppVersionModalOpen && (
                <NewAppVersionModal />
            )}
            {!embedNoticeActive && navigation.socialsModalOpen && (
                <SocialsModal />
            )}
            {!embedNoticeActive && navigation.flicksModalState.isOpen && (
                <FlicksModal />
            )}
            {eventActive && eventName === 'xmas' && (
                <Snowfall
                    changeFrequency={50}
                    speed={[0.1, 0.2]}
                    wind={[-1.0, 1.0]}
                    snowflakeCount={75}
                    radius={[1.0, 2.0]}
                    style={{ zIndex: 1 }}
                />
            )}
            <div
                className={clsx(
                    'w-screen h-full bg-canvas-6',
                    eventActive &&
                        eventName === aprilfools &&
                        'transform rotate-180',
                )}
            >
                {!embedNoticeActive && (
                    <>
                        <AppBar />
                        <AppMenuContainer className="invisible lg:visible" />
                        <AppMenuMobileContainer className="visible lg:invisible" />
                    </>
                )}

                <div className="flex flex-col w-full h-full pt-14">
                    <HomeRoutes />
                </div>
                {!embedNoticeActive &&
                    navigation.policyConsentPanelOpen &&
                    location.pathname !== '/privacy-policy' &&
                    location.pathname !== '/cookie-policy' && <PolicyConsent />}
            </div>
        </>
    );
};

export default HomePage;
