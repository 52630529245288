import React from 'react';
import { useClassyBoy } from '../../../hooks/useClassyBoy';
import LoadingSpinner from '../misc/LoadingSpinner';

type AppIconButtonProps = {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    layoutClassName?: string;
    enabledClassName?: string;
    disabledClassName?: string;
    loadingClassName?: string;
    isLoading?: boolean;
    isEnabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const AppIconButton: React.FC<AppIconButtonProps> = ({
    type,
    children,
    onClick,
    className,
    layoutClassName,
    enabledClassName,
    disabledClassName,
    loadingClassName,
    isLoading,
    isEnabled,
}) => {
    const buttonClasses = useClassyBoy(
        'focus:outline-none rounded-full flex items-center justify-center',
        className,
    );
    const loadingClasses = useClassyBoy('m-2', loadingClassName);

    if (isLoading) {
        return <LoadingSpinner className={loadingClasses.result} />;
    }

    buttonClasses.add(layoutClassName ?? 'p-2 w-8 h-8');

    if (isEnabled === undefined || isEnabled) {
        buttonClasses.add(
            'hover:bg-gray-400 hover:bg-opacity-25 cursor-pointer',
            enabledClassName,
        );
    } else {
        buttonClasses.add('text-primary-3 cursor-pointer', disabledClassName);
    }

    const processClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if ((isEnabled === undefined || isEnabled) && onClick) {
            onClick(e);
        }
    };

    return (
        <button
            type={type}
            onClick={processClick}
            className={buttonClasses.result}
        >
            {children}
        </button>
    );
};

export default AppIconButton;
