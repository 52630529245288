import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import {
    useGetRoundsInRangeQuery,
    useUpdateRoundMutation,
} from '../../app/services/api';
import RoundCalendar from '../../components/admin/roundList/RoundCalendar';
import RoundList from '../../components/admin/roundList/RoundList';
import {
    ApiRequestRoundsInRange,
    ApiRequestUpdateRound,
} from '../../models/api/round';
import { AdminRoundShort, RoundShort } from '../../models/game/round';
import { RoundClaim } from '../../models/pages/roundList';
import {getDateTimeLocalNow} from "../../utils/dateTimeUtils";

const RoundListPage: React.FC = () => {
    const [queryRequest, setQueryRequest] = useState<ApiRequestRoundsInRange>();
    const [roundClaimingActive, setRoundClaimingActive] = useState(false);
    const [claimedRounds, setClaimedRounds] = useState<RoundClaim[]>([]);

    const account = useAppSelector((state) => state.account);

    const queryResponse = useGetRoundsInRangeQuery(queryRequest!, {
        refetchOnMountOrArgChange: true,
        skip: queryRequest === undefined,
    });
    const [updateRound, { isLoading: isUpdatingRound }] =
        useUpdateRoundMutation();

    useEffect(() => {
        const currentDateTime = getDateTimeLocalNow();
        const startDate = currentDateTime.startOf('month').toISODate();
        const endDate = currentDateTime.endOf('month').toISODate();

        setQueryRequest({
            dateStart: startDate,
            dateEnd: endDate,
        });
    }, []);

    const updateDateRange = (difference: number) => {
        if (queryRequest === undefined) return;

        let startDateTime: DateTime = DateTime.fromISO(queryRequest!.dateStart);

        startDateTime =
            difference > 0
                ? startDateTime.plus({ month: 1 })
                : startDateTime.minus({ month: 1 });

        setQueryRequest({
            dateStart: startDateTime.startOf('month').toISODate(),
            dateEnd: startDateTime.endOf('month').toISODate(),
        });
    };

    const setNewRoundData = (round: RoundShort) => {
        //dispatch(setNewRoundData({date: round.date, number: round.number}))
        console.log(round);
    };

    const activateRoundClaiming = () => {
        if (queryResponse.data !== undefined) {
            setClaimedRounds(
                queryResponse.data.content
                    .filter(
                        (e: AdminRoundShort) => e.preparedById !== undefined,
                    )
                    .map(
                        (e: AdminRoundShort): RoundClaim => ({
                            id: e.id,
                            userId: e.preparedById,
                            existing: true,
                            remove: false,
                            update: false,
                        }),
                    ),
            );
            setRoundClaimingActive(true);
        }
    };

    const setRoundClaim = (round: AdminRoundShort) => {
        const claim = claimedRounds.find((e) => e.id === round.id);
        let newClaim: RoundClaim | null = {
            id: round.id,
            userId: round.preparedById ?? account.id,
            existing: false,
            remove: false,
            update: false,
        };

        if (claim !== undefined) {
            if (claim.existing) {
                newClaim.existing = true;
                if (round.preparedById !== account.id) {
                    newClaim.update = true;
                } else {
                    newClaim.remove = !claim.remove;
                }
            } else {
                newClaim = null;
            }
        }

        const newClaimList = claimedRounds.filter((e) => e.id !== round.id);

        setClaimedRounds(
            newClaim !== null ? newClaimList.concat(newClaim) : newClaimList,
        );
    };

    const saveRoundClaims = () => {
        let apiRequests: ApiRequestUpdateRound[] = [];

        for (const claimedRound of claimedRounds) {
            let apiRequest = {
                roundId: claimedRound.id,
                body: { claim: !claimedRound.remove },
            };

            if (
                !claimedRound.existing ||
                claimedRound.remove ||
                claimedRound.update
            ) {
                apiRequests.push(apiRequest);
            }
        }

        const apiCalls = apiRequests.map((e) => updateRound(e));

        try {
            Promise.all(apiCalls).then(() => {
                setRoundClaimingActive(false);
                queryResponse.refetch();
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <RoundList
                className="flex xl:hidden"
                updateDateRange={updateDateRange}
                queryRequest={queryRequest}
                queryResponse={queryResponse}
                setNewRoundData={setNewRoundData}
                roundClaimingActive={roundClaimingActive}
                isUpdatingRound={isUpdatingRound}
                saveRoundClaims={saveRoundClaims}
                setRoundClaimingActive={setRoundClaimingActive}
                activateRoundClaiming={activateRoundClaiming}
                claimedRounds={claimedRounds}
                setRoundClaim={setRoundClaim}
            />
            <RoundCalendar
                className="hidden xl:flex"
                updateDateRange={updateDateRange}
                queryRequest={queryRequest}
                queryResponse={queryResponse}
                setNewRoundData={setNewRoundData}
                roundClaimingActive={roundClaimingActive}
                isUpdatingRound={isUpdatingRound}
                saveRoundClaims={saveRoundClaims}
                setRoundClaimingActive={setRoundClaimingActive}
                activateRoundClaiming={activateRoundClaiming}
                claimedRounds={claimedRounds}
                setRoundClaim={setRoundClaim}
            />
        </>
    );
};
export default RoundListPage;
