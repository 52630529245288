import React from 'react';
import clsx from "clsx";

type AppContainerProps = {
    innerClassName?: string;
    outerClassName?: string;
    maxWidth?: string;
    padding?: boolean;
    fillScreen?: boolean;
    children: React.ReactNode;
};

const AppContainer: React.FC<AppContainerProps> = ({
       innerClassName,
       outerClassName,
       maxWidth = '640px',
       padding = true,
       children,
       fillScreen = false,
   }) => {
    return (
        <div className={clsx('flex justify-center', outerClassName, fillScreen && 'h-screen')}>
            <div
                style={{
                    maxWidth: maxWidth,
                    //height: fillScreen ? 'calc(100vh - 3.5rem)' : undefined,
                }}
                className={`${padding && 'px-4'} ${innerClassName}`}
            >
                {children}
            </div>
        </div>
    );
};

export default AppContainer;
