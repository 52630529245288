import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { getPersistConfig } from 'redux-deep-persist';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rtkQueryErrorLogger } from './middleware/ApiErrorToast';
import rootReducer, { RootState } from './rootReducer';
import { api } from './services/api';

export const history = createBrowserHistory();

const migrations = {
    0: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            settings: {
                ...state.settings,
                shareLink: true,
                shareStats: true,
            },
        };
    },
    1: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            stats: {
                ...state.stats,
                // @ts-ignore
                firstGuessStreak: 0,
            },
        };
    },
    2: (state: RootState): RootState => {
        // add share settings, default to true

        return {
            ...state,
            settings: {
                ...state.settings,
                // @ts-ignore
                appVersion: 0,
            },
        };
    },
    3: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            stats: {
                ...state.stats,
                playStreakBest: 0,
                winStreakBest: 0,
                loseStreakBest: 0,
                firstGuessStreakBest: 0,
            },
            settings: {
                ...state.settings,
                shareStatsBest: true,
                shareStatsShort: false,
            },
        };
    },
    4: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            settings: {
                ...state.settings,
                shareStatsNegative: true,
            },
        };
    },
    5: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            settings: {
                ...state.settings,
                shareOverrideCopyToClipboard: false,
            },
        };
    },
    6: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            stats: {
                ...state.stats,
                previousRounds: state.stats.previousRounds.map((e) => ({
                    ...e,
                    round: {
                        ...e.round,
                        flick: {
                            ...e.round.flick,
                            legacyId: e.round.flick.id,
                            id: '00000000-0000-0000-0000-000000000000',
                        },
                    },
                })),
            },
        };
    },
    7: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            // @ts-ignore
            account: {
                role: 'anonymous',
            },
        };
    },
    8: (state: RootState): RootState => {
        // add share settings, default to true
        return {
            ...state,
            // @ts-ignore
            settings: {
                ...Object.entries(state.settings)
                    .filter(([key]) => key !== 'appVersion')
                    .reduce((obj, [key, value]) => {
                        // @ts-ignore
                        obj[key] = value;
                        return obj;
                    }, {}),
                appVersionLastUpdated: '0',
            },
        };
    },
    9: (state: RootState): RootState => {
        return {
            ...state,
            account: {
                ...state.account,
                sessionId: undefined,
            },
        };
    },
    /*10: (state: RootState): RootState => {
        return {
            ...state,
            stats: {
                ...state.stats,
                archiveRounds: JSON.parse(JSON.stringify(state.stats.previousRounds)),
            },
        };
    },*/
};

const persistConfig = getPersistConfig({
    key: 'root',
    version: 9,
    storage: storage,
    whitelist: [
        'game.round',
        'game.newRound',
        'game.guesses',
        'game.guessIndex',
        'game.gameOver',
        'game.win',
        'stats',
        'settings',
        'account.refreshToken',
        'account.role',
        'account.id',
        'account.sessionId',
    ],
    // @ts-ignore
    migrate: createMigrate(migrations, { debug: false }),
    rootReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([api.middleware, rtkQueryErrorLogger]),
    devTools: true,
});

export type AppDispatch = typeof store.dispatch;
