import React from 'react';
import { useClassyBoy } from '../../../hooks/useClassyBoy';

type AppCheckboxProps = {
    className?: string;
    label?: string;
    value: boolean;
    setValue: (value: boolean) => void;
    enabled?: boolean;
    mode?: "primary" | "alt";
};

const AppCheckbox: React.FC<AppCheckboxProps> = ({
    className,
    label,
    value,
    setValue,
    enabled = true,
    mode = "primary",
}) => {
    const inputClasses = useClassyBoy(
        className,
        'w-6 h-6 rounded-full form-tick appearance-none bg-black border cursor-pointer',
        mode === "primary" ? 'border-primary-1' : 'border-secondary-1',
        'checked:border-0 focus:outline-none active:bg-black',
        'checked:focus:ring-0',
        mode === "primary" ? 'checked:bg-primary-1 checked:text-primary-1' : 'checked:bg-secondary-1 checked:text-secondary-1',
        'disabled:border-canvas-2 disabled:checked:bg-canvas-2 disabled:checked:text-canvas-2',
    );

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (!enabled) return;
        setValue(e.currentTarget.checked);
    };

    return (
        <div className="flex justify-center">
            <div className="form-check form-switch">
                <input
                    className={inputClasses.result}
                    type="checkbox"
                    onChange={handleChange}
                    defaultChecked={value}
                    disabled={!enabled}
                />
                {label && (
                    <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="flexSwitchCheckChecked"
                    >
                        Checked
                        {label}
                    </label>
                )}
            </div>
        </div>
    );
};

export default AppCheckbox;
