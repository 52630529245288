import React from 'react';
import { animateColors } from '../../../constants/components/tailwindClassCollections';
import { useClassyBoy } from '../../../hooks/useClassyBoy';
import LoadingSpinner from '../misc/LoadingSpinner';

type AppButtonProps = {
    className?: string;
    sizeClassName?: string;
    isEnabled?: boolean;
    isLoading?: boolean;
    mode?:
        | 'primary'
        | 'secondary'
        | 'alt'
        | 'alt-secondary'
        | 'errorPrimary'
        | 'errorSecondary'
        | 'successPrimary'
        | 'successSecondary';
    onClick?: Function;
    children?: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const primaryModes = ['primary', 'errorPrimary', 'successPrimary'];

const AppButton: React.FC<AppButtonProps> = ({
    type,
    value,
    children,
    className,
    sizeClassName,
    isEnabled,
    isLoading,
    mode = 'primary',
    onClick,
}) => {
    const baseClasses = useClassyBoy(
        'rounded cursor-pointer text-md font-semibold tracking-widest',
        animateColors(),
        className,
    );

    const modeClasses = useClassyBoy();
    const combinedClasses = useClassyBoy();

    baseClasses.add(sizeClassName ?? 'w-24 h-8');

    const style = {
        disabledPrimary: 'bg-disabled-1 text-white pointer-events-none',
        disabledSecondary:
            'border-disabled-1 text-disabled-1 pointer-events-none',
        primary:
            'bg-primary-1 text-white hover:bg-accent-1 hover:text-primary-1',
        secondary:
            'border-2 border-primary-1 bg-transparent text-primary-1 hover:bg-primary-1 hover:border-transparent hover:text-white ',
        alt: 'bg-secondary-1 text-white hover:bg-accent-1 hover:text-secondary-1',
        'alt-secondary':
            'border-2 border-secondary-1 bg-transparent text-secondary-1 hover:bg-secondary-1 hover:border-transparent hover:text-white ',
        errorPrimary:
            'bg-error-1 text-white hover:bg-error-2 hover:text-error-1',
        errorSecondary:
            'border-2 border-error-1 bg-transparent text-error-1 hover:bg-error-1 hover:border-transparent hover:text-white',
        successPrimary:
            'bg-success-1 text-white hover:bg-success-2 hover:text-success-1',
        successSecondary:
            'border-2 border-success-1 bg-transparent text-success-1 hover:bg-success-1 hover:border-transparent hover:text-white ',
        loading: 'pointer-events-none',
    };

    if (isEnabled !== undefined && !isEnabled) {
        if (primaryModes.includes(mode)) {
            modeClasses.set(style.disabledPrimary);
        } else {
            modeClasses.set(style.disabledSecondary);
        }
    } else if (isLoading !== undefined && isLoading) {
        modeClasses.set(style.loading);
    } else {
        modeClasses.set(style[mode]);
    }

    combinedClasses.set(baseClasses.result, modeClasses.result);

    return (
        <button
            type={type}
            className={combinedClasses.result}
            onClick={onClick}
        >
            {isLoading ? <LoadingSpinner /> : children ?? value}
        </button>
    );
};

export default AppButton;
