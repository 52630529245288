import clsx from 'clsx';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useClassyBoy } from '../../hooks/useClassyBoy';
import { ArchiveRoundShort } from '../../models/game/round';
import ArchiveListProps from '../../models/props/ArchiveListProps';
import AppIconButton from '../common/inputs/AppIconButton';
import AppCard from '../common/layout/AppCard';
import AppContainer from '../common/layout/AppContainer';
import LoadingSpinner from '../common/misc/LoadingSpinner';
import { LeftArrowIcon, RightArrowIcon } from '../common/misc/Svgs';
import ArchiveItem from './ArchiveItem';

const ArchiveList: React.FC<ArchiveListProps> = ({
    className,
    updatePage,
    queryResponse,
}) => {
    const classes = useClassyBoy('max-h-full overflow-y-hidden', className);
    const queryLoading = queryResponse.isFetching || queryResponse.isLoading;
    const previousPageEnabled =
        !queryLoading && queryResponse.data?.content.hasPreviousPage;
    const nextPageEnabled =
        !queryLoading && queryResponse.data?.content.hasNextPage;

    const rangeStart =
        (queryResponse.data?.content.pageNumber - 1) *
            queryResponse.data?.content.pageSize +
        1;
    const rangeEnd = Math.min(
        queryResponse.data?.content.pageNumber *
            queryResponse.data?.content.pageSize,
        queryResponse.data?.content.totalCount,
    );

    return (
        <AppContainer
            outerClassName={classes.result}
            innerClassName="w-full items-center pt-4 text-accent-1 flex flex-col"
            fillScreen={true}
        >
            <div className="relative w-full flex justify-center h-12">
                <h2 className="absolute top-0 flex-grow text-2xl text-center font-semibold mb-4 uppercase">
                    Archive
                </h2>
            </div>

            <AppCard
                className="w-full h-full max-h-full overflow-hidden pb-16"
                layoutClassName="py-4 px-2"
            >
                <div className="flex w-full items-center mb-4 px-4">
                    <AppIconButton
                        onClick={() => updatePage(-1)}
                        isEnabled={previousPageEnabled}
                    >
                        <LeftArrowIcon
                            className={clsx(
                                'w-12 h-12',
                                previousPageEnabled
                                    ? 'text-secondary-1'
                                    : 'text-canvas-3',
                            )}
                        />
                    </AppIconButton>
                    <h3 className="text-lg text-center uppercase flex-grow">
                        {queryLoading
                            ? 'Loading...'
                            : `${rangeStart} - ${rangeEnd} of ${queryResponse.data?.content.totalCount}`}
                    </h3>
                    <AppIconButton
                        onClick={() => updatePage(1)}
                        isEnabled={nextPageEnabled}
                    >
                        <RightArrowIcon
                            className={clsx(
                                'w-12 h-12',
                                nextPageEnabled
                                    ? 'text-secondary-1'
                                    : 'text-canvas-3',
                            )}
                        />
                    </AppIconButton>
                </div>
                {queryLoading && (
                    <div className="w-full flex justify-center">
                        <LoadingSpinner className="w-12 h-12 mt-4" />
                    </div>
                )}
                {queryResponse.isSuccess && !queryResponse.isFetching && (
                    <Scrollbars>
                        <div className="mx-3">
                            {queryResponse.data?.content.items.map(
                                (round: ArchiveRoundShort, index: number) => {
                                    return (
                                        <ArchiveItem
                                            key={index}
                                            className="mb-4"
                                            index={index}
                                            round={round}
                                        />
                                    );
                                },
                            )}
                        </div>
                    </Scrollbars>
                )}
            </AppCard>
        </AppContainer>
    );
};

export default ArchiveList;
