import clsx from 'clsx';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { animateAll } from '../../../../constants/components/tailwindClassCollections';
import { setMenuIsOpen } from '../../../../store/navigationSlice';
import AppMenu from '../AppMenu';

type AppMenuMobileContainerProps = {
    className?: string;
};

const AppMenuMobileContainer: React.FC<AppMenuMobileContainerProps> = ({
    className,
}) => {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.navigation.menuOpen);

    const closeMenu = () => {
        dispatch(setMenuIsOpen(false));
    };
    return (
        <div
            className={clsx(
                className,
                'fixed top-14 h-screen w-[10rem] z-40',
                isOpen ? 'left-0' : '-left-[10rem]',
                animateAll(500),
            )}
        >
            {isOpen && (
                <div
                    className="absolute top-0 h-screen w-screen"
                    onClick={closeMenu}
                />
            )}
            <AppMenu className="absolute z-50" />
        </div>
    );
};

export default AppMenuMobileContainer;
