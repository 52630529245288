import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { animateColors } from '../../constants/components/tailwindClassCollections';
import { setSocialsModalIsOpen } from '../../store/navigationSlice';
import AppButton from '../common/inputs/AppButton';
import AppDialog from '../common/layout/AppDialog';
import { DiscordIcon, TwitterIcon } from '../common/misc/Svgs';

const SocialsModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(setSocialsModalIsOpen(false));
    };

    const modalButtons = () => (
        <AppButton onClick={closeModal} className="uppercase">
            CLOSE
        </AppButton>
    );

    return (
        <AppDialog close={closeModal} buttons={modalButtons()} title="Socials">
            <div className="max-w-[20rem] self-center w-full">
                <a
                    href="https://ko-fi.com/flickle"
                    target="_blank"
                    className={`${animateColors()} flex w-full bg-kofiRed-1 hover:opacity-90 items-center rounded-xl py-2 px-4 mb-4`}
                    rel="noreferrer"
                >
                    <img
                        src="kofi-logo.png"
                        className="h-8 w-8"
                        alt="ko-fi logo"
                    />
                    <p className="text-center flex-grow mx-2 font-bold text-xl">
                        Ko-fi
                    </p>
                </a>

                <a
                    href="https://discord.gg/qKN3Y2HZdf"
                    target="_blank"
                    className={`${animateColors()} flex w-full bg-discordPurple-1 hover:opacity-90 items-center rounded-xl py-2 px-4 mb-4`}
                    rel="noreferrer"
                >
                    <DiscordIcon className="h-8 w-8" />
                    <p className="text-center flex-grow mx-2 font-bold text-xl">
                        Discord
                    </p>
                </a>

                <a
                    href="https://twitter.com/Flickle_App"
                    target="_blank"
                    className={`${animateColors()} flex w-full bg-twitterBlue-1 hover:opacity-90 items-center rounded-xl py-2 px-4`}
                    rel="noreferrer"
                >
                    <TwitterIcon className="h-8 w-8" />
                    <p className="text-center flex-grow mx-2 font-bold text-xl">
                        Twitter
                    </p>
                </a>
            </div>
        </AppDialog>
    );
};
export default SocialsModal;
