import clsx from 'clsx';
import { DateTime, Duration } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { weekdayColOffsets } from '../../../constants/misc/calendarConsts';
import { getRgbColorFromUserId } from '../../../constants/misc/themeConstants';
import { AdminRoundShort, RoundShort } from '../../../models/game/round';
import { ClaimStatus } from '../../../models/pages/roundList';
import {getDateTimeLocalNow, getDateTimeUtcNow, getDiffNow, getDurationFormat} from "../../../utils/dateTimeUtils";

export type RoundCalendarItemProps = {
    className?: string;
    index: number;
    round: AdminRoundShort;
    setNewRoundData: (round: RoundShort) => void;
    routerLinkEnabled?: boolean;
    nonRouterLinkOnClick?: () => void;
    claimStatus: ClaimStatus;
};

const RoundCalendarItem: React.FC<RoundCalendarItemProps> = ({
    className,
    index,
    round,
    setNewRoundData,
    routerLinkEnabled = true,
    nonRouterLinkOnClick,
    claimStatus,
}) => {
    const hasFlick = round.flickId !== undefined;
    const roundDate = DateTime.fromISO(round.date, {
        zone: 'utc',
    });
    const liveAt = DateTime.fromISO(round.liveAt, {
        zone: 'utc',
    });
    const pastAt = DateTime.fromISO(round.pastAt, {
        zone: 'utc',
    });
    const roundInFuture = getDateTimeUtcNow().diff(liveAt).as('milliseconds') < 0;
    const roundInPast = getDateTimeUtcNow().diff(pastAt).as('milliseconds') > 0;

    const roundActive = !roundInFuture && !roundInPast;

    const roundIsToday =
        roundDate.diff(getDateTimeLocalNow().startOf('day'), 'days').days === 0;

    const durationUntilLive = getDiffNow(liveAt);
    const durationUntilPast = getDiffNow(pastAt);

    const untilLiveFormat = getDurationFormat(durationUntilLive);
    const untilPastFormat = getDurationFormat(durationUntilPast);

    const getClaimedUserColorStyles = (id: string | undefined) =>
        id !== undefined ? { color: getRgbColorFromUserId(id) } : {};

    const getCalendarItemContents = () => {
        return (
            <>
                <div
                    className={clsx(
                        'flex w-full border-b leading-none p-1',
                        !round.ready &&
                            round.flickId === undefined &&
                            'border-canvas-5',
                    )}
                >
                    <span className="block">
                        {roundDate.toFormat('dd/MM')}
                    </span>
                    {roundInPast && (
                        <span
                            className={clsx(
                                'flex-grow text-center text-sm',
                                round.ready
                                    ? 'text-accent-2'
                                    : 'text-error-1 animate-pulse',
                            )}
                        >
                            <span className="block">Past</span>
                        </span>
                    )}
                    {roundActive && (
                        <span
                            className={clsx(
                                'flex-grow text-center text-sm',
                                round.ready
                                    ? 'text-primary-1'
                                    : 'text-error-1 animate-pulse',
                            )}
                        >
                            <span className="block">Ends in</span>
                            <span className="block">
                                {durationUntilPast.toFormat(untilPastFormat)}
                            </span>
                        </span>
                    )}
                    {roundInFuture && (
                        <div
                            className={clsx(
                                'flex-grow text-center text-sm',
                                round.ready
                                    ? 'text-accent-1'
                                    : durationUntilLive.as('days') > 3
                                    ? 'text-accent-1'
                                    : durationUntilLive.as('hours') > 24
                                    ? 'text-warning-1'
                                    : 'text-error-1',
                            )}
                        >
                            <span className="block">Starts in</span>
                            <span className="block">
                                {durationUntilLive.toFormat(untilLiveFormat)}
                            </span>
                        </div>
                    )}
                    <span className="block text-right">{`#${round.number}`}</span>
                </div>
                <div
                    className={`w-full flex-grow leading-none flex items-center`}
                >
                    <span
                        className={`block w-full ${
                            !hasFlick && 'text-canvas-3'
                        } text-center py-4 px-1`}
                    >
                        {round.flickTitle ?? 'Not Set'}
                    </span>
                </div>
                {claimStatus !== 'none' ? (
                    <span
                        className={clsx(
                            'block w-full text-center text-sm pb-1 px-1 text-canvas-6 font-bold uppercase',

                            claimStatus === 'claimed'
                                ? 'bg-warning-1'
                                : claimStatus === 'remove'
                                ? 'bg-error-1 text-canvas-6'
                                : 'bg-primary-1 text-canvas-6',
                        )}
                    >
                        {claimStatus === 'claimed'
                            ? 'claimed'
                            : claimStatus === 'remove'
                            ? 'unclaim'
                            : 'claim'}
                    </span>
                ) : (
                    <div
                        className={clsx(
                            'w-full text-center text-sm pb-1 px-1 border-t',
                            round.isReplay
                                ? 'text-secondary-1'
                                : round.preparedById === undefined
                                    ? 'text-accent-2'
                                    : 'font-bold',
                        )}
                    >
                        <span
                            style={round.isReplay ? undefined : getClaimedUserColorStyles(
                                round.preparedById,
                            )}
                        >
                            {round.isReplay
                                ?  `Replay of #${round.replayRoundNumber}`
                                : round.preparedById
                                    ? round.preparedByNickname ?? 'Unknown'
                                    : 'Not Taken'}
                        </span>
                    </div>
                )}
            </>
        );
    };

    const containerClasses = () =>
        clsx(
            className,
            hasFlick
                ? round.ready
                    ? roundIsToday
                        ? 'text-primary-1'
                        : 'text-accent-1'
                    : 'text-warning-1'
                : 'bg-canvas-6',
            hasFlick
                ? round.ready
                    ? round.isReplay
                        ? 'border border-secondary-1 text-secondary-1'
                        : 'border border-accent-1 text-accent-1'
                    : 'border border-warning-1 text-warning-1'
                : '',
            index === 0 && weekdayColOffsets[roundDate.weekday - 1],
            'w-full flex flex-col items-center min-h-[5rem]',
        );

    const containerRouterLink = (
        children: React.ReactElement,
    ): React.ReactElement => {
        return (
            <Link
                to={`/rounds/${round.id}`}
                onClick={hasFlick ? undefined : () => setNewRoundData(round)}
                className={containerClasses()}
            >
                {children}
            </Link>
        );
    };

    const containerDiv = (children: React.ReactElement): React.ReactElement => {
        return (
            <div onClick={nonRouterLinkOnClick} className={containerClasses()}>
                {children}
            </div>
        );
    };

    return routerLinkEnabled
        ? containerRouterLink(getCalendarItemContents())
        : containerDiv(getCalendarItemContents());
};

export default RoundCalendarItem;
