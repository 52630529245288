import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type EventsState = {
    eventActive: boolean;
};

const eventsInitialState: EventsState = {
    eventActive: false,
};

const events = createSlice({
    name: 'events',
    initialState: eventsInitialState,
    reducers: {
        setEventActive: (
            state: EventsState,
            action: PayloadAction<boolean>,
        ) => {
            state.eventActive = action.payload;
        },
    },
});

export const { setEventActive } = events.actions;

export default events.reducer;
