import AppDialog from '../common/layout/AppDialog';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    setGuessesModalIsOpen,
} from '../../store/navigationSlice';
import AppButton from '../common/inputs/AppButton';
import {
    getGuess,
    getGuessBorderColor,
    getGuessTextColor,
} from '../../utils/guessesUtils';

const GuessesModal: React.FC = () => {
    const dispatch = useAppDispatch();

    const guesses = useAppSelector((state) => state.game.guesses);

    const closeModal = () => {
        dispatch(setGuessesModalIsOpen(false));
    };

    const modalButtons = () => (
        <AppButton onClick={closeModal}>CLOSE</AppButton>
    );

    return (
        <AppDialog close={closeModal} buttons={modalButtons()} title="Guesses">
            <div>
                {Array(6)
                    .fill(null)
                    .map((item, index) => {
                        const guess = getGuess(guesses, index);
                        return (
                            <div
                                key={index}
                                className={`${getGuessBorderColor(
                                    guess,
                                    index,
                                )} flex items-center w-full min-h-[2.1rem] border mb-2 last:mb-0 text-accent-1 px-2`}
                            >
                                {guess && (
                                    <span
                                        className={`py-1 ${getGuessTextColor(
                                            guess,
                                        )}`}
                                    >
                                        {guess.isSkip
                                            ? 'Skip'
                                            : guess.flick!.title}
                                    </span>
                                )}
                            </div>
                        );
                    })}
            </div>
        </AppDialog>
    );
};
export default GuessesModal;
