import React from 'react';
import { animateColors } from '../../../constants/components/tailwindClassCollections';
import { useClassyBoy } from '../../../hooks/useClassyBoy';

type AppFormSubmitButtonProps = {
    className?: string;
    sizeClassName?: string;
    isEnabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const AppFormSubmitButton: React.FC<AppFormSubmitButtonProps> = ({
    type = 'submit',
    value = 'submit',
    className,
    sizeClassName,
    isEnabled,
    onClick,
}) => {
    const baseClasses = useClassyBoy(
        'rounded cursor-pointer text-md font-semibold tracking-widest uppercase',
        animateColors(),
        className,
    );

    const modeClasses = useClassyBoy();
    const combinedClasses = useClassyBoy();

    baseClasses.add(sizeClassName ?? 'w-24 h-8');

    if (isEnabled !== undefined && !isEnabled) {
        modeClasses.set('bg-disabled-1 text-white pointer-events-none');
    } else {
        modeClasses.set(
            'bg-primary-1 text-white hover:bg-accent-1 hover:text-primary-1 duration',
        );
    }

    combinedClasses.set(baseClasses.result, modeClasses.result);

    return (
        <input type={type} value={value} className={combinedClasses.result} />
    );
};

export default AppFormSubmitButton;
