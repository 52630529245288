import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    eventName,
    spookleTitleChangeIntervalMs,
    spookleTitleChangeProbability,
} from '../../../config';
import {
    aprilfools,
    spookle,
    xmas,
} from '../../../constants/misc/eventConstants';
import {
    setMenuIsOpen,
    setRulesModalIsOpen,
    setSocialsModalIsOpen,
} from '../../../store/navigationSlice';
import { randomIntBetween } from '../../../utils/numberUtils';
import AppIconButton from '../inputs/AppIconButton';
import { ChevronLeftIcon, HeartIcon, InfoIcon, MenuIcon } from '../misc/Svgs';
import AppContainer from './AppContainer';

const AppBar: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const isArchive = location.pathname.includes('/archive');
    const [title, setTitle] = useState<string>('');
    const [xmasTitleBlinkCounter, setXmasTitleBlinkCounter] =
        useState<number>(0);
    const eventActive = useAppSelector((state) => state.events.eventActive);
    const menuOpen = useAppSelector((state) => state.navigation.menuOpen);

    const defaultBanner = () => {
        return (
            <div className="fixed w-screen h-14 z-50">
                <div className="w-screen h-7 banner-stripes-default-1 border-b-2 border-canvas-5" />
                <div className="w-screen h-7 banner-stripes-default-2" />
            </div>
        );
    };

    const spookleBanner = () => {
        return (
            <div className="absolute w-screen h-14">
                <div className="w-screen h-7 banner-stripes-spookle-1 border-b-2 border-canvas-5" />
                <div className="w-screen h-7 banner-stripes-spookle-2" />
            </div>
        );
    };

    const xmasBanner = () => {
        return (
            <div className="absolute w-screen h-14">
                <div className="absolute top-0 w-screen h-14 banner-stripes-xmas-1" />
                <div className="absolute top-0 w-screen h-14 xmas-banner-shadow" />
            </div>
        );
    };

    const defaultTitle = () => {
        return (
            <h1 className="text-3xl leading-none tracking-widest font-bold text-accent-1 uppercase italic text-center bg-canvas-5 px-4 py-1 border border-accent-1 relative drop-shadow">
                Flickle
            </h1>
        );
    };

    const spookleTitle = () => {
        const titleChangeActive = title.toLowerCase() !== spookle;
        return (
            <h1
                className={`text-3xl leading-none tracking-widest font-bold uppercase italic text-center bg-canvas-5 px-4 py-1 border relative drop-shadow 
                ${
                    titleChangeActive
                        ? 'text-red-500 border-red-500'
                        : 'text-accent-1 border-spookle-orange'
                } 
                ${titleChangeActive && 'animate-twitch'}`}
                style={{ minWidth: '12rem', maxWidth: '13rem' }}
            >
                {title}
            </h1>
        );
    };

    const spookleTitleChange = () => {
        if (Math.random() < (spookleTitleChangeProbability ?? 1)) {
            const options = [
                'Help me',
                'Boo',
                'Run',
                'Scream',
                'Beware',
                'R.I.P.',
                '⛧⛧⛧⛧⛧',
                'S̷̤̖͂P̶̨͒Ö̷̻̙́O̴͚͉̾͂K̴͎̪͑͒L̷̝̬̕E̵̛̤̣͘',
            ];
            const selectedOptionIndex = randomIntBetween(options.length - 1, 0);
            setTitle(options[selectedOptionIndex]);
            setTimeout(() => {
                setTitle('Spookle');
            }, 750);
        }
    };

    const colorClasses = [
        'xmas-glow-red',
        'xmas-glow-green',
        'xmas-glow-gold',
        'xmas-glow-blue',
    ];

    const getXmasTitleColourClass = (index: number) =>
        colorClasses[Math.abs(index) % colorClasses.length];

    const xmasTitle = () => {
        return (
            <h1 className="text-3xl leading-none tracking-widest font-bold text-accent-1 uppercase italic text-center bg-canvas-6 px-4 py-1 border border-accent-1 relative drop-shadow">
                <>
                    {Array.from('Flickle').map((item, index) => {
                        return (
                            <span
                                key={index}
                                className={clsx(
                                    'outline-1 text-transparent xmas-glow',
                                    getXmasTitleColourClass(
                                        index - xmasTitleBlinkCounter,
                                    ),
                                )}
                            >
                                {item}
                            </span>
                        );
                    })}
                </>
            </h1>
        );
    };

    const aprilFoolsTitle = () => {
        return (
            <h1 className="text-3xl leading-none tracking-widest font-bold text-accent-1 uppercase italic text-center bg-canvas-5 px-4 py-1 border border-accent-1 relative drop-shadow">
                Flipple
            </h1>
        );
    };

    useEffect(() => {
        let titleUpdateIntervalId: any | undefined = undefined;
        let titleBlinkIntervalId: any | undefined = undefined;
        if (eventActive) {
            if (eventName === spookle) {
                setTitle('Spookle');
                titleUpdateIntervalId =
                    !titleUpdateIntervalId &&
                    setInterval(
                        spookleTitleChange,
                        Number(spookleTitleChangeIntervalMs ?? 5000),
                    );
            }

            if (eventName === xmas) {
                titleBlinkIntervalId =
                    !titleBlinkIntervalId &&
                    setInterval(() => {
                        setXmasTitleBlinkCounter(xmasTitleBlinkCounter + 1);
                    }, Number(800)); //xmasTitleBlinkIntervalMs ?? 500
            }
        }

        return () => {
            if (titleUpdateIntervalId) {
                clearInterval(titleUpdateIntervalId);
            }

            if (titleBlinkIntervalId) {
                clearInterval(titleBlinkIntervalId);
            }
        };
    }, [eventActive, xmasTitleBlinkCounter]);

    const getBanner = () => {
        if (eventActive) {
            switch (eventName) {
                case spookle:
                    return spookleBanner();
                case xmas:
                    return xmasBanner();
                default:
                    return defaultBanner();
            }
        }

        return defaultBanner();
    };

    const getTitle = () => {
        if (eventActive) {
            switch (eventName) {
                case spookle:
                    return spookleTitle();
                case xmas:
                    return xmasTitle();
                case aprilfools:
                    return aprilFoolsTitle();
                default:
                    return defaultTitle();
            }
        }

        return defaultTitle();
    };

    return (
        <>
            {getBanner()}
            <AppContainer
                maxWidth="1024px"
                innerClassName="w-full h-full relative justify-between flex"
                outerClassName={clsx(
                    'flex-col w-screen items-center h-14 fixed z-50 border-b',
                    eventActive && eventName === spookle
                        ? 'border-spookle-orange'
                        : 'border-accent-1',
                )}
                padding={false}
            >
                <div className="absolute w-full h-14 flex justify-center items-center">
                    <Link to="/">{getTitle()}</Link>
                </div>

                <div className="flex flex-row">
                    <div
                        className={clsx(
                            'self-center flex items-center justify-end rounded-full ml-2 drop-shadow',
                            eventActive &&
                                eventName === xmas &&
                                'bg-canvas-6 p-0.5',
                        )}
                    >
                        <AppIconButton
                            layoutClassName="w-8 h-8"
                            onClick={() => {
                                dispatch(setMenuIsOpen(!menuOpen));
                            }}
                        >
                            <div
                                className={clsx(
                                    'rounded-full bg-canvas-6 w-8 h-8 border-2 flex items-center justify-center',
                                    isArchive
                                        ? 'border-secondary-1'
                                        : 'border-primary-1',
                                )}
                            >
                                {menuOpen ? (
                                    <ChevronLeftIcon
                                        className={clsx(
                                            'w-5 h-5',
                                            isArchive
                                                ? 'text-secondary-1'
                                                : 'text-primary-1',
                                        )}
                                    />
                                ) : (
                                    <MenuIcon
                                        className={clsx(
                                            'w-5 h-5',
                                            isArchive
                                                ? 'text-secondary-1'
                                                : 'text-primary-1',
                                        )}
                                    />
                                )}
                            </div>
                        </AppIconButton>
                    </div>
                    <div
                        className={clsx(
                            'self-center flex items-center justify-end rounded-full ml-2 drop-shadow',
                            eventActive &&
                                eventName === xmas &&
                                'bg-canvas-6 p-0.5',
                        )}
                    >
                        <AppIconButton
                            layoutClassName="w-8 h-8"
                            onClick={() => {
                                dispatch(setSocialsModalIsOpen(true));
                            }}
                        >
                            <div className="rounded-full bg-canvas-6 w-8 h-8 border-2 border-error-1 flex items-center justify-center">
                                <HeartIcon className="w-5 h-5 text-error-1" />
                            </div>
                        </AppIconButton>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div
                        className={clsx(
                            'self-center flex items-center justify-end rounded-full mr-2 drop-shadow',
                            eventActive &&
                                eventName === xmas &&
                                'bg-canvas-6 p-0.5',
                        )}
                    >
                        <AppIconButton
                            layoutClassName="w-8 h-8"
                            onClick={() => {
                                dispatch(setRulesModalIsOpen(true));
                            }}
                        >
                            <div
                                className={clsx(
                                    'rounded-full bg-canvas-6 w-8 h-8 border-2 flex items-center justify-center',
                                    isArchive
                                        ? 'border-secondary-1'
                                        : 'border-primary-1',
                                )}
                            >
                                <InfoIcon
                                    className={clsx(
                                        'w-6 h-6',
                                        isArchive
                                            ? 'text-secondary-1'
                                            : 'text-primary-1',
                                    )}
                                />
                            </div>
                        </AppIconButton>
                    </div>
                </div>
            </AppContainer>
        </>
    );
};

export default AppBar;
