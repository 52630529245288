import clsx from 'clsx';
import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { animateAll } from '../../../../constants/components/tailwindClassCollections';
import AppMenu from '../AppMenu';

type AppMenuContainerProps = {
    className?: string;
};

const AppMenuContainer: React.FC<AppMenuContainerProps> = ({ className }) => {
    const isOpen = useAppSelector((state) => state.navigation.menuOpen);

    return (
        <div
            className={clsx(
                className,
                'fixed h-full top-14 w-[10rem] z-40',
                isOpen ? 'left-0' : '-left-[10rem]',
                animateAll(500),
            )}
        >
            <AppMenu className="relative" />
        </div>
    );
};

export default AppMenuContainer;
