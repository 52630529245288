const getBooleanValue = (envVar: string | undefined): boolean =>
    envVar !== undefined && envVar === 'true';
const getNumberValue = (envVar: string | undefined): number => {
    if (envVar === undefined) {
        return 0;
    }
    const parsed = parseInt(envVar);
    if (isNaN(parsed)) {
        return 0;
    }
    return parsed;
};

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const googleAnalyticsId = process.env.REACT_APP_GA_ID;
export const googleTagManagerId = process.env.REACT_APP_GTM_ID;

// Ads
export const adPlaceholdersEnabled: boolean = getBooleanValue(
    process.env.REACT_APP_ADS_USE_PLACEHOLDERS,
);
export const testAds: boolean = getBooleanValue(process.env.REACT_APP_ADS_TEST);

// Events
export const eventName = process.env.REACT_APP_EVENT_NAME;
export const eventLive: boolean = getBooleanValue(
    process.env.REACT_APP_EVENT_LIVE,
);
export const eventStartDate = process.env.REACT_APP_EVENT_START_DATE;
export const eventEndDate = process.env.REACT_APP_EVENT_END_DATE;

// Spookle
export const spookleTitleChangeProbability =
    process.env.REACT_APP_SPOOKLE_TITLE_CHANGE_PROBABILITY;
export const spookleTitleChangeIntervalMs =
    process.env.REACT_APP_SPOOKLE_TITLE_CHANGE_INTERVAL_MS;

// Xmas
export const xmasTitleBlinkIntervalMs =
    process.env.REACT_APP_XMAS_TITLE_BLINK_INTERVAL_MS;
