import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import AppContainer from '../common/layout/AppContainer';
import {getDateTimeLocalNow} from "../../utils/dateTimeUtils";

const CountdownTimer: React.FC = () => {
    const [countDownInterval, setCountDownInterval] = useState<any>();
    const [countDownValue, setCountDownValue] = useState<number>(0);
    const [countDownLabel, setCountDownLabel] = useState<string>('');

    const game = useAppSelector(
        (state) => state.game,
    );

    const calculateCountDownValue = () => {
        const currentDateTime = getDateTimeLocalNow();
        const nextStartDateTime = currentDateTime
            .plus({ day: 1 })
            .startOf('day');
        const timeRemaining = nextStartDateTime.diff(currentDateTime);
        setCountDownValue(timeRemaining.toMillis())
        setCountDownLabel(
            timeRemaining.toMillis() > 0
                ? timeRemaining.toFormat('hh:mm:ss')
                : '00:00:00',
        );
    };

    useEffect(() => {
        calculateCountDownValue();
        setCountDownInterval(setInterval(calculateCountDownValue, 1000));
        return () => {
            if (countDownInterval) {
                clearInterval(countDownInterval);
            }
        };
    }, []);

    return (
        <AppContainer
            outerClassName="h-full"
            innerClassName="flex flex-col w-full h-full justify-center items-center pb-4"
        >
            <div className="flex flex-col justify-center items-center w-2/3 max-w-64 border-t border-accent-1">
                <p
                    className="text-xs text-accent-1 py-2"
                    onClick={() =>
                        (window.location.href = 'https://ko-fi.com/flickle')
                    }
                >
                    <span className="text-red-500">❤ </span>
                    <span>Flickle? </span>
                    <a
                        href="https://ko-fi.com/flickle"
                        className="text-primary-1"
                    >
                        Buy us a coffee!
                    </a>
                </p>

                <p className="text-accent-2 text-center">Next Flickle:</p>
                <p className="text-accent-1 text-4xl tracking-widest">
                    {game.newRoundPending || countDownValue < 0 ? "00:00:00" : countDownLabel}
                </p>
            </div>
        </AppContainer>
    );
};

export default CountdownTimer;
