import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import ReactPlayer from 'react-player/file';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    useCreateClipMutation,
    useDeleteClipMutation,
    useGetRoundQuery,
    useGetRoundStatsQuery,
    useUpdateClipMutation,
    useUpdateRoundMutation,
} from '../../app/services/api';
import AppIconInput from '../../components/common/forms/AppIconInput';
import AppIconButton from '../../components/common/inputs/AppIconButton';
import AppCard from '../../components/common/layout/AppCard';
import AppContainer from '../../components/common/layout/AppContainer';
import LoadingSpinner from '../../components/common/misc/LoadingSpinner';
import {
    DeleteIcon,
    DownloadIcon,
    EditIcon,
    LeftArrowIcon,
    RightArrowIcon,
} from '../../components/common/misc/Svgs';
import { ResponseModel } from '../../models/api/common/response';
import { ApiRequestUpdateRound } from '../../models/api/round';
import { AdminClip } from '../../models/game/clip';
import { AdminRoundLong } from '../../models/game/round';
import { RoundStats } from '../../models/stats/RoundStats';
import { setFlicksModalState } from '../../store/navigationSlice';
import { LogDebug } from '../../utils/loggingUtils';

const RoundPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let { roundId } = useParams();

    const [round, setRound] = useState<AdminRoundLong>();
    const [selectedClip, setSelectedClip] = useState<AdminClip>();
    const [createClipPositions, setCreateClipPositions] = useState<number[]>();
    const [roundStats, setRoundStats] = useState<RoundStats>();

    const flicksModal = useAppSelector(
        (state) => state.navigation.flicksModalState,
    );

    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop(item: { files: any[] }) {
                createMultipleClipFiles(item.files);
            },
            collect: (monitor: DropTargetMonitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                };
            },
        }),
        [round],
    );

    const getRoundQueryResponse = useGetRoundQuery(roundId!);
    const getRoundStatsResponse = useGetRoundStatsQuery({
        roundId: roundId!,
        isArchive: false,
    });
    const [updateRound, { isLoading: isUpdatingRound }] =
        useUpdateRoundMutation();
    const [updateClip, { isLoading: isUpdatingClip }] = useUpdateClipMutation();
    const [deleteClip] = useDeleteClipMutation();
    const [createClip, { isLoading: isCreatingClip }] = useCreateClipMutation();

    useEffect(() => {
        if (getRoundQueryResponse.isSuccess) {
            setRound(getRoundQueryResponse.data.content);
        }

        if (getRoundQueryResponse.isError) {
            navigate('/rounds');
        }
    }, [
        getRoundQueryResponse.isSuccess,
        getRoundQueryResponse.isError,
        getRoundQueryResponse.data,
        navigate,
    ]);

    useEffect(() => {
        if (getRoundStatsResponse.isSuccess) {
            setRoundStats(getRoundStatsResponse.data!.content);
        }
    }, [
        getRoundStatsResponse.isSuccess,
        getRoundStatsResponse.isError,
        getRoundStatsResponse.data,
    ]);

    useEffect(() => {
        if (flicksModal.state !== undefined) {
            const flickIdChanged =
                flicksModal.state.flickId !== undefined &&
                round?.flickId !== flicksModal.state.flickId;
            const isReplayChanged =
                round?.isReplay !== flicksModal.state.isReplay;

            if (flickIdChanged || isReplayChanged) {
                try {
                    const request: ApiRequestUpdateRound = {
                        roundId: round!.id,
                        body: {},
                    };

                    if (flickIdChanged) {
                        request.body.flickId = flicksModal.state.flickId;
                    } else if (isReplayChanged) {
                        request.body.randomiseReplayRound =
                            flicksModal.state.isReplay;
                    }

                    updateRound(request)
                        .unwrap()
                        .then((response: ResponseModel<AdminRoundLong>) => {
                            setRound(response.content);
                            setSelectedClip(undefined);
                        });
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }, [flicksModal, round, updateRound]);

    useEffect(() => {
        console.log(drop);
    }, [drop]);

    const selectClipCallback = useCallback(
        (clip?: AdminClip) => {
            if (
                clip === undefined ||
                isUpdatingClip ||
                isUpdatingRound ||
                getRoundQueryResponse.isFetching
            )
                return;
            setSelectedClip(clip);
        },
        [getRoundQueryResponse.isFetching, isUpdatingClip, isUpdatingRound],
    );

    const editFlick = useCallback(
        () =>
            dispatch(
                setFlicksModalState({
                    isOpen: true,
                    state: {
                        flickId: round?.flickId!,
                        flickTitle: round?.flickTitle!,
                        isReplay: round?.isReplay!,
                    },
                }),
            ),
        [dispatch, round?.flickId, round?.flickTitle, round?.isReplay],
    );

    const repositionSelectedClip = useCallback(
        (position: number) => {
            if (selectedClip === undefined) return;

            let formData = new FormData();
            formData.append('Position', position.toString());
            try {
                updateClip({
                    clipId: selectedClip.id,
                    body: formData,
                }).unwrap();
            } catch (error) {
                console.error(error);
            }
        },
        [selectedClip, updateClip],
    );

    const replaceSelectedClipFile = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (selectedClip === undefined) return;

            const file: File | undefined =
                event.target.files !== null && event.target.files.length > 0
                    ? event.target.files[0]
                    : undefined;

            if (file === undefined) return;

            let formData = new FormData();
            formData.append('File', file);
            try {
                updateClip({
                    clipId: selectedClip.id,
                    body: formData,
                })
                    .unwrap()
                    .then(() => {
                        setSelectedClip(undefined);
                    });
            } catch (error) {
                console.error(error);
            }
        },
        [selectedClip, updateClip],
    );

    const createClipFile = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, position: number) => {
            if (round?.flickId === undefined) return;

            const file: File | undefined =
                event.target.files !== null && event.target.files.length > 0
                    ? event.target.files[0]
                    : undefined;

            if (file === undefined) return;

            setCreateClipPositions([position]);

            let formData = new FormData();
            formData.append('FlickId', round?.flickId);
            formData.append('Position', position.toString());
            formData.append('File', file);
            try {
                createClip({
                    body: formData,
                }).unwrap();
            } catch (error) {
                console.error(error);
            }
        },
        [createClip, round?.flickId],
    );

    const createMultipleClipFiles = useCallback(
        (files: File[]) => {
            if (round?.flickId === undefined) return;

            const currentClipCount = round.clips.length;

            files = files
                .slice(0, 7 - currentClipCount)
                .sort((a, b) => a.name.localeCompare(b.name));

            let positions: number[] = [];
            for (let i = 0; i < 7; i++) {
                if (positions.length < files.length) {
                    const position = i + 1;
                    if (!round.clips.find((e) => e.position === position)) {
                        positions.push(position);
                    }
                }
            }

            setCreateClipPositions(positions);

            for (let i = 0; i < files.length; i++) {
                LogDebug(
                    'Creating clip',
                    files[i].name,
                    `${files[i].size / 1000000}MB`,
                );
                let formData = new FormData();
                formData.append('FlickId', round?.flickId);
                formData.append('Position', positions[i].toString());
                formData.append('File', files[i]);
                try {
                    createClip({
                        body: formData,
                    }).unwrap();
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [createClip, round?.clips, round?.flickId],
    );

    const deleteSelectedClip = useCallback(() => {
        if (selectedClip === undefined) return;

        try {
            deleteClip({
                clipId: selectedClip.id,
            })
                .unwrap()
                .then(() => {
                    setSelectedClip(undefined);
                });
        } catch (error) {
            console.error(error);
        }
    }, [deleteClip, selectedClip]);

    const winPercentage = roundStats
        ? Math.round((roundStats!.wins / roundStats!.total) * 100)
        : 0;

    const lossPercentage = roundStats
        ? Math.round((roundStats!.losses / roundStats!.total) * 100)
        : 0;

    const getPageHeader = useMemo(
        () => (
            <AppCard
                layoutClassName="py-4 w-full"
                className="mb-4"
                isLoading={isUpdatingRound || getRoundQueryResponse.isLoading}
                loadingIndicatorClassname="w-8 h-8"
            >
                <div className="flex-row items-center hidden lg:flex">
                    <span className="block w-24 text-center text-base">
                        {DateTime.fromISO(round?.date!).toFormat('dd/MM/yy')}
                    </span>
                    <div
                        className="bg-canvas-6 grow border border-transparent hover:border-primary-1 cursor-pointer"
                        onClick={editFlick}
                    >
                        <span className="block pl-2 py-1 text-center text-ellipsis max-h-12 overflow-x-hidden">
                            {round?.flickTitle ?? 'Pick a Flick'}
                        </span>
                    </div>
                    <span className="block text-center w-24">{`#${round?.number}`}</span>
                </div>

                <div className="flex flex-col items-center lg:hidden px-4">
                    <div className="flex w-full justify-between">
                        <span className="block text-left text-base">
                            {DateTime.fromISO(round?.date!).toFormat(
                                'dd/MM/yy',
                            )}
                        </span>

                        <span className="block text-right">{`#${round?.number}`}</span>
                    </div>

                    <div
                        className="bg-canvas-6 w-full mt-4 border border-transparent hover:border-primary-1 cursor-pointer"
                        onClick={editFlick}
                    >
                        <span className="block pl-2 py-1 text-center text-ellipsis max-h-12 overflow-x-hidden">
                            {round?.flickTitle ?? 'Pick a Flick'}
                        </span>
                    </div>
                </div>
            </AppCard>
        ),
        [
            editFlick,
            getRoundQueryResponse.isLoading,
            isUpdatingRound,
            round?.date,
            round?.flickTitle,
            round?.number,
        ],
    );

    const getClipsSectionContent = useMemo(
        () => (
            <>
                <div className="flex flex-row flex-wrap w-full pt-2 px-2 pb-2">
                    {Array.from({ length: 7 }).map((_, index) => {
                        const position = index + 1;
                        const clip = round?.clips.find(
                            (_) => _.position === position,
                        );

                        const isSelected =
                            clip !== undefined && selectedClip?.id === clip?.id;

                        return (
                            <div key={index} className="w-full lg:w-1/3 p-2">
                                <div
                                    className={clsx(
                                        clip
                                            ? !isSelected && 'cursor-pointer'
                                            : 'bg-canvas-6',
                                        isSelected
                                            ? 'border-primary-1'
                                            : 'border-accent-1',
                                        'flex flex-col border p-2 w-full h-36',
                                    )}
                                    onClick={() => selectClipCallback(clip)}
                                >
                                    <div className="flex items-center h-4">
                                        <AppIconInput
                                            type="file"
                                            className={clsx(
                                                isSelected
                                                    ? 'visible'
                                                    : 'invisible',
                                            )}
                                            layoutClassName="p-1 w-6 h-6"
                                            onChange={replaceSelectedClipFile}
                                        >
                                            <EditIcon className="w-4 h-4 text-primary-1" />
                                        </AppIconInput>

                                        <div className="flex justify-center items-center grow">
                                            <AppIconButton
                                                className={clsx(
                                                    clip !== undefined &&
                                                        isSelected &&
                                                        position > 1
                                                        ? 'visible'
                                                        : 'invisible',
                                                )}
                                                layoutClassName="p-1 w-6 h-6"
                                                onClick={() =>
                                                    repositionSelectedClip(
                                                        position - 1,
                                                    )
                                                }
                                            >
                                                <LeftArrowIcon className="w-4 h-4" />
                                            </AppIconButton>
                                            <span className="block text-center leading-none font-bold text-lg mx-2">
                                                {position < 7
                                                    ? position
                                                    : 'END'}
                                            </span>
                                            <AppIconButton
                                                className={clsx(
                                                    clip !== undefined &&
                                                        isSelected &&
                                                        position < 7
                                                        ? 'visible'
                                                        : 'invisible',
                                                )}
                                                layoutClassName="p-1 w-6 h-6"
                                                onClick={() =>
                                                    repositionSelectedClip(
                                                        position + 1,
                                                    )
                                                }
                                            >
                                                <RightArrowIcon className="w-4 h-4" />
                                            </AppIconButton>
                                        </div>

                                        <AppIconButton
                                            className={clsx(
                                                isSelected
                                                    ? 'visible'
                                                    : 'invisible',
                                            )}
                                            layoutClassName="p-1 w-6 h-6"
                                            onClick={deleteSelectedClip}
                                        >
                                            <DeleteIcon className="w-4 h-4 text-error-1" />
                                        </AppIconButton>
                                    </div>
                                    {clip && (
                                        <>
                                            <div className="w-full pt-2">
                                                <ReactPlayer
                                                    className="bg-transparent"
                                                    light={false}
                                                    controls={false}
                                                    width="100%"
                                                    height="5.15rem"
                                                    url={clip.sourceUrl}
                                                />
                                            </div>
                                            <div className="w-full h-6 flex pt-1 justify-center items-center">
                                                {isSelected &&
                                                (isUpdatingClip ||
                                                    isUpdatingRound ||
                                                    getRoundQueryResponse.isFetching) ? (
                                                    <LoadingSpinner className="w-4 h-4" />
                                                ) : (
                                                    <span className="block w-full text-center leading-none">
                                                        {clip?.filename ??
                                                            'No filename'}
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {!clip && (
                                        <div className="w-full grow flex flex-col justify-center items-center">
                                            {isCreatingClip &&
                                            createClipPositions?.includes(
                                                position,
                                            ) ? (
                                                <LoadingSpinner />
                                            ) : (
                                                <>
                                                    <span className="block w-full text-center text-canvas-3 mb-2">
                                                        Not Set
                                                    </span>
                                                    <AppIconInput
                                                        type="file"
                                                        className="w-full h-full"
                                                        layoutClassName="w-12 h-12"
                                                        onChange={(e) => {
                                                            createClipFile(
                                                                e,
                                                                position,
                                                            );
                                                        }}
                                                    >
                                                        <DownloadIcon className="w-8 h-8 text-primary-1" />
                                                    </AppIconInput>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    className={clsx(
                        'w-full h-28 px-4 hidden lg:block',
                        (round?.clips.length ?? 0) < 7
                            ? 'visible'
                            : 'invisible',
                    )}
                >
                    <div
                        className={clsx(
                            'h-full flex justify-center items-center',
                            'border-4 border-dashed',
                            canDrop && isOver
                                ? 'border-primary-1 bg-canvas-6'
                                : 'border-accent-1',
                        )}
                    >
                        <span
                            className={clsx(
                                'text-2xl uppercase',
                                canDrop && isOver
                                    ? 'text-primary-1'
                                    : 'text-accent-1',
                            )}
                        >
                            {canDrop && isOver
                                ? 'Release to upload'
                                : 'Drag files here'}
                        </span>
                    </div>
                </div>
            </>
        ),
        [
            canDrop,
            createClipFile,
            createClipPositions,
            deleteSelectedClip,
            getRoundQueryResponse.isFetching,
            isCreatingClip,
            isOver,
            isUpdatingClip,
            isUpdatingRound,
            replaceSelectedClipFile,
            repositionSelectedClip,
            round?.clips,
            selectClipCallback,
            selectedClip?.id,
        ],
    );

    const getClipSection = useMemo(
        () => (
            <AppCard
                layoutClassName="py-4"
                className="flex lg:flex-1"
                isLoading={
                    isUpdatingRound ||
                    getRoundQueryResponse.isLoading ||
                    isUpdatingRound
                }
                title="Clips"
            >
                <div className="hidden lg:block">{getClipsSectionContent}</div>
                <div className="block lg:hidden">{getClipsSectionContent}</div>
            </AppCard>
        ),
        [
            getClipsSectionContent,
            getRoundQueryResponse.isLoading,
            isUpdatingRound,
        ],
    );

    const getStatsSection = useMemo(
        () => (
            <AppCard
                title="Stats"
                className="mb-4"
                layoutClassName="py-4"
                loadingHeightStyle="7rem"
                loadingIndicatorClassname="h-12 w-12"
                isLoading={
                    getRoundStatsResponse.isLoading || roundStats === undefined
                }
            >
                {roundStats && (
                    <div className="flex flex-col w-full lg:flex-row lg:h-28 justify-around pt-2 lg:px-4">
                        <div className="w-full lg:w-1/2 flex flex-col justify-center h-16 lg:h-full px-4 lg:pr-4 lg:pl-0">
                            <div className="flex w-full">
                                <span className="block">W</span>
                                <span className="block flex-grow text-center">
                                    {isNaN(winPercentage) ? 0 : winPercentage}%
                                </span>
                                <span className="block">L</span>
                            </div>
                            <div className="flex h-5 my-1 w-full">
                                <div
                                    className={clsx('h-full bg-primary-1')}
                                    style={{
                                        width: `${winPercentage}%`,
                                    }}
                                ></div>
                                <div
                                    className={clsx('h-full bg-error-1')}
                                    style={{
                                        width: `${lossPercentage}%`,
                                    }}
                                ></div>
                            </div>
                            <div className="flex w-full">
                                <span className="block flex-grow text-left">
                                    {roundStats!.wins}
                                </span>
                                <span className="block flex-grow text-right">
                                    {roundStats!.losses}
                                </span>
                            </div>
                        </div>
                        <div className="flex lg:hidden w-full border-canvas-6 border-b-2 my-4" />
                        <div className="w-full lg:w-1/2 flex h-24 lg:h-full px-4 lg:pl-4 lg:pr-0">
                            {Array.from(Array(6).keys()).map((item, index) => {
                                const value = Object.values(
                                    roundStats!.guesses,
                                )[index];

                                const barPercentage = Math.round(
                                    (value / roundStats!.wins) * 100,
                                );
                                const spacePercentage = 100 - barPercentage;

                                return (
                                    <div
                                        key={index}
                                        className="flex-grow flex flex-col"
                                    >
                                        <span className="block text-center">
                                            {isNaN(barPercentage)
                                                ? 0
                                                : barPercentage}
                                            %
                                        </span>
                                        <div className="flex-grow px-1 border-canvas-5">
                                            <div
                                                style={{
                                                    height: `${spacePercentage}%`,
                                                }}
                                            ></div>
                                            <div
                                                className="bg-accent-1"
                                                style={{
                                                    height: `${barPercentage}%`,
                                                }}
                                            ></div>
                                        </div>

                                        <span className="block text-center">
                                            {index + 1}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </AppCard>
        ),
        [
            getRoundStatsResponse.isLoading,
            lossPercentage,
            roundStats,
            winPercentage,
        ],
    );

    const getPageContent = useMemo(
        () => (
            <>
                <div className="flex flex-col h-full lg:flex-1 lg:mr-2 mb-4 lg:mb-0 px-3 lg:px-0">
                    {getStatsSection}
                    <AppCard
                        className="lg:flex-1 mb-4 lg:mb-0"
                        isLoading={
                            isUpdatingRound ||
                            getRoundQueryResponse.isLoading ||
                            isUpdatingRound
                        }
                        title="Player"
                    >
                        <div className="w-full h-full flex flex-col items-center justify-center p-4">
                            <div className="relative w-full h-screen max-h-[calc(56.25vw)] lg:h-[18.25vw] lg:max-h-[calc(100vh - 32rem)]">
                                {selectedClip ? (
                                    <>
                                        <ReactPlayer
                                            className="absolute top-0 left-0 bg-transparent"
                                            playsinline={true}
                                            light={false}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            muted={false}
                                            loop={true}
                                            url={selectedClip.sourceUrl}
                                        />
                                    </>
                                ) : (
                                    <div className="w-full h-full flex justify-center items-center bg-canvas-6"></div>
                                )}
                            </div>
                            <span className="w-full block text-center mt-2 text-lg">
                                {selectedClip
                                    ? selectedClip.filename
                                    : 'No Clip Selected'}
                            </span>
                        </div>
                    </AppCard>
                    <div className="flex lg:hidden">{getClipSection}</div>
                </div>
                <div className="hidden lg:flex lg:flex-1 ml-0 lg:ml-2">
                    {getClipSection}
                </div>
            </>
        ),
        [
            getClipSection,
            getRoundQueryResponse.isLoading,
            getStatsSection,
            isUpdatingRound,
            selectedClip,
        ],
    );

    const getBody = () => (
        <>
            <h2 className="w-full text-2xl text-center font-semibold mb-4 uppercase">
                Edit Round
            </h2>

            <div className="w-full px-4 lg:px-0">{getPageHeader}</div>

            <div className="w-full flex-grow overflow-hidden" ref={drop}>
                {round?.flickId && (
                    <>
                        <div
                            className={clsx(
                                'hidden flex-row w-full h-screen max-h-[calc(100vh-13.5rem)] lg:flex',
                            )}
                        >
                            {getPageContent}
                        </div>
                        <div
                            className={clsx(
                                'flex flex-col w-full h-full max-h-full overflow-hidden lg:hidden px-1',
                            )}
                        >
                            <Scrollbars className="mb-4">
                                {getPageContent}
                            </Scrollbars>
                        </div>
                    </>
                )}
            </div>
        </>
    );

    return (
        <>
            <AppContainer
                outerClassName="max-h-full overflow-y-hidden flex lg:hidden"
                innerClassName="w-full h-full items-center pt-4 text-accent-1 flex flex-col"
                maxWidth="1920px"
                fillScreen={true}
                padding={false}
            >
                {getBody()}
            </AppContainer>
            <AppContainer
                outerClassName="max-h-full overflow-y-hidden hidden lg:flex"
                innerClassName="w-full h-full items-center pt-4 text-accent-1"
                maxWidth="1920px"
            >
                {getBody()}
            </AppContainer>
        </>
    );
};
export default RoundPage;
