import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { adPlaceholdersEnabled, eventName, testAds } from '../../../config';
import { aprilfools } from '../../../constants/misc/eventConstants';
import useAdsense from '../../../hooks/useAdsense';

type AdsenseProps = {
    className?: string;
    style?: React.CSSProperties;
    slot: string;
    layout?: string;
    layoutKey?: string;
    responsive?: boolean;
    children?: React.ReactNode;
};

const Adsense: React.FC<AdsenseProps> = ({
    className = '',
    style = { display: 'block' },
    slot,
    layout = '',
    layoutKey = '',
    responsive = false,
    children,
    ...rest
}) => {
    const adsense = useAdsense();
    const location = useLocation();
    const eventActive = useAppSelector((state) => state.events.eventActive);
    const cookiePolicyStatus = useAppSelector(
        (state) => state.settings.cookiePolicyStatus,
    );

    useEffect(() => {
        adsense.fetchAd();
    }, [location.pathname]);

    return (
        <>
            <div
                className={clsx(
                    'bg-red-500 flex justify-center items-center',
                    className,
                    !adPlaceholdersEnabled && 'hidden',
                    eventActive &&
                        eventName === aprilfools &&
                        'transform rotate-180',
                )}
            >
                <div className="text-white text-center">
                    <p>AD</p>
                </div>
            </div>
            <div key={location.pathname} className="w-full h-full">
                <ins
                    className={clsx(
                        'adsbygoogle',
                        className,
                        adPlaceholdersEnabled && 'hidden',
                        eventActive &&
                            eventName === aprilfools &&
                            'transform rotate-180',
                    )}
                    style={style}
                    data-ad-client="ca-pub-7333724618766645"
                    data-ad-slot={slot}
                    data-ad-layout={layout}
                    data-ad-layout-key={layoutKey}
                    data-adtest={testAds ? 'on' : 'off'}
                    data-full-width-responsive={responsive}
                    data-personalization-id={
                        cookiePolicyStatus === 'full' ? 'enabled' : 'disabled'
                    }
                    {...rest}
                >
                    {children}
                </ins>
            </div>
        </>
    );
};

export default Adsense;
