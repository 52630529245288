import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    useCreateArchiveCompletionRecordMutation,
    useRecordAnonRoundCompletionMutation,
} from '../../app/services/api';
import { eventName } from '../../config';
import {
    antiStreakWorstEmoji,
    streakBestEmoji,
    streakEmoji,
} from '../../constants/misc/EmojiConstants';
import { spookle, xmas } from '../../constants/misc/eventConstants';
import { ApiRequestArchiveCompletionRecord } from '../../models/api/archive';
import { ApiRequestAnonRoundCompletion } from '../../models/api/stats';
import {
    archiveRecordFromRound,
    roundRecordFromRound,
} from '../../models/game/round';
import { StreakItem } from '../../models/stats/StreakItems';
import { setGuessesModalIsOpen } from '../../store/navigationSlice';
import { addGameRecord, recalculateStreaks } from '../../store/statsSlice';
import { formatEmojis } from '../../utils/emojiUtils';
import { LogDebug } from '../../utils/loggingUtils';
import {
    generateArchiveGuesses,
    generateStreakItems,
} from '../../utils/statsUtils';
import AppButton from '../common/inputs/AppButton';
import AppContainer from '../common/layout/AppContainer';
import {
    DoubleChevronDownIcon,
    HistoryIcon,
    ShareIcon,
} from '../common/misc/Svgs';
import RoundStatsChart from '../common/stats/RoundStatsChart';

const EndScreen: React.FC = () => {
    const dispatch = useAppDispatch();

    const [clipboardNoticeActive, setClipboardNoticeActive] = useState(false);
    const [streakItems, setStreakItems] = useState<StreakItem[]>();

    const round = useAppSelector((state) => state.game.round);
    const guesses = useAppSelector((state) => state.game.guesses);
    const win = useAppSelector((state) => state.game.win);
    const stats = useAppSelector((state) => state.stats);
    const settings = useAppSelector((state) => state.settings);
    const account = useAppSelector((state) => state.account);

    const eventActive = useAppSelector((state) => state.events.eventActive);

    const [recordAnonRound] = useRecordAnonRoundCompletionMutation();
    const [createArchiveCompletionRecord] =
        useCreateArchiveCompletionRecordMutation();

    useEffect(() => {
        dispatch(
            addGameRecord({
                win: win,
                round: roundRecordFromRound(round!),
                guessCount: guesses.length,
            }),
        );
        dispatch(recalculateStreaks());
        if (account.sessionId) {
            try {
                const request: ApiRequestAnonRoundCompletion = {
                    sessionId: account.sessionId,
                    roundId: round!.id,
                    guesses: guesses.length,
                    win: win,
                    isArchive: false,
                };

                recordAnonRound(request)
                    .unwrap()
                    .then(() => {
                        LogDebug(
                            `Recorded round completion for session ${request.sessionId} and round ${request.roundId}`,
                        );
                    });
            } catch (recordAnonRoundError) {
                console.warn(recordAnonRoundError);
            }

            try {
                const request: ApiRequestArchiveCompletionRecord = {
                    sessionId: account.sessionId,
                    roundId: archiveRecordFromRound(round!).id,
                    guessHistory: generateArchiveGuesses(guesses),
                    isWin: win,
                };

                createArchiveCompletionRecord(request)
                    .unwrap()
                    .then(() => {
                        LogDebug(
                            `Create archive round completion for session ${request.sessionId} and round ${request.roundId}`,
                        );
                    });
            } catch (recordArchiveRecordError) {
                console.warn(recordArchiveRecordError);
            }
        }
    }, [
        account.sessionId,
        createArchiveCompletionRecord,
        dispatch,
        guesses,
        recordAnonRound,
        round,
        win,
    ]);

    const shareScore = async () => {
        let hashtag;

        if (eventActive) {
            switch (eventName) {
                case spookle:
                    hashtag = '#Spookle';
                    break;
                case xmas:
                    hashtag = '#Flickle';
                    break;
                default:
                    hashtag = '#Flickle';
                    break;
            }
        } else {
            hashtag = '#Flickle';
        }

        const scoreText = `${hashtag} #${round!.number}\n\n${formatEmojis(
            guesses,
            win,
            eventActive,
            true,
        )}`;

        const streakItems = generateStreakItems(
            stats,
            settings.shareStatsNegative ? 'all' : 'streaks',
            settings.shareStatsShort,
            true,
        );
        const statsText =
            settings.shareStats && streakItems
                ? `\n\n${streakItems
                      .map((item) => {
                          const label = settings.shareStatsShort
                              ? `${item.label} - ${streakEmoji}`
                              : `${item.label}:`;
                          const bestStreaks = settings.shareStatsBest
                              ? settings.shareStatsShort
                                  ? `${
                                        !item.isAntiStreak
                                            ? streakBestEmoji
                                            : antiStreakWorstEmoji
                                    } ${item.best}`
                                  : `(${
                                        !item.isAntiStreak ? 'Best' : 'Worst'
                                    } ${item.best})`
                              : '';
                          const result = `${label} ${item.current} ${bestStreaks}`;
                          return result.trim();
                      })
                      .join('\n')}`
                : '';

        const linkText = settings.shareLink ? `\n\nhttps://flickle.app/` : '';

        const shareData = {
            text: `${scoreText}${statsText}${linkText}`,
        };

        const copyToClipboard = async () => {
            await navigator.clipboard.writeText(shareData.text);
            setClipboardNoticeActive(true);
        };

        if (settings.shareOverrideCopyToClipboard) {
            await copyToClipboard();
        } else {
            try {
                if (
                    navigator.canShare(shareData) &&
                    window.screen.width < 700
                ) {
                    await navigator.share(shareData);
                } else {
                    await copyToClipboard();
                }
            } catch (err) {
                console.error(
                    'Failed to share score, fallback to clipboard',
                    err,
                );
                await copyToClipboard();
            }
        }
    };

    useEffect(() => {
        setStreakItems(generateStreakItems(stats, 'all', true));
    }, [stats]);

    return (
        <AppContainer
            outerClassName="flex items-center pt-4"
            innerClassName="w-full h-full flex justify-center"
        >
            <div className="w-full h-full flex flex-col items-center text-accent-1">
                <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold text-accent-1 text-center">
                        {round!.flickTitle}
                    </p>
                    <div className="flex justify-center items-center mt-4">
                        <p className="text-xl">{win ? '🙌' : '😢'}</p>
                        <p className="mx-4 text-center text-sm">
                            {' '}
                            {win
                                ? "You got today's Flickle!"
                                : "You didn't get today's Flickle, better luck next time... "}
                        </p>
                        <p className="text-xl">{win ? '🙌' : '😢'}</p>
                    </div>
                </div>

                <div className="w-full flex flex-col flex-grow items-center mt-1">
                    <div className="w-full flex">
                        <div className="flex items-center justify-center pl-4">
                            <DoubleChevronDownIcon className="w-6 h-6 ml-2 text-canvas-3" />
                        </div>
                        <div className="flex-grow">
                            <div
                                className="w-full text-center text-lg mt-4 cursor-pointer"
                                onClick={() =>
                                    dispatch(setGuessesModalIsOpen(true))
                                }
                            >
                                {formatEmojis(guesses, win, eventActive, false)}
                            </div>

                            {streakItems && (
                                <div className="flex justify-center mt-2">
                                    {streakItems.map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="flex flex-col justify-center mx-2"
                                            >
                                                <div className="text-lg">
                                                    {item.label}
                                                </div>
                                                <div className="text-md text-center">
                                                    {item.current}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="flex items-center justify-center pr-4">
                            <DoubleChevronDownIcon className="w-6 h-6 ml-2 text-canvas-3" />
                        </div>
                    </div>
                    <RoundStatsChart
                        roundId={round!.id}
                        win={win}
                        guesses={guesses}
                        isArchive={false}
                    />
                    <p
                        className={`${
                            clipboardNoticeActive ? 'visible' : 'invisible'
                        } text-accent-2 mt-4 text-sm`}
                    >
                        Copied to clipboard!
                    </p>

                    <div className="flex items-center">
                        <div className="mb-4 mr-4">
                            <Link to={'/archive'}>
                                <AppButton mode="alt" sizeClassName="w-36 h-10">
                                    <div className="flex justify-between px-4">
                                        <div className="flex-grow text-center">
                                            ARCHIVE
                                        </div>
                                        <HistoryIcon className="w-6 h-6 ml-2" />
                                    </div>
                                </AppButton>
                            </Link>
                        </div>

                        <AppButton sizeClassName="w-36 h-10 mb-4 ml-4">
                            <div className="flex px-4" onClick={shareScore}>
                                <div className="flex-grow text-center">
                                    SHARE
                                </div>
                                <ShareIcon className="w-6 h-6 ml-2" />
                            </div>
                        </AppButton>
                    </div>
                </div>
            </div>
        </AppContainer>
    );
};

export default EndScreen;
