const colors = [
    '#00FF00',
    '#0000FF',
    '#FF0000',
    '#01FFFE',
    '#FFA6FE',
    '#FFDB66',
    '#006401',
    '#800080',
];

export const getRgbColorFromUserId = (id: string) => {
    if (!id) return '#f5f5f5';

    // Use the hashCode function to convert the UUID string to a number
    const hash = hashCode(id);

    // Use the modulus operator to ensure the index is within the range of the colors array
    const index = hash % colors.length;

    // Return the color at the calculated index
    return hexToRgb(colors[index]);
};

function hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

const hexToRgb = (hex: string) => {
    if (!hex) return 'rgb(255,255,255)';

    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
};
