import React from 'react';
import { Link } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import { setPolicyConsentPanelIsOpen } from '../../../store/navigationSlice';
import { setCookiePolicyStatus } from '../../../store/settingsSlice';
import AppButton from '../inputs/AppButton';
import clsx from "clsx";
import {eventName} from "../../../config";
import {aprilfools} from "../../../constants/misc/eventConstants";

const PolicyConsent: React.FC = () => {
    const dispatch = useAppDispatch();
    const eventActive = useAppSelector((state) => state.events.eventActive);

    const acceptAllCookies = () => {
        dispatch(setCookiePolicyStatus('full'));
        dispatch(setPolicyConsentPanelIsOpen(false));
    };

    const rejectNonEssentialCookies = () => {
        dispatch(setCookiePolicyStatus('essential'));
        dispatch(setPolicyConsentPanelIsOpen(false));
    };

    return (
        <div className={clsx('absolute flex flex-col items-center z-10 bottom-0 w-screen bg-canvas-5 text-accent-1 text-sm sm:text-base p-4 border-t border-accent-1', eventActive && eventName === aprilfools && 'transform rotate-180 border-b')}>
            <p className="mb-6 max-w-[60rem]">
                We use cookies to improve your experience on our website. By
                continuing to use our website, you consent to our use of
                cookies. For more information, please see our{' '}
                <Link
                    to="/cookie-policy"
                    className="text-primary-1 hover:text-primary-2"
                >
                    Cookie Policy
                </Link>{' '}
                and{' '}
                <Link
                    to="/privacy-policy"
                    className="text-primary-1 hover:text-primary-2"
                >
                    Privacy Policy
                </Link>
                .
            </p>
            <div className="flex w-full max-w-[28rem] sm:max-w-[36rem]">
                <div className="flex sm:justify-center sm:w-1/2">
                    <AppButton
                        value="Accept All"
                        className="uppercase text-xs sm:text-sm"
                        sizeClassName="h-6 w-28 sm:h-8 sm:w-32"
                        onClick={acceptAllCookies}
                    />
                </div>
                <div className="flex sm:w-1/2 flex-grow sm:flex-grow-0 sm:justify-center justify-end">
                    <AppButton
                        mode="secondary"
                        value="essential only"
                        className="uppercase text-xs sm:text-sm"
                        sizeClassName="h-6 w-48 sm:h-8 sm:w-56"
                        onClick={rejectNonEssentialCookies}
                    />
                </div>
            </div>
        </div>
    );
};

export default PolicyConsent;
