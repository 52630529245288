import { DateTime } from 'luxon';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { Guess } from '../../models/game/guess';
import { ArchiveRoundLong } from '../../models/game/round';
import { formatEmojis } from '../../utils/emojiUtils';
import AppButton from '../common/inputs/AppButton';
import AppContainer from '../common/layout/AppContainer';
import LoadingSpinner from '../common/misc/LoadingSpinner';
import {
    DoubleChevronDownIcon,
    RightArrowIcon,
    ShuffleIcon,
} from '../common/misc/Svgs';
import RoundStatsChart from '../common/stats/RoundStatsChart';

type ArchiveEndScreenProps = {
    win: boolean;
    round: ArchiveRoundLong;
    guesses: Guess[];
    getNextArchive: () => void;
    getRandomArchive: () => void;
    fetchingNextArchive: boolean;
    roundsAvailable: boolean;
};

const ArchiveEndScreen: React.FC<ArchiveEndScreenProps> = ({
    win,
    round,
    guesses,
    getNextArchive,
    getRandomArchive,
    fetchingNextArchive,
    roundsAvailable,
}) => {
    const eventActive = useAppSelector((state) => state.events.eventActive);

    return (
        <AppContainer
            outerClassName="flex items-center pt-4"
            innerClassName="w-full h-full flex justify-center"
        >
            <div className="w-full h-full flex flex-col items-center text-accent-1">
                <div className="flex flex-col items-center">
                    <p className="text-2xl font-semibold text-accent-1 text-center">
                        {round.flickTitle}
                    </p>
                    <p className="text-accent-2 text-center mt-1">
                        {`#${round.number} - ${DateTime.fromISO(
                            round.date,
                        ).toLocaleString(DateTime.DATE_SHORT)}`}
                    </p>
                    <div className="flex justify-center items-center mt-4">
                        <p className="text-xl">{win ? '🙌' : '😢'}</p>
                        <p className="mx-4 text-center text-sm">
                            {' '}
                            {win
                                ? 'You got the Flickle!'
                                : "You didn't get the Flickle"}
                        </p>
                        <p className="text-xl">{win ? '🙌' : '😢'}</p>
                    </div>
                </div>

                <div className="w-full flex flex-col flex-grow items-center mt-1">
                    <div className="w-full flex">
                        <div className="flex items-center justify-center pl-4">
                            <DoubleChevronDownIcon className="w-6 h-6 ml-2 text-canvas-3" />
                        </div>
                        <div className="flex-grow">
                            <div className="w-full text-center text-lg mt-4">
                                {formatEmojis(
                                    guesses,
                                    win,
                                    eventActive,
                                    false,
                                    true,
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-center pr-4">
                            <DoubleChevronDownIcon className="w-6 h-6 ml-2 text-canvas-3" />
                        </div>
                    </div>

                    {fetchingNextArchive ? (
                        <LoadingSpinner className="mt-8 h-10 w-10" />
                    ) : (
                        <div className="flex items-center mt-8">
                            <AppButton
                                mode="alt-secondary"
                                sizeClassName="w-36 h-10"
                                className="mr-4"
                                onClick={getRandomArchive}
                                isEnabled={roundsAvailable}
                            >
                                <div className="flex justify-between px-4">
                                    <div className="flex-grow text-center uppercase">
                                        random
                                    </div>
                                    <ShuffleIcon className="w-6 h-6 ml-2" />
                                </div>
                            </AppButton>
                            <AppButton
                                mode="alt"
                                sizeClassName="w-36 h-10"
                                className="ml-4"
                                onClick={getNextArchive}
                                isEnabled={roundsAvailable}
                            >
                                <div className="flex justify-between px-4">
                                    <div className="flex-grow text-center uppercase">
                                        next
                                    </div>
                                    <RightArrowIcon className="w-6 h-6 ml-2" />
                                </div>
                            </AppButton>
                        </div>
                    )}

                    <RoundStatsChart
                        roundId={round!.id}
                        win={win}
                        guesses={guesses}
                        isArchive={true}
                    />
                </div>
            </div>
        </AppContainer>
    );
};

export default ArchiveEndScreen;
