import React from 'react';
import AppContainer from '../../components/common/layout/AppContainer';
import AppCard from '../../components/common/layout/AppCard';
import AppButton from '../../components/common/inputs/AppButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useLogoutMutation } from '../../app/services/api';
import { resetUser } from '../../store/accountSlice';

const AccountPage: React.FC = () => {
    const dispatch = useAppDispatch();

    const refreshToken = useAppSelector((state) => state.account.refreshToken);

    const [logout, { isLoading: isLoggingOut }] = useLogoutMutation();

    const onLogout = () => {
        if (refreshToken?.value !== undefined) {
            logout({
                refreshToken: refreshToken.value,
            });
        }
        dispatch(resetUser());
    };

    return (
        <AppContainer
            outerClassName="max-h-full"
            innerClassName="w-full h-full items-center py-4 text-accent-1"
        >
            <h2 className="text-2xl text-center font-semibold mb-4 uppercase">
                Account
            </h2>

            <AppCard>
                <AppButton
                    value="Log out"
                    onClick={onLogout}
                    isLoading={isLoggingOut}
                />
            </AppCard>
        </AppContainer>
    );
};
export default AccountPage;
