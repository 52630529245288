import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ApiResultLogin, ApiUserData } from '../models/api/account';
import { getDateTimeLocalNow } from '../utils/dateTimeUtils';

type Token = {
    value: string;
    expiresAt: number;
};

type AccountState = {
    accessToken?: Token;
    refreshToken?: Token;
    id: string;
    role: 'anonymous' | 'user' | 'admin';
    nickname?: string;
    sessionId?: string;
};

const accountInitialState: AccountState = {
    id: '00000000-0000-0000-0000-000000000000',
    role: 'anonymous',
};

const account = createSlice({
    name: 'account',
    initialState: accountInitialState,
    reducers: {
        resetUser: (state: AccountState) => {
            return {
                ...accountInitialState,
                sessionId: state.sessionId,
            };
        },
        setUserInfo: (
            state: AccountState,
            action: PayloadAction<ApiUserData>,
        ) => {
            console.log(action.payload);
            state.id = action.payload.id;
            state.role = action.payload.role;
            state.nickname = action.payload.nickname;
        },
        setTokens: (
            state: AccountState,
            action: PayloadAction<ApiResultLogin>,
        ) => {
            const currentDateTimeSeconds =
                getDateTimeLocalNow(true).toSeconds();

            state.accessToken = {
                value: action.payload.accessToken,
                expiresAt:
                    currentDateTimeSeconds +
                    Math.max(action.payload.accessTokenExpiresIn - 60, 0),
            };

            state.refreshToken = {
                value: action.payload.refreshToken,
                expiresAt:
                    currentDateTimeSeconds +
                    Math.max(action.payload.refreshTokenExpiresIn - 60, 0),
            };
        },
        setSessionId: (
            state: AccountState,
            action: PayloadAction<string | undefined>,
        ) => {
            state.sessionId = action.payload ?? uuidv4();
        },
    },
});

export const { resetUser, setUserInfo, setTokens, setSessionId } =
    account.actions;

export default account.reducer;
