import { api } from './services/api';
import { combineReducers } from '@reduxjs/toolkit';
import gameSlice from '../store/gameSlice';
import statsSlice from '../store/statsSlice';
import navigationSlice from '../store/navigationSlice';
import settingsSlice from '../store/settingsSlice';
import eventsSlice from '../store/eventsSlice';
import accountSlice from '../store/accountSlice';

const rootReducer = combineReducers({
    game: gameSlice,
    stats: statsSlice,
    navigation: navigationSlice,
    settings: settingsSlice,
    events: eventsSlice,
    account: accountSlice,

    [api.reducerPath]: api.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
