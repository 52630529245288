import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { googleAnalyticsId, googleTagManagerId } from '../config';
import Home from '../pages/HomePage';
import { setAllModalsClosed } from '../store/navigationSlice';
import {LogDebug} from "../utils/loggingUtils";

const Analytics: React.FC = () => {
    const location = useLocation();

    const [initialized, setInitialized] = useState(false);

    const cookiePolicyConsentStatus = useAppSelector(
        (state) => state.settings.cookiePolicyStatus,
    );

    useEffect(() => {
        if (
            googleAnalyticsId &&
            googleTagManagerId &&
            cookiePolicyConsentStatus === 'full'
        ) {
            const tagManagerArgs: TagManagerArgs = {
                gtmId: googleTagManagerId,
            };

            TagManager.initialize(tagManagerArgs);
            ReactGA.initialize(googleAnalyticsId);
            setInitialized(true);
        } else {
            setInitialized(false);
        }
    }, [cookiePolicyConsentStatus]);

    useEffect(() => {
        if (initialized) {
            ReactGA.send('pageview');
        }
    }, [initialized, location]);

    return null;
};

const LocationDisplay: React.FC = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setAllModalsClosed());
    }, [location]);

    return null;
};

const DebugRouter: React.FC<any> = ({ children }) => {
    const location = useLocation();
    if (process.env.NODE_ENV === 'development') {
        LogDebug(`Route: ${location.pathname}${location.search}`);
    }

    return children;
};

const AppRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <DebugRouter>
                <Routes>
                    <Route element={<Home />} path="/*" />
                </Routes>
            </DebugRouter>
            <LocationDisplay />
            <Analytics />
        </BrowserRouter>
    );
};

export default AppRoutes;
