import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

const UserRoute = ({ component: Component, ...rest }: any) => {
    const role = useAppSelector((state) => state.account.role);

    return role !== 'anonymous' ? <Outlet /> : <Navigate to="/" />;
};

export default UserRoute;
