import React, {MutableRefObject, useCallback, useEffect, useState} from 'react';
import { useDebounce } from 'use-debounce';
import { useSearchFlicksQuery } from '../../app/services/api';
import useFocus from '../../hooks/useFocus';
import { FlickSearchResult } from '../../models/game/flick';
import AppButton from '../common/inputs/AppButton';
import AppSearchBar, {
    AppSearchBarCallables,
} from '../common/inputs/AppSearchBar';
import AppContainer from '../common/layout/AppContainer';
import {Guess} from "../../models/game/guess";
import clsx from "clsx";

export type ControllerCallables = {
    setFocus: MutableRefObject<null> | (() => void);
};

type ControllerProps = {
    setCallables?: (callables: ControllerCallables) => void;
    roundReady: boolean;
    guesses: Guess[];
    submitGuessFunction: (option: FlickSearchResult) => void;
    skipGuessFunction: () => void;
    mode?: 'primary' | 'secondary'
};

const Controller: React.FC<ControllerProps> = (
    {
        setCallables,
        roundReady,
        guesses,
        submitGuessFunction,
        skipGuessFunction,
        mode = 'primary'
    }) => {
    const [inputRef, setInputFocus] = useFocus();

    const [currentCallablesValue, setCurrentCallablesValue] = useState<((callables: ControllerCallables) => void) | undefined>(undefined);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [debouncedSearchQuery, { isPending: debounceIsPending }] =
        useDebounce(searchQuery, 100);
    const [searchOptions, setSearchOptions] = useState<FlickSearchResult[]>([]);
    const [selectedOption, setSelectedOption] = useState<FlickSearchResult>();
    const [showSearchOptions, setShowSearchOptions] = useState<boolean>(false);
    const [searchBarCallables, setSearchBarCallables] =
        useState<AppSearchBarCallables>();

    const isSearchQueryValid = (value: string): boolean => value.length >= 2;

    const searchFlicksQueryResponse = useSearchFlicksQuery(
        debouncedSearchQuery,
        {
            skip: !isSearchQueryValid(debouncedSearchQuery),
        },
    );

    useEffect(() => {
        if (
            searchFlicksQueryResponse.isSuccess &&
            searchFlicksQueryResponse.data
        ) {
            const flicks = searchFlicksQueryResponse.data.content.items;

            setSearchOptions(flicks);
        }
    }, [searchFlicksQueryResponse.data, searchFlicksQueryResponse.isSuccess]);

    const processSearchQuery = (value: string) => {
        if (isSearchQueryValid(value)) {
            if (selectedOption) {
                setSelectedOption(undefined);
            }
            setSearchQuery(value);
            setShowSearchOptions(true);
        } else {
            setSearchQuery('');
        }
    };

    const searchBarOnFocus = () => {
        setShowSearchOptions(true);
    };

    const onClickOption = (option: FlickSearchResult) => {
        setSelectedOption(option);
        setShowSearchOptions(false);
    };

    useEffect(() => {
        if (searchBarCallables && selectedOption?.title) {
            searchBarCallables.setValue(selectedOption.title);
            setSearchQuery(selectedOption.title);
        }
    }, [searchBarCallables, selectedOption]);

    const onWindowClick = useCallback((event: MouseEvent) => {
        const allowedInputs = ['searchOption', 'searchBar'];
        if (
            !event.target ||
            !(event.target instanceof HTMLElement) ||
            !allowedInputs.includes(event.target.id)
        ) {
            setShowSearchOptions(false);
        } else if (
            event.target.id === 'searchBar' &&
            isSearchQueryValid(searchQuery)
        ) {
            setShowSearchOptions(true);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (setCallables && setCallables !== currentCallablesValue)
            setCurrentCallablesValue(setCallables);
    }, [currentCallablesValue, setCallables]);

    useEffect(() => {
        if (currentCallablesValue){
            currentCallablesValue({
                setFocus: setInputFocus,
            });
        }
    }, [currentCallablesValue, setInputFocus]);

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    }, [onWindowClick]);

    const getHighlightedText = (text: string, highlight: string) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(
            new RegExp(
                `(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
                'gi',
            ),
        );
        return (
            <span>
                {parts.map((part, i) => (
                    <div key={i} className="inline">
                        {part.toLowerCase() === highlight.toLowerCase() ? (
                            <mark className={clsx(mode === 'primary' ? 'bg-primary-3' : 'bg-secondary-3')}>{part}</mark>
                        ) : (
                            <span>{part}</span>
                        )}
                    </div>
                ))}
            </span>
        );
    };

    const resetSearch = () => {
        setSelectedOption(undefined);
        if (searchBarCallables) {
            searchBarCallables.setValue('');
        }
    };

    const submit = () => {
        if (!selectedOption) return;
        submitGuessFunction(selectedOption);
        resetSearch();
    };

    const skip = () => {
        skipGuessFunction();
        resetSearch();
    };

    return (
        <AppContainer
            outerClassName="border-t border-accent-1 bg-canvas-5"
            innerClassName="flex flex-col w-full items-center py-4"
        >
            <div className="relative w-full mb-px">
                {showSearchOptions &&
                    searchQuery.length >= 2 &&
                    !searchFlicksQueryResponse.isLoading && (
                        <div className="absolute w-full z-10 bottom-0 overflow-y-scroll max-h-[10rem] border-t border-accent-1">
                            {searchOptions?.length > 0 ? (
                                searchOptions.map((option, index) => (
                                    <div
                                        id="searchOption"
                                        key={index}
                                        onClick={() => {
                                            onClickOption(option);
                                        }}
                                        className="cursor-pointer flex items-center w-full -mt-1 min-h-[2.5rem] border border-accent-1 text-accent-1 px-2 bg-canvas-4"
                                    >
                                        <span className="pointer-events-none py-2">
                                            {getHighlightedText(
                                                option.title,
                                                searchQuery,
                                            )}
                                        </span>
                                    </div>
                                ))
                            ) : (
                                <div className="flex items-center w-full -mt-1 min-h-[2.5rem] border border-accent-1 text-accent-2 px-2 bg-canvas-4">
                                    <span className="pointer-events-none py-2">
                                        No movies found
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
            </div>
            <AppSearchBar
                id="searchBar"
                placeholder="Start typing to find movies"
                className="w-full"
                setSearchQuery={processSearchQuery}
                onFocus={searchBarOnFocus}
                inputRef={inputRef}
                setCallables={setSearchBarCallables}
                isLoading={
                    searchFlicksQueryResponse.isLoading ||
                    searchFlicksQueryResponse.isFetching ||
                    debounceIsPending()
                }
            />
            <div className="flex w-full justify-between items-center mt-4">
                <AppButton
                    value="SKIP"
                    onClick={skip}
                    mode="errorSecondary"
                    isEnabled={roundReady}
                />
                <span className="text-accent-1">{guesses.length + 1}/6</span>
                <AppButton
                    value="SUBMIT"
                    onClick={submit}
                    mode={mode === 'primary' ? 'primary' : 'alt'}
                    isEnabled={selectedOption !== undefined && roundReady}
                />
            </div>
        </AppContainer>
    );
};

export default Controller;
