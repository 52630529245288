import {eventName} from "../config";
import {aprilfools, spookle, xmas} from "../constants/misc/eventConstants";
import {Guess} from "../models/game/guess";

export const formatEmojis = (guesses: Guess[], win: boolean, eventActive: boolean, shared = false, isArchive = false): string => {
    let emojis = '';
    if (shared) {
        if (eventActive) {
            switch (eventName) {
                case spookle:
                    emojis = '🎃';
                    break;
                case xmas:
                    emojis = '🎄';
                    break;
                case aprilfools:
                    emojis = '🙃';
                    break;
                default:
                    emojis = '🎬';
                    break;
            }
        } else {
            emojis = '🎬';
        }
    }

    for (let i = 0; i < 6; i++) {
        if (i > guesses.length - 1) {
            emojis += '⬜';
        } else {
            const guess = guesses[i];
            emojis += guess.isSkip ? '⬛' : guess.isCorrect ? (isArchive ? '🟪' : '🟩') : '🟥';
        }
    }

    if (shared && !win) {
        emojis += '❌';
    }

    return emojis;
};
