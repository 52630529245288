import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from '../routes/AppRoutes';
import { store } from './store';

let persistor = persistStore(store);

function App() {
    return (
        <>
            <ToastContainer limit={2} theme="dark" />
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <DndProvider backend={HTML5Backend}>
                        <AppRoutes />
                    </DndProvider>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
